<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 user">
        <div class="col-12">
          <div class="card">
            <!-- Card Header -->
            <div class="card-header border-bottom d-flex justify-content-between align-items-center">
              <h5 class="mb-0">Törzsadatok</h5>
                <div class="px-4">
                    <material-button class="float-right btn btm-sm" @click="createItem">
                        <i class="fas fa-plus me-2"></i>
                        Hozzáadás
                    </material-button>
                </div>
            </div>
  
            <!-- Card Body -->
            <div class="px-0 pb-0 card-body">
              <ul class="nav nav-tabs card-header-tabs px-4">
                <li v-for="(label, key) in dataTypes" :key="key">
                  <button
                    class="nav-link cursor-pointer"
                    :class="{
                        'text-muted': selection !== key, 
                        'text-success': selection === key 
                    }"
                    @click="changeTab(key)"
                  >
                    {{ label }}
                  </button>
                </li>
              </ul>
              <div v-if="loading" class="text-center p-4">Betöltés...</div>
              <div v-else class="px-4 py-2">
                <div class="border rounded overflow-hidden">
                  <div class="bg-light p-2 text-center font-weight-bold d-flex">
                    <div class="col text-center" v-if="selection === 'utilities'">KÓD</div>
                    <div class="col text-center" v-else>ID</div>
                    <div class="col text-center">Név</div>
                    <div class="col text-center">Cím</div>
                    <div class="col text-center" v-if="selection === 'tags'">Szín</div>
                    <div class="col text-center">Műveletek</div>
                  </div>
                  <div v-for="masterData in masterDataCollection.data" :key="masterData.slug" class="d-flex text-center align-items-center border-top p-2">
                    <div class="col text-center">{{ masterData.id }} {{ masterData.code }}</div>
                    <div class="col text-center">{{ masterData.name }}</div>
                    <div class="col text-center">{{ masterData.slug }}</div>
                    <div class="col text-center" v-if="selection === 'tags'">
                      <div :style="{ backgroundColor: masterData.color }" class="border rounded-pill py-2 px-4 d-inline-block text-white">
                        {{ masterData.color }}
                      </div>
                    </div>
                    <div class="col text-center d-flex justify-content-center gap-2">
                      <div @click="updateItem(masterData)" class="bg-white cursor-pointer me-3">
                        <i class="fas fa-user-edit text-success"></i>
                      </div>
                      <div @click="deleteItem(masterData)" class="bg-white cursor-pointer me-3">
                        <i class="fas fa-trash text-danger"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       <!-- Modal -->
       <div class="modal fade" id="masterDataModal" tabindex="-1" aria-labelledby="masterDataModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="masterDataModalLabel">{{ isEditing ? 'Adat szerkesztése' : 'Új adat hozzáadása' }}</h5>
              </div>
              <div class="modal-body mb-4">
                <div class="p-2">
                  <div v-if="selection === 'utilities'">
                    <InputLabel :small="true">Kód</InputLabel>
                    <TextInput
                      type="text"
                      v-model="form.code"
                      class="w-100"
                      placeholder="Kód"
                    />
                  </div>
                  <div class="py-4">
                    <InputLabel :small="true">Név</InputLabel>
                    <TextInput
                      type="text"
                      v-model="form.name"
                      class="w-100"
                      placeholder="Név"
                    />
                  </div>
                  <div v-if="selection === 'tags'">
                    <InputLabel :small="true">Szín</InputLabel>
                    <TextInput
                      type="text"
                      v-model="form.color"
                      class="w-100"
                      placeholder="Szín"
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <material-button type="button" color="secondary" data-bs-dismiss="modal">Mégse</material-button>
                <material-button type="button" @click="saveItem(selection)">{{ isEditing ? 'Mentés' : 'Hozzáadás' }}</material-button>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
  
<script setup>
  import { ref, watch, onMounted, computed } from "vue";
  import { useStore } from "vuex";
  import MaterialButton from "@/components/MaterialButton.vue";
  import { Modal } from "bootstrap";
  import showSwal from "../../../../mixins/showSwal";
  import Swal from "sweetalert2";
  import InputLabel from "@/components/InputLabel.vue";
  import TextInput from "@/components/TextInput.vue";
  
  const dataTypes = {
    account_types: "Felhasználó típusok",
    area_functions: "Terület funkciók",
    area_floor_coverings: "Területi padlóburkolatok",
    area_numbers: "Helység számok",
    area_positions: "Területi pozíciók",
    buildings: "Épületek",
    contact_types: "Kontakt típusok",
    hh_solar_system_types: "Napkollektor típusok",
    lease_durations: "Bérleti időtartamok",
    measurement_units: "Mértékegységek",
    meter_types: "Mérő típusok",
    notice_periods: "Felmondási idők",
    payment_methods: "Fizetési módok",
    price_index_types: "Árindex típusok",
    price_indexes: "Árindexek",
    realestate_functions: "Ingatlan funkciók",
    realestate_handlings: "Ingatlan kezelések",
    reporting_types: "Jelentés típusok",
    submeter_types: "Almérő típusok",
    supply_types: "Ellátás típusok",
    tags: "Címkék",
    utilities: "Közművek",
  };
  
  const store = useStore();
  const selection = ref("account_types");
  const loading = ref(false);
  const masterDataCollection = computed(() => store.getters['masterdata/getMasterData'] || []);
  const isEditing = ref(false);
  const form = ref({ 
    slug: "",
    name: "", 
    code: "", 
    color: "", 
    selection: "" 
  });
  let modalInstance = null;

  const changeTab = (key) => {
    selection.value = key;
    fetchData();
  };

  const showModal = () => {
    if (!modalInstance) {
        modalInstance = new Modal(document.getElementById("masterDataModal"));
    }
    modalInstance.show();
  };
  
  const fetchData = async () => {
    loading.value = true;
    try {
        await store.dispatch("masterdata/getMasterData", selection.value);
    } catch (error) {
      console.error("Hiba történt:", error);
    }
    loading.value = false;
  };

  const createItem = () => {
    form.value = { 
      slug: "", 
      name: "", 
      code: "", 
      color: "",
      selection: selection.value
    };

    isEditing.value = false;
    showModal();
  };

  const updateItem = (masterData) => {
    form.value = { 
      ...masterData,
      selection: selection.value
     };

    isEditing.value = true;
    showModal();
  };

  const saveItem = async () => {
    if (isEditing.value) {
        try {
          await store.dispatch("masterdata/updateMasterData", form.value);
          showSwal.methods.showSwal({
            type: "success",
            message: "Sikeresen módosította!",
            width: 500
          });
      } catch (error) {
        console.error("Error in updating data:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });
      }
    } else {
      try {
        await store.dispatch("masterdata/createMasterData", form.value );
        showSwal.methods.showSwal({
          type: "success",
          message: "Új adat létrehozva!",
          width: 500
        });
      } catch (error) {
        console.error("Error in creating data:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });
      }
    }

    modalInstance.hide();
    fetchData();
  };

  const deleteItem = async (masterData) => {
    form.value = { 
      selection: selection.value,
      slug: masterData.slug,
    };
    
    const result = await showSwal.methods.showSwalConfirmationDelete();
    
    if (result.isConfirmed) {
      try {
        await store.dispatch("masterdata/deleteMasterData", form.value);
        showSwal.methods.showSwal({
          type: "success",
          message: "Sikeresen törölve!",
          width: 500
        });
      } catch (error) {
        console.error("Error in deleting data:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });
      }

      fetchData();

    } else if (result.dismiss === Swal.DismissReason.cancel) {
      showSwal.methods.showSwal({
        type: "error",
        message: "A törlés megszakítva.",
        width: 500
      });
    }
  };

  watch(selection, fetchData);
  onMounted(fetchData);
</script>