import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getRealEstates(params) {
        const response = await axios.get(API_URL + "/getrealestates", {headers: authHeader(), params: params});
        return {data: response.data, meta: response.data.meta?.page};
    },

    async addRealEstate(realestate) {
        realestate.type = "realestates";
        const formData = new FormData();
        Object.keys(realestate).forEach(key => {
            if (key !== "files") {
                formData.append(`data[attributes][${key}]`, realestate[key]);
            }
        });
        if (realestate.files && realestate.files.length > 0) {
            realestate.files.forEach(file => {
                formData.append("file[]", file);
            });
        }
        const response = await axios.post(API_URL + "/addrealestate", formData, {
            headers: {
                ...authHeader(),
                "Content-Type": "multipart/form-data"
            }
        });
        return dataFormatter.deserialize(response.data);
    },

    async deleteRealEstate(realestateId) {
        await axios.delete(API_URL + "/realestates/" + realestateId, {headers: authHeader()});
    },

    async getRealEstate(realestateId) {
        const response = await axios.get(API_URL + "/getrealestate/" + realestateId, {headers: authHeader()});
        return response.data;
    },

    async getRealEstateAreas(realestateId) {
        const response = await axios.get(API_URL + `/data/realestates/${realestateId}/areas`, {headers: authHeader()});
        return response.data;
    },

    async editRealEstate(realestate) {
        realestate.type = "realestates";
        const formData = new FormData();
        Object.keys(realestate).forEach(key => {
            if (key !== "files") {
                formData.append(`data[attributes][${key}]`, realestate[key]);
            }
        });
        if (realestate.files && realestate.files.length > 0) {
            realestate.files.forEach(file => {
                formData.append("file[]", file);
            });
        }
        const response = await axios.post(API_URL + "/editrealestate/" + realestate.id, formData, {
            headers: {
                ...authHeader(),
                "Content-Type": "multipart/form-data"
            }
        });
    
        return dataFormatter.deserialize(response.data);
    },

    async deleteMedia(params) {
        await axios.delete(API_URL + "/realestate/" + params.realestate_id + "/media/" + params.media_id, {headers: authHeader()});
    },

    async downloadMedia(media_id) {
        const response = await axios.get(API_URL + "/download/" + media_id, { headers: authHeader(), responseType: 'blob'});
        const filename = `downloaded_file.${response.data.type.split('/')[1] || 'bin'}`;
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    },
}




