import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import {areas} from "./areas.module"
import {auth} from "./auth.module"
import {roles} from "./roles.module"
import {users} from "./users.module"
import {tags} from "./tags.module"
import {realestates} from "./realestates.module"
import {contacts} from "./contacts.module"
import {contacttypes} from "./contacttypes.module"
import {meters} from "./meters.module"
import {submeters} from "./submeters.module"
import {categories} from "./categories.module"
import {items} from "./items.module"
import {profile} from "./profile.module"
import {countries} from "./countries.module"
import {utilities} from "./utilities.module"
import {services} from "./services.module"
import {owners} from "./owners.module"
import {incominginvoices} from "./incominginvoices.module"
import {calculation} from "./calculation.module"
import {sfunctions} from "./sfunctions.module"
import {handlings} from "./handlings.module"
import {afunctions} from "./afunctions.module"
import {positions} from "./positions.module"
import {coverings} from "./coverings.module"
import {unetworks} from "./unetworks.module"
import {munits} from "./munits.module"
import {stypes} from "./stypes.module"
import {leases} from "./leases.module"
import {leasedurations} from "./leasedurations.module"
import {noticeperiods} from "./noticeperiods.module"
import {priceindexes} from "./priceindexes.module"
import {consumption} from "./consumption.module"
import {scontracts} from "./scontracts.module"
import { supplytypes } from "./supplytypes.module";
import { reportingtypes } from "./reportingtypes.module";
import { accounttypes } from "./accounttypes.module";
import { paymentmethods } from "./paymentmethods.module";
import { hhsolarsystemtypes } from "./hhsolarsystemtypes.module";
import { billings } from "./outgoinginvoices.module";
import { masterdata } from "./masterdata.module";
import { buildings } from "./buildings.module";
import { areanumbers } from "./areanumbers.module";

export default createStore({
  modules:{
    areas,
    auth,
    roles,
    users,
    tags,
    realestates,
    contacts,
    contacttypes,
    categories,
    items,
    profile,
    countries,
    owners,
    meters,
    submeters,
    utilities,
    services,
    incominginvoices,
    calculation,
    sfunctions,
    handlings,
    afunctions,
    positions,
    coverings,
    unetworks,
    munits,
    stypes,
    leases,
    leasedurations,
    noticeperiods,
    priceindexes,
    consumption,
    scontracts,
    supplytypes,
    reportingtypes,
    accounttypes,
    paymentmethods,
    hhsolarsystemtypes,
    billings,
    masterdata,
    areanumbers,
    buildings
  },
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-gradient-dark",
    isRTL: false,
    color: "success",
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    isDarkMode: false,
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    toggleHideConfig(state) {
      state.hideConfigButton = !state.hideConfigButton;
    },
    setIsPinned(state, payload) {
      state.isPinned = payload;
    },
    color(state, payload) {
      state.color = payload;
    },
    navbarMinimize(state) {
      const sidenavShow = document.getElementsByClassName("g-sidenav-show")[0];

      if (sidenavShow.classList.contains("g-sidenav-pinned")) {
        sidenavShow.classList.remove("g-sidenav-pinned");
        sidenavShow.classList.add("g-sidenav-hidden");
        state.isPinned = true;
      } else {
        sidenavShow.classList.remove("g-sidenav-hidden");
        sidenavShow.classList.add("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  actions: {
    setColor({ commit }, payload) {
      commit("color", payload);
    }
  },
  getters: {}
});
