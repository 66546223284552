/* eslint-disable no-unused-vars */
import buildingsService from '../services/buildings.service';

const initialState = { buildings: null };

export const buildings = {
    namespaced: true,
    state: initialState,
    actions: {
        async getBuildings({ commit }, options) {
            const buildings = await buildingsService.getBuildings(options);
            commit('getBuildingsSuccess', buildings);
        },
    },
    mutations: {
        getBuildingsSuccess(state, buildings) {
            state.buildings = buildings;
        },
    },
    getters: {
        getBuildingsData(state) {
            return state.buildings?.data;
        },
    }
}

