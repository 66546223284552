import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async filterBillingData(params) {
        const response = await axios.post(API_URL + "/outinvoices", { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page};
    },

    async outInvoicesStore(params) {
        const response = await axios.put(API_URL + "/addoutinvoices/" + params.id, { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page};
    },
};