import { createRouter, createWebHistory } from "vue-router";
import Default from "../views/dashboards/Default.vue";
import Sales from "../views/dashboards/Sales.vue";
import Notifications from "../views/pages/Notifications.vue";
import NewUser from "../views/pages/users/NewUser.vue";
import Settings from "../views/pages/account/Settings.vue";
import Billing from "../views/pages/account/Billing.vue";
import Invoice from "../views/pages/account/Invoice.vue";
import Basic from "../views/auth/signin/Basic.vue";
import Cover from "../views/auth/signin/Cover.vue";
import Illustration from "../views/auth/signin/Illustration.vue";
import ResetCover from "../views/auth/reset/Cover.vue";
import SignupCover from "../views/auth/signup/Cover.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import PasswordForgot from "../views/PasswordForgot.vue";
import PasswordReset from "../views/PasswordReset.vue";
import UserProfile from "../views/examples-api/user-profile/UserProfile.vue";
import RolesList from "../views/examples-api/roles/RolesList.vue";
import EditRole from "../views/examples-api/roles/EditRole.vue";
import AddRole from "../views/examples-api/roles/AddRole.vue";
import UsersList from "../views/examples-api/users/UsersList.vue";
import EditUser from "../views/examples-api/users/edit-user/EditUser.vue";
import AddUser from "../views/examples-api/users/add-user/AddUser.vue";
import RealEstatesList from "../views/pages/realestates/RealEstatesList.vue";
import AddRealEstate from "../views/pages/realestates/AddRealEstate.vue";
import EditRealEstate from "../views/pages/realestates/EditRealEstate.vue";
import AreasList from "../views/pages/areas/AreasList.vue";
import AddAreas from "../views/pages/areas/AddArea.vue";
import EditAreas from "../views/pages/areas/EditArea.vue";
import OwnersList from "../views/pages/owners/OwnerList.vue";
import AddOwners from "../views/pages/owners/AddOwner.vue";
import EditOwners from "../views/pages/owners/EditOwner.vue";
import ContactsList from "../views/pages/contacts/ContactList.vue";
import AddContacts from "../views/pages/contacts/AddContact.vue";
import EditContacts from "../views/pages/contacts/EditContact.vue";
import MetersList from "../views/pages/meters/MeterList.vue";
import AddMeters from "../views/pages/meters/AddMeter.vue";
import EditMeters from "../views/pages/meters/EditMeter.vue";
import InvoiceList from "../views/pages/incoming-invoices/InvoiceList.vue";
import AddInInvoice from "../views/pages/incoming-invoices/AddInvoice.vue";
import EditInInvoice from "../views/pages/incoming-invoices/EditInvoice.vue";
import SubMetersList from "../views/pages/submeters/SubMeterList.vue";
import AddSubMeters from "../views/pages/submeters/AddSubMeter.vue";
import EditSubMeters from "../views/pages/submeters/EditSubMeter.vue";
import Calculation from "../views/pages/calculation/Calculation.vue";
import ConsumptionsCalendar from "../views/pages/consumptions/ConsumptionsCalendar.vue";
import ConsumptionsRegistration from "../views/pages/consumptions/ConsumptionsRegistration.vue";
import AddUtility from "../views/pages/unetwork/AddUtility.vue";
import EditUtility from "../views/pages/unetwork/EditUtility.vue";
import UtilitiesList from "../views/pages/unetwork/UtilityList.vue";
import LeasesList from "../views/pages/leases/LeasesList.vue";
import AddLease from "../views/pages/leases/AddLease.vue";
import EditLease from "../views/pages/leases/EditLease.vue";
import ScontractsList from "../views/pages/scontracts/ScontractsList.vue";
import AddScontract from "../views/pages/scontracts/AddScontract.vue";
import EditScontract from "../views/pages/scontracts/EditScontract.vue";
import OutGoingInvoicesList from "../views/pages/outgoing-invoices/OutGoingInvoicesList.vue";
import store from "@/store";
import ConsumptionsCalculator from "../views/pages/consumptions/ConsumptionsCalculator.vue";
import MasterData from "../views/pages/settings/master-data/MasterData.vue";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/login",
    meta: {
      breadcrumb: "Bejelentkezés",
    },
  },
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
    meta: {
      breadcrumb: "Főoldal",
    },
  },
  {
    path: "/dashboards/sales",
    name: "Sales",
    component: Sales,
    meta: {
      breadcrumb: "Eladások",
    },
  },
  {
    path: "/pages/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      breadcrumb: "Értesítések",
    },
  },
  {
    path: "/pages/users/new-user",
    name: "New User",
    component: NewUser,
    meta: {
      breadcrumb: "Felhasználó hozzáadása",
    },
  },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
    meta: {
      breadcrumb: "Beállítások",
    },
  },
  {
    path: "/pages/account/billing",
    name: "Billing",
    component: Billing,
    meta: {
      breadcrumb: "Számlázás",
    },
  },
  {
    path: "/pages/account/invoice",
    name: "Invoice",
    component: Invoice,
    meta: {
      breadcrumb: "Számla",
    },
  },
  {
    path: "/authentication/signin/basic",
    name: "Signin Basic",
    component: Basic,
  },
  {
    path: "/authentication/signin/cover",
    name: "Signin Cover",
    component: Cover,
  },
  {
    path: "/authentication/signin/illustration",
    name: "Signin Illustration",
    component: Illustration,
  },
  {
    path: "/authentication/reset/cover",
    name: "Reset Cover",
    component: ResetCover,
  },
  {
    path: "/authentication/signup/cover",
    name: "Signup Cover",
    component: SignupCover,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      breadcrumb: "Bejelentkezés",
      authRequired: false,
      permission: null,
    },
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
    meta: {
      breadcrumb: "Regisztráció",
      authRequired: false,
      permission: null,
    },
  },
  {
    path: "/password-forgot",
    name: "PasswordForgot",
    component: PasswordForgot,
    meta: {
      breadcrumb: "Elfelejtett jelszó",
      authRequired: false,
      permission: null,
    },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: {
      breadcrumb: "Jelszó visszaállítás",
      authRequired: false,
      permission: null,
    },
  },
  {
    path: "/examples/user-profile",
    name: "User Profile",
    component: UserProfile,
    meta: {
      breadcrumb: "Felhasználói profil",
      authRequired: true,
      permission: null,
    },
  },
  {
    path: "/pages/settings/roles/roles-list",
    name: "Roles List",
    component: RolesList,
    meta: {
      breadcrumb: "Szerepkörök listája",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/settings/roles/add-role",
    name: "Add Role",
    component: AddRole,
    meta: {
      breadcrumb: "Szerepkör hozzáadása",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/settings/roles/edit-role",
    name: "Edit Role",
    component: EditRole,
    meta: {
      breadcrumb: "Szerepkör szerkesztése",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/settings/users/users-list",
    name: "Users List",
    component: UsersList,
    meta: {
      breadcrumb: "Felhasználók listája",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/settings/users/add-user",
    name: "Add User",
    component: AddUser,
    meta: {
      breadcrumb: "Felhasználó hozzáadása",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/settings/users/edit-user",
    name: "Edit User",
    component: EditUser,
    meta: {
      breadcrumb: "Felhasználó szerkesztése",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/settings/master-data/masterdata",
    name: "Master Data",
    component: MasterData,
    meta: {
      breadcrumb: "Törzsadatok",
      authRequired: true,
      permission: ["admin"],
    },
  },
  {
    path: "/pages/realestates/realestates-list",
    name: "RealEstates List",
    component: RealEstatesList,
    meta: {
      breadcrumb: "Ingatlanok listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/realestates/add-realestate",
    name: "Add RealEstate",
    component: AddRealEstate,
    meta: {
      breadcrumb: "Ingatlan hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/realestates/edit-realestate/:id",
    name: "Edit RealEstate",
    component: EditRealEstate,
    meta: {
      breadcrumb: "Ingatlan szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/unetwork/utility-list",
    name: "Utilities List",
    component: UtilitiesList,
    meta: {
      breadcrumb: "Közművek listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/unetwork/add-utility",
    name: "Add Utility",
    component: AddUtility,
    meta: {
      breadcrumb: "Közmű hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/unetwork/edit-utility/:id",
    name: "Edit Utility",
    component: EditUtility,
    meta: {
      breadcrumb: "Közmű szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/owners/owners-list",
    name: "Owners List",
    component: OwnersList,
    meta: {
      breadcrumb: "Tulajdonosok listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/owners/add-owners",
    name: "Add Owner",
    component: AddOwners,
    meta: {
      breadcrumb: "Tulajdonos hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/owners/edit-owners/:id",
    name: "Edit Owner",
    component: EditOwners,
    meta: {
      breadcrumb: "Tulajdonos szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/leases/leases-list",
    name: "Leases List",
    component: LeasesList,
    meta: {
      breadcrumb: "Bérleti szerződések listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/leases/add-leases",
    name: "Add Lease",
    component: AddLease,
    meta: {
      breadcrumb: "Bérleti szerződés hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/leases/edit-leases/:id",
    name: "Edit Lease",
    component: EditLease,
    meta: {
      breadcrumb: "Bérleti szerződés szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/contacts/contacts-list",
    name: "Contacts List",
    component: ContactsList,
    meta: {
      breadcrumb: "Kapcsolatok listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/contacts/add-contacts",
    name: "Add Contact",
    component: AddContacts,
    meta: {
      breadcrumb: "Kapcsolat hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/contacts/edit-contacts/:id",
    name: "Edit Contact",
    component: EditContacts,
    meta: {
      breadcrumb: "Kapcsolat szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/meters/meters-list",
    name: "Meters List",
    component: MetersList,
    meta: {
      breadcrumb: "Főmérők listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/meters/add-meters",
    name: "Add Meter",
    component: AddMeters,
    meta: {
      breadcrumb: "Főmérő hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/meters/edit-meters/:id",
    name: "Edit Meter",
    component: EditMeters,
    meta: {
      breadcrumb: "Főmérő szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/submeters/submeters-list",
    name: "SubMeters List",
    component: SubMetersList,
    meta: {
      breadcrumb: "Almérők listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/submeters/add-submeters",
    name: "Add SubMeter",
    component: AddSubMeters,
    meta: {
      breadcrumb: "Almérő hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/submeters/edit-submeters/:id",
    name: "Edit SubMeter",
    component: EditSubMeters,
    meta: {
      breadcrumb: "Almérő szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/calculation",
    name: "Calculation",
    component: Calculation,
    meta: {
      breadcrumb: "Számítás",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/consumptions/consumptions-calendar",
    name: "ConsumptionsCalendar",
    component: ConsumptionsCalendar,
    meta: {
      breadcrumb: "Fogyasztás naptár",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/consumptions/consumptions-reg",
    name: "ConsumptionsRegistration",
    component: ConsumptionsRegistration,
    meta: {
      breadcrumb: "Fogyasztás regisztráció",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/consumptions/consumptions-calculator",
    name: "ConsumptionsCalculator",
    component: ConsumptionsCalculator,
    meta: {
      breadcrumb: "Fogyaszás kalkulátor",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/incoming-invoices/invoices-list",
    name: "InInvoice List",
    component: InvoiceList,
    meta: {
      breadcrumb: "Bejövő számlák listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/incoming-invoices/add-invoice",
    name: "Add InInvoice",
    component: AddInInvoice,
    meta: {
      breadcrumb: "Bejövő számla hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/incoming-invoices/edit-invoice/:id",
    name: "Edit InInvoice",
    component: EditInInvoice,
    meta: {
      breadcrumb: "Bejövő számla szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/outgoing-invoices/outgoing-invoices-list",
    name: "OutInvoice List",
    component: OutGoingInvoicesList,
    meta: {
      breadcrumb: "Kimenő számlák listája",
      authRequired: true,
      permission: ['admin', 'creator']
    }
  },
  {
    path: "/pages/areas/areas-list",
    name: "Areas List",
    component: AreasList,
    meta: {
      breadcrumb: "Helyiségek listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/areas/add-areas",
    name: "Add Area",
    component: AddAreas,
    meta: {
      breadcrumb: "Helyiség hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },

  {
    path: "/pages/areas/edit-areas/:id",
    name: "Edit Area",
    component: EditAreas,
    meta: {
      breadcrumb: "Helyiség szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/scontracts/scontracts-list",
    name: "Scontracts List",
    component: ScontractsList,
    meta: {
      breadcrumb: "Szerződések listája",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
  {
    path: "/pages/scontracts/add-scontracts",
    name: "Add Scontract",
    component: AddScontract,
    meta: {
      breadcrumb: "Szerződés hozzáadása",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },

  {
    path: "/pages/scontracts/edit-scontracts/:id",
    name: "Edit Scontract",
    component: EditScontract,
    meta: {
      breadcrumb: "Szerződés szerkesztése",
      authRequired: true,
      permission: ["admin", "creator"],
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

//eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
  let { authRequired, permission } = to.meta;

  const isLoggedIn = store.getters["auth/isLoggedIn"];

  if (authRequired) {
    //if trying to access a page that requires authentication but not logged in, redirect to login page
    if (!isLoggedIn) {
      return next({ name: "Login", query: { returnUrl: to.path } });
    }

    //if trying to access a page that requires permission, redirect to dashboard
    if (permission) {
      try {
        await store.dispatch("profile/getProfile");
        const userPermission =
          store.getters["profile/getUserProfile"].roles[0].name;

        if (!permission.includes(userPermission)) {
          return next({ name: "Default", query: { returnUrl: to.path } });
        }
      } catch (error) {
        try {
          store.dispatch("auth/logout");
        } finally {
          // eslint-disable-next-line no-unsafe-finally
          return next({ name: "Login" });
        }
      }
    }
  }
  next();
});

export default router;
