export const pagination = {
    pageLength: 10,
    lengthMenu: [10, 25, 50],
    searching: true,
    language: {
        search: "Keresés:",
        lengthMenu: "_MENU_ db/oldal",
        info: "_START_ - _END_ elem, összesen _TOTAL_ db",
        infoEmpty: "A találatok száma 0",
        zeroRecords: "Nincs megjeleníthető adat",
    },
};

export const emptyTableContent = 'Az adatok letöltése folyamatban...';