<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Szerződés hozzáadása</h5>
              </div>
              <div class="col-6 text-end">
                <material-button class="float-right btn btm-sm"
                                 @click="this.$router.push({ name: 'Leases List' })">
                  Vissza
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Bérbeadó</label>
                  <FormDropDown
                    v-model.number="lease.owners_id"
                    :possible-values="getOwnersList"
                    placeholder="Válassz bérbeadót!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Bérlő</label>
                  <FormDropDown
                    v-model.number="lease.contacts_id"
                    :possible-values="getContactsList"
                    placeholder="Válassz bérlőt!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Ingatlan megnevezése</label>
                  <FormDropDown
                    v-model.number="lease.realestate_id"
                    :possible-values="realestateList"
                    placeholder="Válassz ingatlant!"
                    :searchable="true"
                    mode="single"
                    :handleSingleChange="setRealEstate(lease.realestate_id)"
                  />
                  <validation-error :errors="apiValidationErrors.pod"></validation-error>
                </div>

                <div class="col-5">
                  <material-input id="realestate_address" v-model:value="lease.realestate_address" label="Hely címe"
                                  name="realestate_address" variant="static" :value="realEstateAddress" disabled></material-input>
                </div>
              </div>

              <br/>

              <div class="row p-4 bg-light">
                <div class="col-4">
                  <label>Bérelt helységek</label>
                  <MultiSelect v-model:selectValue="lease.areas_id" :options="areas" select-all="true"
                               select-all-label="Mindet kijelöl" cleaner="false" placeholder="Válassz helyiségeket!" @change="() => setFloorSpace()"
                               @show="() => setChoice()"/>
                  <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                </div>

                <div class="col-4">
                  <material-input id="contract_based_floor_space" v-model:value="lease.contract_based_floor_space"
                                  label="Szerződés szerinti bérelt alapterület"
                                  name="contract_based_floor_space" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.contract_based_floor_space"></validation-error>
                </div>

                <div class="col-4">
                  <material-input id="gross_floor_space" v-model:value="lease.gross_floor_space"
                                  label="Bérelt bruttó alapterület"
                                  name="gross_floor_space" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.gross_floor_space"></validation-error>
                </div>

                <div class="col-4">
                  <label class="form-label mb-0 ms-0">Bérleti szerződés időtartama</label>
                  <FormDropDown
                    v-model.number="lease.lease_durations_id"
                    :possible-values="getLeaseDurationsList"
                    placeholder="Válassz időtartamot!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-4">
                  <label class="form-label mb-0 ms-0">Határozott időszak vége</label>
                  <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu"
                                 :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ"
                                 cancel-text="Bezár"/>
                </div>

                <div class="col-4">
                  <label class="form-label mb-0 ms-0">Bérleti időszak kezdete</label>
                  <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu"
                                 :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ"
                                 cancel-text="Bezár"/>
                </div>

                <div class="p-4">
                  <hr>
                  <h2 style="width: 100%;">Bérleti díj</h2>
                </div>

                <div class="col-5">
                  <material-input id="rent" v-model:value="lease.rent" label="Bérleti díj"
                                  name="rent" variant="static" @input="CalculateRent"></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="col-5">
                  <material-input id="total_rent" v-model:value="lease.total_rent" label="Bérleti díj összesen"
                                  name="total_rent" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Bérleti díj árindexálás</label>
                  <FormDropDown
                    v-model.number="lease.rent_price_indexes_id"
                    :possible-values="getPriceIndexesList"
                    placeholder="Válassz árindexálást!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-5">
                  <material-input id="rent_price_indexe_maximum" v-model:value="lease.rent_price_indexes_maximum"
                                  label="Bérleti díj árindexálás maximum"
                                  name="rent_price_indexe_maximum" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.rent_price_indexe_maximum"></validation-error>
                </div>

                <div class="p-4">
                  <hr>
                  <h2 style="width: 100%;">Közös költség</h2>
                </div>

                <div class="col-5">
                  <material-input id="common_cost" v-model:value="lease.common_cost" label="Közös költség"
                                  name="common_cost" variant="static" @input="CalculateCommonCost"></material-input>
                  <validation-error :errors="apiValidationErrors.common_cost"></validation-error>
                </div>

                <div class="col-5">
                  <material-input id="total_common_cost" v-model:value="lease.total_common_cost"
                                  label="Közös költség összesen"
                                  name="total_common_cost" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.total_common_cost"></validation-error>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Közös költség árindexálás</label>
                  <FormDropDown
                    v-model.number="lease.common_cost_indexes_id"
                    :possible-values="getPriceIndexesList"
                    placeholder="Válassz árindexálást!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-5">
                  <material-input id="common_cost_maximum" v-model:value="lease.common_cost_price_indexes_maximum"
                                  label="Közös költség árindexálás maximum"
                                  name="common_cost_maximum" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="p-4">
                  <hr>
                  <h2 style="width: 100%;">Egyéb</h2>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Felmondási idő</label>
                  <FormDropDown
                    v-model.number="lease.notice_periods_id"
                    :possible-values="getNoticePeriodsList"
                    placeholder="Válassz felmondási időt!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-5">
                  <material-input id="caution" v-model:value="lease.caution" label="Kaució"
                                  name="caution" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

              </div>

              <div class="row mt-2 mb-5 d-flex align-items-center">
                <div class="col-2 text-end mt-5">
                  <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropDown from "@/components/FormDropdown.vue";
import {ref} from 'vue';
import {CButton, CCard, CCardBody, CCollapse} from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import MultiSelect from "@/components/MultiSelect.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import {hu} from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'AddLease',
  components: {
    MaterialButton,
    MaterialInput,
    VueDatePicker,
    MultiSelect,
    ValidationError,
    CButton,
    CCollapse,
    CCard,
    CCardBody,
    FormDropDown
  },
  mixins: [formMixin],
  setup() {
    const currentDate = ref(new Date());
    const currentSubDate = ref(new Date());
    const areas = ref([]);
    const visible = ref(false);
    const format = (date) => {
      const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    };
    return {
      color: '#33e8f5',
      currentDate,
      currentSubDate,
      hu,
      format,
      areas,
      visible,
    }
  },
  data() {
    return {
      lease: {},
      sublease: [],
      date: null,
      realestate: null,
      realEstateAddress: "",
      ownersdata: [
        {
          ownerName: "",
          ownership: "",
        },
      ],
    }
  },
  computed: {
    getOwnersList() {
      const data = this.$store.getters["contacts/getContactsBaseData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getContactsList() {
      const data = this.$store.getters["contacts/getContactsBaseData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    realestateList() {
      const data = this.getRealEstateList ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name,
            }));
    },
    AreaList() {
      return this.$store.getters["areas/getAreasData"];
    },
    getLeaseDurationsList() {
      const data = this.$store.getters["leasedurations/getLeasedurationsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name,
            }));
    },
    getPriceIndexesList() {
      const data = this.$store.getters["priceindexes/getPriceindexesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name,
            }));
    },
    getNoticePeriodsList() {
      const data = this.$store.getters["noticeperiods/getNoticeperiodsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name,
            }));
    },
    watch: {
      AreaList: function () {
        this.setChoice();
      },
    },
    ownerName(index) {
      if (typeof (index) != 'undefined') {
        return this.apiValidationErrors['owner' + (index + 1)];
      }
      return null;
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("contacts/getContactsBaseData"),
        this.$store.dispatch("realestates/getRealEstates"),
        this.$store.dispatch("areas/getAreas"),
        this.$store.dispatch("leasedurations/getLeasedurations"),
        this.$store.dispatch("priceindexes/getPriceindexes"),
        this.$store.dispatch("noticeperiods/getNoticeperiods"),
      ]);

      this.loading = false;
    } catch (error) {
      console.error("Error loading data:", error);
    }
  },

  methods: {
    async handleAdd() {
      this.resetApiValidation();
      try {
        this.lease.currentDate = this.currentDate;
        //this.lease.sublease = this.sublease;
        await this.$store.dispatch("leases/addLease", this.lease);
        showSwal.methods.showSwal({
          type: "success",
          message: "Főmérő sikeresen hozzáadva!",
          width: 500
        });
        this.$router.push({name: "Leases List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });

      }
    },
    async setRealEstate(id) {
      const selectedRealEstate = this.getRealEstateList?.find(value => value.id == id) ?? {};
  
      this.lease.realestate_address = selectedRealEstate.zip 
        ? `${selectedRealEstate.zip} ${selectedRealEstate.city} ${selectedRealEstate.address}` 
        : '';

      await this.$store.dispatch("areas/getAreas", {
        filter: id ? { realestate_id: id } : {},
      });
    },
    setChoice() {
      this.areas = this.AreaList
        .filter(item => item.realestate_id === this.lease.realestate_id)
        .map(item => ({
          value: item.id,
          label: item.identifier,
        }));
    },
    setFloorSpace() {
      let total_floor_space = 0;
      this.lease.areas_id.forEach((selectedValue, index) => {
        let currentArea = this.AreaList.filter(value => {
          console.log(value.id)
          return value.id === selectedValue.value
        });
        total_floor_space += Number(currentArea[0].gross_floor_space);
      });
      document.getElementById("gross_floor_space").value = total_floor_space.toFixed(2);
      this.lease.gross_floor_space = total_floor_space.toFixed(2);
    },
    CalculateRent() {
      document.getElementById("total_rent").value = document.getElementById("rent").value * document.getElementById("contract_based_floor_space").value;
      this.lease.total_rent = document.getElementById("rent").value * document.getElementById("contract_based_floor_space").value;
    },
    CalculateCommonCost() {
      document.getElementById("total_common_cost").value = document.getElementById("common_cost").value * document.getElementById("contract_based_floor_space").value;
      this.lease.total_common_cost = document.getElementById("common_cost").value * document.getElementById("contract_based_floor_space").value;
    },
    async addMore() {
      this.ownersdata.push({
        ownerName: "",
        ownership: "",
      });
      const arrayLength = this.ownersdata.length;
      await this.$nextTick(function () {
        if (document.getElementById("choices-sub-munits" + arrayLength)) {
          const munits = document.getElementById("choices-sub-munits" + arrayLength);
        }

        if (document.getElementById("choices-sub-types" + arrayLength)) {
          const stypes = document.getElementById("choices-sub-types" + arrayLength);
        }

        if (document.getElementById("choices-sub-position" + arrayLength)) {
          const position = document.getElementById("choices-sub-position" + arrayLength);
        }
      });
    },
    remove(index) {
      this.ownersdata.splice(index, 1);
    },
    updateOwner: function (value, index) {
      this.owner['owner' + (index + 1)] = value;
      this.$emit('input', value);
    },
    updateOwnership: function (value, index) {
      this.owner['ownership' + (index + 1)] = value;
      this.$emit('input', value);
    },
    updateSublease: function (index, type, value) {
      if (this.sublease[index] === undefined) {
        this.sublease[index] = {};
      }
      if (type == 'commissioning_date') {
        this.sublease[index][type] = this.format(value);
      } else {
        this.sublease[index][type] = value;
      }
    }
  }
}
</script>