<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Ingatlan Lista</h5>
              </div>
              <div class="col-6 text-end">
                <material-button
                    class="float-right btn btm-sm"
                    @click="this.$router.push({ name: 'Add RealEstate' })"
                >
                  <i class="fas fa-tag me-2"></i>
                  Ingatlan Hozzáadása
                </material-button>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="px-0 pb-0 card-body">

            <div class="table-responsive p-4">
              <!-- Table -->
              <DataTable id="dataTable" :data="data" :columns="columns" :options="dataTableOptions" ref="table"
                         class="table table-flush dataTable-table" width="100%">
                <template #action="props">
                  <td
                      class="dt-control"
                      :text="`Col 1: ${props.cellData}`"
                      @click="rowClick"
                  ></td>
                </template>
              </DataTable>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import $ from "jquery";
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net-bs5';
import eventTable from "@/mixins/eventTable.js";
import store from "@/store";
import _ from "lodash";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import BasePagination from "@/components/BasePagination.vue";
import {emptyTableContent, pagination} from "../../../consts";

DataTable.use(DataTablesCore);

var sortDirection = "created_at";

const getRealestatesList = _.debounce(async function (params) {
  await store.dispatch("realestates/getRealEstates");
}, 300);

export default {
  name: "RealEstatesList",
  components: {
    MaterialButton,
    MaterialInput,
    BasePagination,
    DataTable
  },
  mixins: [eventTable],
  setup() {
    const data = ref([]);
    const table = ref();
    const columns = [
      {
        //data: null,
        //className: 'dt-control',
        render: {
          //_: 'pod',
          display: '#action'
        },
        title: 'Helység Lista'
      },
      {title: 'Név'},
      {title: 'Város'},
      {title: 'Felvitel Dátuma'},
      {title: 'Műveletek', class: 'operations-column'},
    ];

    const dataTableOptions = ref(pagination);

    return {
      columns,
      dataTableOptions,
      data,
      table,
    }
  },
  data() {
    return {
      search: "",
      owner: "",
      numberList: "",
      buildingList: "",
      dataRealEstates: [],
      pagination: {},
      dataTable: null,
      keys: null,
      areas: [],
    };
  },
  computed: {
    realestatesList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    metaPage() {
      return this.$store.getters["realestates/getRealEstatesMeta"];
    },
    getAreaNumbersList() {
      return this.$store.getters["areanumbers/getAreanumbersData"];
    },
    getBuildingsList() {
      return this.$store.getters["buildings/getBuildingsData"];
    },
    getownerList() {
      return this.$store.getters["contacts/getContactsBaseData"];
    },
  },
  watch: {
    realestatesList: {
      handler: "reactiveTable",
      immediate: false,
      deep: true,
    },
  },
  async mounted() {
    await getRealestatesList({
      sort: sortDirection,
      query: "",
      //nr: getCurrentPage,
      //perpage: this.dataTable.options.perPage,
    });
    $('#dataTable .dt-empty').html(emptyTableContent);

    await Promise.all([
        this.$store.dispatch("buildings/getBuildings"),
        this.$store.dispatch("areanumbers/getAreanumbers"),
        this.$store.dispatch("contacts/getContactsBaseData"),
      ]);
  },
  beforeUnmount() {
    sortDirection = "created_at";
  },
  methods: {
    rowClick(event) {
      const table = $("#dataTable").DataTable();
      let tr = event.target.closest('tr');
      let row = table.row(tr);
      console.log(this.realestate);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
      } else {
        // Open this row
        row.child(this.format(row.data())).show();
      }
    },
    format(d) {
      // `d` is the original data object for the row
      let areaHtml = "";
      areaHtml += '<table>'
      areaHtml += '<thead>'
      areaHtml += '<tr>'
      areaHtml += '<th>Helység száma</th>'
      areaHtml += '<th>Helyrajzi szám</th>'
      areaHtml += '<th>Épület száma</th>'
      areaHtml += '<th>Nettó méret</th>'
      areaHtml += '<th>Bruttó méret</th>'
      areaHtml += '<th>Akció</th>'
      areaHtml += '<tr>'
      areaHtml += '</thead>'

      d[0].forEach(realestate => {
        let area = this.getAreaNumbersList.find(area => String(area.id) === String(realestate.area_numbers_id));
        let building = this.getBuildingsList.find(area => String(area.id) === String(realestate.area_buildings_id));

        areaHtml += '<tr>'
        areaHtml += `<td class="text-center">${area.name}</td>`;
        areaHtml += `<td class="text-center">${realestate.lot_number}</td>`;
        areaHtml += `<td class="text-center">${building.name}</td>`;
        areaHtml += `<td class="text-center">${realestate.net_floor_space}</td>`;
        areaHtml += `<td class="text-center">${realestate.gross_floor_space}</td>`;
        areaHtml += `<td class="text-center">${this.actionDeleteButton(realestate.id, "Terület törlése")}</td>`;
        areaHtml += '<tr>'
      });

      areaHtml += '</table>'

      return (
          `<dl>
          <dt>Tulajdonos:</dt>
          <dd>
          <table class="w-50">
            <thead>
              <tr>
                <th>Név</th>
                <th>Adószám</th>
                <th>Kontakt</th>
              </tr>
            </thead>
            <tr>
              <td>${d[5].name}</td>
              <td>${d[5].vat_number}</td>
              <td>${d[5].contact}</td>
            </tr>
          </table>
          </dd>
          <dt>Helységek:</dt>
          <dd>${areaHtml}</dd>
          </dl>`
      );
    },

    async getDataFromPage(page) {
      await getRealestatesList({
        sort: sortDirection,
        query: this.search,
        nr: page,
        perpage: this.dataTable.options.perPage,
      });
    },

    async reactiveTable() {
      this.dataRealEstates = [];
      if (this.realestatesList.length > 0) {
        this.realestatesList.forEach((realestate) => {
          this.dataRealEstates.push([
            realestate.get_areas,
            realestate.name,
            realestate.city,
            new Date(realestate.created_at).toLocaleString(),
            `<div class="text-end">${
                this.actionEditButton(realestate.id, "Ingatlan módosítása") +
                this.actionDeleteButton(realestate.id, "Ingatlan törlése")
            }</div>`,
            realestate.owner,
          ]);
        });

        this.data = this.dataRealEstates;
        this.removeEvent();
        await this.$nextTick();
        this.eventToCall({
          myUserId: null,
          redirectPath: "Edit RealEstate",
          deletePath: "realestates/deleteRealEstate",
          getPath: "realestates/getRealEstates",
          textDelete: "Ingatlan sikeresen törölve!",
          textDefaultData: "realestates",
        });
      } else {
        this.dataTable.setMessage("Nincs a keresési feltételnek megfelelő találat.");
      }
    },
  },
};
</script>
