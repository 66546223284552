/* eslint-disable no-unused-vars */
import contactsService from '../services/contacts.service';

const initialState = {
    contacts: null,
    baseContacts: null,
    contact: null
};

export const contacts = {
    namespaced: true,
    state: initialState,
    actions: {
        async getContacts({commit}, options) {
            const contacts = await contactsService.getContacts(options);
            commit('getContactsSuccess', contacts);
        },

        async getContactsBaseData({commit}, options) {
            const baseContacts = await contactsService.getContactsBaseData(options);
            commit('getContactsBaseDataSuccess', baseContacts);
        },

        async addContact({commit}, newcontact) {
            await contactsService.addContact(newcontact);
        },

        async deleteContact({commit}, contactId) {
            await contactsService.deleteContact(contactId);
        },

        async getContact({commit}, contactId) {
            const contact = await contactsService.getContact(contactId);
            commit('getContactSuccess', contact);
        },

        async editContact({commit}, modifiedcontact) {
            await contactsService.editContact(modifiedcontact);
        }
    },
    mutations: {
        getContactsSuccess(state, contacts) {
            state.contacts = contacts;
        },
        getContactsBaseDataSuccess(state, baseContacts) {
            state.baseContacts = baseContacts;
        },
        getContactSuccess(state, contact) {
            state.contact = contact;
        }
    },
    getters: {
        getContactsData(state) {
            return state.contacts?.data;
        },
        getContactsBaseData(state) {
            return state.baseContacts?.data;
        },
        getContactsMeta(state) {
            return state.contacts?.meta;
        },
        getContact(state) {
            return state.contact;
        }
    }
};
