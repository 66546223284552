/* eslint-disable no-unused-vars */
import masterDataService from '../services/masterdata.service';

const initialState = { masterdata: null };

export const masterdata = {
    namespaced: true,
    state: initialState,
    actions: { 
        async getMasterData({ commit }, options) {
            const masterData = await masterDataService.getMasterData(options);
            commit('getMasterData', masterData);
        },
        async createMasterData({ commit }, options) {
            const createMasterData = await masterDataService.createMasterData(options);
            commit('getCreateMasterData', createMasterData);
        },
        async updateMasterData({ commit }, options) {
            const updateMasterData = await masterDataService.updateMasterData(options);
            commit('setUpdateMasterData', updateMasterData);
        },
        async deleteMasterData({ commit }, options) {
            const deleteMasterData = await masterDataService.deleteMasterData(options);
            commit('setDeleteMasterData', deleteMasterData);
        },
    },
    mutations: {
        getMasterData(state, masterData) {
            state.masterData = masterData;
        },
        getCreateMasterData(state, createMasterData) {
            state.createMasterData = createMasterData;
        },
        setUpdateMasterData(state, updateMasterData) {
            state.updateMasterData = updateMasterData;
        },
        setDeleteMasterData(state, deleteMasterData) {
            state.deleteMasterData = deleteMasterData;
        },
    },
    getters: {
        getMasterData(state) {
            return state.masterData?.data;
        },
        getCreateMasterData(state) {
            return state.createMasterData?.data;
        },
        setUpdateMasterData(state) {
            return state.updateMasterData?.data;
        },
        setDeleteMasterData(state) {
            return state.deleteMasterData?.data;
        },
    }
};