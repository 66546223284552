/* eslint-disable no-unused-vars */
import realestatesService from '../services/realestates.service';

const initialState = { realestates: null, realestate: null ,realestatesAreas: null};

export const realestates = {
    namespaced: true,
    state: initialState,
    actions: {
        async getRealEstates({ commit }, options) {
            const realestates = await realestatesService.getRealEstates(options);
            commit('getRealEstatesSuccess', realestates);
        },

        async getRealEstateAreas({ commit }, realestateId) {
            const realestateAreas = await realestatesService.getRealEstateAreas(realestateId);
            commit('getRealEstateAreasSuccess', realestateAreas);
        },

        async addRealEstate({ commit }, newRealEstate) {
            await realestatesService.addRealEstate(newRealEstate);
        },

        async deleteRealEstate({ commit }, realestateId) {
            await realestatesService.deleteRealEstate(realestateId);
        },

        async getRealEstate({ commit }, realestateId) {
            const realestate = await realestatesService.getRealEstate(realestateId);
            commit('getRealEstateSuccess', realestate);
        },

        async editRealEstate({ commit }, modifiedRealEstate) {
            await realestatesService.editRealEstate(modifiedRealEstate); 
        },

        async deleteMedia({ commit }, options) {
            await realestatesService.deleteMedia(options);
        },

        async downloadMedia({ commit }, options) {
            const downloadMedia = await realestatesService.downloadMedia(options);
            commit('getDownloadMediaSuccess', downloadMedia);
        },
    },
    mutations: {
        getRealEstatesSuccess(state, realestates) {
            state.realestates = realestates;
        },
        getRealEstateAreasSuccess(state, areas) {
            state.realestatesAreas = areas;
        },
        getRealEstateSuccess(state, realestate) {
            state.realestate = realestate;
        },
        getDownloadMediaSuccess(state, downloadMedia) {
            state.downloadMedia = downloadMedia;
        },
    },
    getters: {
        getRealEstatesData(state) {
            return state.realestates?.data;
        },
        getRealEstateAreas(state) {
            return state.realestatesAreas;
        },
        getRealEstatesMeta(state) {
            return state.realestates?.meta;
        },
        getRealEstate(state){
            return state.realestate;
        },
        getDownloadMediaData(state) {
            return state.downloadMedia?.data;
        },
    }
}

