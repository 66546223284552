/* eslint-disable no-unused-vars */
import billingsService from '../services/outgoinginvoices.service';

const initialState = { billings: null, filteredBillings: null };

export const billings = {
    namespaced: true,
    state: initialState,
    actions: { 
        async filterBillingData({ commit }, options) {
            const filteredBillings = await billingsService.filterBillingData(options);
            commit('getFilteredBillings', filteredBillings);
        },

        async outInvoicesStore({ commit }, options) {
            const status = await billingsService.outInvoicesStore(options);
            commit('getOutgoingInvoices', status);
        },
    },
    mutations: {
        getFilteredBillings(state, filteredBillings) {
            state.filteredBillings = filteredBillings;
        },
        getOutgoingInvoices(state, status) {
            state.status = status;
        },
    },
    getters: {
        getFilteredBillingsData(state) {
            return state.filteredBillings?.data;
        },
        getOutgoingInvoicesData(state) {
            return state.status?.data;
        },
    }
};