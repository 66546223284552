<template>
  <Dialog :visible="isModalForSeparateOpen" @update:visible="emit('close')" :modal="true" :style="{ width: '1250px' }" header="Helyiségek szétbontása">
    <div class="row d-flex justify-content-between px-2">
      <div class="col-md-6">
        <div class="card p-3 border border-2 shadow-none">
          <h5 class="fw-bold">Szétbontandó helyiség</h5>
          <p class="mb-1">
            <strong>Nettó terület:</strong> <span class="badge bg-secondary">{{ originalArea.net_floor_space }} m²</span>
          </p>
          <p>
            <strong>Bruttó terület:</strong> <span class="badge bg-secondary">{{ originalArea.gross_floor_space }} m²</span>
          </p>
        </div>
      </div>
      <div class="col-md-6 shadow-none">
        <div class="card p-3 border border-2 shadow-none">
          <h5 class="fw-bold">Szétbontott helyiségek összesítve</h5>
          <p class="mb-1">
            <strong>Nettó terület:</strong> <span class="badge bg-success">{{ totalNetFloorSpace }} m²</span>
          </p>
          <p>
            <strong>Bruttó terület:</strong> <span class="badge bg-success">{{ totalGrossFloorSpace }} m²</span>
          </p>
        </div>
      </div>
    </div>
    <div class="row ms-2 mt-4">
      <div class="col-3">
        <label for="identifier" class="block font-bold" style="display: block; font-weight: 700;">Azonostó</label>
      </div>
      <div class="col-3">
        <label for="netFloorSpace" class="block font-bold" style="display: block; font-weight: 700;">Nettó terület</label>
      </div>
      <div class="col-3">
        <label for="grossFloorSpace" class="block font-bold" style="display: block; font-weight: 700;">Bruttó terület</label>
      </div>
      <div class="col-2" />
    </div>
    <div v-for="(data, index) in newAreas" :key="index" class="px-4">
      <div class="row py-2">
        <div class="col-3">
          <InputText 
            id="identifier" 
            v-model.trim="originalArea.identifier" 
            required="true" 
            autofocus 
            fluid
            v-show="index === 0"
            disabled
          />
          <InputText 
            id="identifier" 
            v-model.trim="newAreas[index].identifier"
            required="true" 
            autofocus 
            fluid
            placeholder="Azonosító..."
            v-show="index != 0"
          />
          <small v-if="submitted && !newAreas[index].identifier" class="text-red-500">Azonostó szükséges.</small>
        </div>
        <div class="col-3">
          <InputText 
            id="netFloorSpace" 
            v-model.trim="originalArea.net_floor_space" 
            required="true" 
            autofocus 
            fluid
            v-show="index === 0"
            disabled
          />
          <InputText 
            id="netFloorSpace" 
            v-model.trim="newAreas[index].net_floor_space" 
            required="true" 
            autofocus 
            fluid
            v-show="index != 0"
          />
          <small v-if="submitted && !newAreas[index].net_floor_space" class="text-red-500">Nettó terület szükséges.</small>
        </div>
        <div class="col-3">
          <InputText 
            id="grossFloorSpace" 
            v-model.trim="originalArea.gross_floor_space" 
            required="true" 
            autofocus 
            fluid
            v-show="index === 0"
            disabled
          />
          <InputText 
            id="identifier" 
            v-model.trim="newAreas[index].gross_floor_space" 
            required="true" 
            autofocus 
            fluid
            v-show="index != 0"
          />
          <small v-if="submitted && !newAreas[index].gross_floor_space" class="text-red-500">Bruttó terület szükséges.</small>
        </div>
        <div class="col-3 d-table d-flex justify-content-center align-items-end pb-2">
          <span aria-hidden="true" class="text-xs font-weight-bold d-table-cell align-middle d-flex justify-content-center align-items-center gap-2">
            <i v-show="index != 0" class="fas fa-plus-circle cursor-pointer mb-2"  @click="areaDialogs[index] = true"> Adatok</i>
            <i v-show="index === 0" class="fas fa-plus-circle cursor-pointer mb-2" @click="addMore()"> MEZŐ HOZZÁADÁSA</i>
            <i v-show="index != 0" class="fas fa-minus-circle cursor-pointer mb-2" @click="remove(index)"> ELTÁVOLÍTÁS</i>
          </span>
        </div>
      </div>
      <Dialog v-model:visible="areaDialogs[index]" @update:visible="areaDialogs[index] = false" :modal="true" :style="{ width: '450px' }" header="Új helyiség adatai">
        <div class="flex flex-col" style="display: flex;flex-direction: column; gap: 1rem;">
          <div>
            <label for="area_buildings_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Épületszám</label>
            <Select 
              id="area_buildings_id"
              v-model="newAreas[index].area_buildings_id" 
              :options="buildingsList"
              optionLabel="name" 
              optionValue="id" 
              placeholder="Válasszon épületet!"
              fluid 
            />
          </div>
          <div>
            <label for="area_numbers_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helységszám</label>
            <Select 
              id="area_numbers_id" 
              v-model="newAreas[index].area_numbers_id"
              :options="filteredAreaNumbersList"
              optionLabel="name" 
              optionValue="id" 
              placeholder="Válasszon helység számot!"
              fluid 
            />
          </div>
          <div>
            <label for="area_functions_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Funkció</label>
            <Select 
              id="area_functions_id" 
              v-model="newAreas[index].area_functions_id"
              :options="areaFunctionsList"
              optionLabel="name" 
              optionValue="id" 
              placeholder="Válasszon funkciót!"
              fluid
            />
          </div>
          <div>
            <label for="area_positions_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Pozíció</label>
            <Select 
              id="area_positions_id"
              v-model="newAreas[index].area_positions_id"
              :options="filteredAreaPositionsList"
              optionLabel="name" 
              optionValue="id" 
              placeholder="Válasszon pozíciót!"
              fluid
            />
          </div>
          <div>
            <label for="lot_number" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helyrajzi szám</label>
            <InputText 
              id="lot_number" 
              v-model.trim="newAreas[index].lot_number"
              required="true"
              autofocus 
              fluid 
              disabled
            />
            <small v-if="submitted && !newAreas[index].lot_number" class="text-red-500">Helyrajzi szám szükséges.</small>
          </div>
          <div>
            <label for="height" class="block font-bold mb-3" style="display: block; font-weight: 700;">Magasság</label>
            <InputText 
              id="height" 
              v-model.trim="newAreas[index].height"
              required="true" 
              autofocus 
              fluid 
            />
            <small v-if="submitted && !newAreas[index].height" class="text-red-500">Magasság szükséges.</small>
          </div>
          <div class="pb-4">
            <label for="area_floor_coverings_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Burkolat</label>
            <Select 
              id="area_floor_coverings_id" 
              v-model="newAreas[index].area_floor_coverings_id"
              :options="areaFloorCoveringsList"
              optionLabel="name" 
              optionValue="id" 
              placeholder="Válasszon burkolatot!"
              fluid
            />
          </div>
        </div>
        <template #footer>
          <Button label="Kész" icon="pi pi-check" @click="areaDialogs[index] = false" />
        </template>
      </Dialog>
    </div>
    <template #footer>
      <Button label="Mégse" icon="pi pi-times" text @click="hideDialog" />
      <Button label="Mentés" icon="pi pi-check" :loading="loading" :disabled="isSaveDisabled" @click="saveArea" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from "vue";
import { useStore } from "vuex";
import showSwal from "../../../../mixins/showSwal";

const props = defineProps({
  isModalForSeparateOpen: Boolean,
  selectedareas: Object,
  realestateId: Object,
});

const emit = defineEmits(["close"]);
const hideDialog = () => {
  emit("close");
};

const submitted = ref(false);
const loading = ref(false);
const areaDialogs = ref([false]);
const originalArea = ref({
    id: null, 
    identifier: "",
    realestate_id: "",
    area_buildings_id: "", 
    area_numbers_id: "", 
    lot_number: "",
    area_positions_id: "",
    area_functions_id: "", 
    net_floor_space: 0,
    gross_floor_space: 0, 
    height: 0, 
    area_floor_coverings_id: "", 
});
const newAreas = ref([
  {
    id: null, 
    identifier: "",
    realestate_id: "",
    area_buildings_id: "", 
    area_numbers_id: "", 
    lot_number: "",
    area_positions_id: "",
    area_functions_id: "", 
    net_floor_space: 0,
    gross_floor_space: 0, 
    height: 0, 
    area_floor_coverings_id: "", 
  },
]);
const addMore = () => {
  newAreas.value.push({
    id: null, 
    identifier: "",
    realestate_id: "",
    area_buildings_id: "", 
    area_numbers_id: "", 
    lot_number: originalArea.value.lot_number,
    area_positions_id: "",
    area_functions_id: "", 
    net_floor_space: 0,
    gross_floor_space: 0, 
    height: originalArea.value.height, 
    area_floor_coverings_id: "", 
  });
  areaDialogs.value.push(false);
};

const remove = (index) => {
  newAreas.value.splice(index, 1);
};

const store = useStore();
const buildingsList = computed(() => store.getters["buildings/getBuildingsData"] || []);
const areaNumbersList = computed(() => store.getters["areanumbers/getAreanumbersData"] || []);
const areaFunctionsList = computed(() => store.getters["afunctions/getFunctionsData"] || []);
const areaPositionsList = computed(() => store.getters["positions/getPositionsData"] || []);
const areaFloorCoveringsList = computed(() => store.getters["coverings/getCoveringsData"] || []);
const filteredAreaNumbersList = computed(() => {
  return areaNumbersList.value.filter(areaNumber =>
    props.selectedareas.some(area =>
      area.area_numbers.some(n => n.id == areaNumber.id)
    )
  );
});
const filteredAreaPositionsList = computed(() => {
  return areaPositionsList.value.filter(areaPosition =>
    props.selectedareas.some(area =>
      area.area_position.some(p => p.id == areaPosition.id)
    )
  );
});

const totalNetFloorSpace = computed(() => 
  newAreas.value.reduce((sum, area) => sum + Number(area.net_floor_space), 0)
);
const totalGrossFloorSpace = computed(() => 
  newAreas.value.reduce((sum, area) => sum + Number(area.gross_floor_space), 0)
);

watch(() => props.selectedareas, (newVal) => {
  if (newVal && newVal.length > 0) {
    const selectedArea = newVal[0];
    originalArea.value = {
      id: selectedArea.id || null,
      identifier: selectedArea.identifier || "",
      net_floor_space: selectedArea.net_floor_space || 0,
      gross_floor_space: selectedArea.gross_floor_space || 0,
      lot_number: selectedArea.lot_number || "",
      area_buildings_id: selectedArea.area_buildings_id || "",
      area_numbers_id: selectedArea.area_numbers_id || "",
      height: selectedArea.height || "",
      area_floor_coverings_id: selectedArea.area_floor_coverings_id || "",
      area_positions_id: selectedArea.area_positions_id || "",
      area_functions_id: selectedArea.area_functions_id || "",
    };
  }
}, { immediate: true });

const isSaveDisabled = computed(() => {
  if (newAreas.value.length <= 1) {
    return true;
  }

  for (let i = 1; i < newAreas.value.length; i++) {
    const area = newAreas.value[i];
    if (
      !area.identifier?.trim() ||
      Number(area.net_floor_space) <= 0 ||
      Number(area.gross_floor_space) <= 0 ||
      !area.area_buildings_id ||
      !area.area_numbers_id ||
      !area.area_positions_id ||
      !area.area_functions_id ||
      !area.lot_number ||
      !area.height ||
      !area.area_floor_coverings_id
    ) {
      return true;
    }
  }

  const originalNet = Number(originalArea.value.net_floor_space);
  const originalGross = Number(originalArea.value.gross_floor_space);
  
  if (totalNetFloorSpace.value > originalNet || totalGrossFloorSpace.value > originalGross) {
    return true;
  }

  return false;
});

const editArea = () => {
  const totalNet = Number(totalNetFloorSpace.value) || 0;
  const totalGross = Number(totalGrossFloorSpace.value) || 0;

  originalArea.value.net_floor_space = String(Number(originalArea.value.net_floor_space) - totalNet);
  originalArea.value.gross_floor_space = String(Number(originalArea.value.gross_floor_space) - totalGross);

  store.dispatch("areas/editArea", originalArea.value);
};

const saveArea = async () => {
  submitted.value = true;
  loading.value = true;
  try {
    const savedAreas = [];
    for (let i = 1; i < newAreas.value.length; i++) {
      const area = newAreas.value[i];
      if (area.identifier) {
        area.realestate_id = props.realestateId;
        const areaId = await store.dispatch("areas/addArea", area);
        savedAreas.push({...area, id: areaId.toString()});
      }
    }
    editArea();
    emit("editAreas", savedAreas);
    emit("editNetFloorSpace", originalArea.value);
    loading.value = false;
    showSwal.methods.showSwal({
      type: "success",
      message: "Sikeresen mentette a helyiségeket!",
      width: 500
    });
    emit("close");
    
  } catch (error) {
    console.log('Error in saving the areas:', error);
    showSwal.methods.showSwal({
      type: "error",
      message: "Valami hiba történt!",
      width: 500
    });
    loading.value = false;
  }
};
</script>