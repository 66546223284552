<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <h5 class="mb-0">Fogyasztásregisztráció - {{ selectedDate }}</h5>
              </div>

              <div class="col-3 mb-4">
                <label class="form-label mb-0 ms-0">Év/Hónap</label>
                <VueDatePicker v-model="calendarDate" :teleport="true" locale="hu" month-picker/>
              </div>

              <div class="col-3">
                <label class="form-label mb-0 ms-0">Ingatlan megnevezése</label>
                <select id="choices-realestate" v-model.number="consumption.realestate_id"
                        name="choices-realestate" class="form-control"
                        @change="setRealEstate($event.target.value)">
                  <option value="">Válassz!</option>
                  <option v-for="realestate in getRealEstateList" :key="realestate.id"
                          :value="realestate.id">{{ realestate.name }}
                  </option>
                </select>
                <validation-error :errors="apiValidationErrors.pod"></validation-error>
              </div>

              <div class="col-4 mb-4">
                <material-input id="realestate_address" v-model:value="consumption.realestate_address"
                                label="Hely címe" name="realestate_address" variant="static"
                                disabled></material-input>
              </div>

              <div class="col-2 text-end">
                <Button type="button" label="Kiválasztás" icon="pi pi-check" :loading="loading"
                        @click.prevent="startRegistration"/>
              </div>
            </div>


          </div>
          <!--Card body-->
          <div class="card-body">
            <div class="w-100">
              <hr>
              <h2>{{ calendarDate.year + ' ' + (calendarDate.month + 1) }}</h2>
            </div>
            <!-- Data -->
            <div v-if="getConsumptionList !== null">
              <div v-for="network in getConsumptionList">
                <div v-if="network.get_meters.length !== 0" class="row d-flex align-items-center pb-2">
                  <div class="col-4">
                    <button :style="colorStyle(network.utility_services_id)" type="button"
                            data-bs-toggle="collapse" :data-bs-target="`#${network.pod}`"
                            aria-expanded="false" aria-controls="collapseExample">
                      POD: {{ network.pod }}
                    </button>
                  </div>
                  <div class="col-3">
                    Feldolgozottság: {{ getStatus(network) }}
                  </div>
                  <div class="col-3">
                    Státusz: {{ getResult(network, 'status') }}
                  </div>
                  <div class="col-2">
                    <i class="material-icons p-2" :style="circleColor(network)">circle</i>
                    <material-button class="float-right btn btm-sm"
                                     @click.prevent="addValues(network)" :disabled="isDisable(network)">
                      {{ getResult(network, 'button') }}
                    </material-button>
                  </div>
                  <div class="collapse p-4" :id="`${network.pod}`" :style="lineStyle(network.utility_services_id)">
                    <div class="table-responsive mt-2 px-4">
                      <div class="row" v-if="network.get_meters.length > 0">
                        <label class="col mb-2"><p class="font-weight-bold">Típus</p></label>
                        <label class="col mb-2"><p class="font-weight-bold">Mérőóra azonosító</p></label>
                        <label class="col mb-2"><p class="font-weight-bold">Utolsó leolvasás</p></label>
                        <label class="col mb-2"><p class="font-weight-bold">Előző havi óraállás</p></label>
                        <label class="col mb-2"><p class="font-weight-bold">Aktuális havi óraállás</p></label>
                        <label class="col mb-2"></label>
                      </div>
                      <div class="row" v-for="meter in network.get_meters">
                        <div class="col mb-2">
                          Főmérő
                        </div>
                        <div class="col mb-2">
                          {{ meter.product_number }}
                        </div>
                        <div class="col mb-2">
                          {{ getLastUpdateDate(meter) }}
                        </div>
                        <div class="col mb-1">
                          {{ getPrevMeterValue(meter) }}
                        </div>
                        <div class="col mb-2">
                          <material-input id="realestate_address" name="realestate_address"
                                          variant="static" :value="getMeterValue(meter)"
                                          @change="updateMeterValue($event, meter)"
                                          :placeholder="placeholderStyle(network.utility_services_id)"></material-input>
                        </div>
                        <div class="col mb-1">
                          <i :id="`circle_${meter.product_number}`" class="material-icons p-2"
                             :style="subCircleColor(meter)">circle</i>
                        </div>
                        <div class="row me-0 ms-0 pe-0 ps-0" v-if="meter.get_sub_meters.length > 0">
                          <label class="col mb-2"><p class="font-weight-bold"></p></label>
                          <label class="col mb-2"><p class="font-weight-bold"></p></label>
                          <label class="col mb-2"><p class="font-weight-bold"></p></label>
                          <label class="col mb-2"><p class="font-weight-bold"></p></label>
                          <label class="col mb-2"><p class="font-weight-bold"></p></label>
                          <label class="col mb-2"></label>
                        </div>
                        <div class="row me-0 ms-0 pe-0 ps-0" v-for="(submeter, index) in meter.get_sub_meters">
                          <div class="col mb-2">
                            Almérő
                          </div>
                          <div class="col mb-2">
                            {{ submeter.product_number }}
                          </div>
                          <div class="col mb-2">
                            {{ getLastUpdateDate(meter, index) }}
                          </div>
                          <div class="col mb-1">
                            {{ getPrevSubMeterValue(meter, index) }}
                          </div>
                          <div class="col mb-2">
                            <material-input id="realestate_address"
                                            :value="getSubMeterValue(meter, index)"
                                            @change="updateSubMeterValue($event, meter, submeter, index)"
                                            name="realestate_address" variant="static"
                                            :placeholder="placeholderStyle(network.utility_services_id)"
                            ></material-input>
                          </div>
                          <div class="col mb-1">
                            <i :id="`circle_${submeter.product_number}_${index}`" class="material-icons p-2"
                               :style="subSubCircleColor(meter, submeter, index)">circle</i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Choices from 'choices.js';
import {ref} from 'vue';
import {CButton, CCard, CCardBody, CCollapse} from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faArrowsTurnRight} from "@fortawesome/free-solid-svg-icons";

export default {
  name: 'Consumption',
  components: {
    MaterialButton,
    MaterialInput,
    VueDatePicker,
    CButton,
    CCollapse,
    CCard,
    CCardBody,
    FontAwesomeIcon,
  },
  mixins: [formMixin],
  setup() {
    const visible = ref(false);
    const calendarDate = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear()
    });
    return {
      color: '#33e8f5',
      calendarDate,
      visible,
    }
  },
  data() {
    return {
      consumption: {},
      dataConsumption: [],
      date: null,
      dataTable: null,
      consumptionList: null,
      faArrowsTurnRight,
    }
  },
  computed: {
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getutilityList() {
      return this.$store.getters["utilities/getUtilitiesData"];
    },
    getConsumptionList() {
      return this.$store.getters["consumption/getConsumptionData"];
    },
    selectedDate() {
      const date = new Date();
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
  },
  async mounted() {
    await this.$store.dispatch("realestates/getRealEstates");
    await this.$store.dispatch("utilities/getUtilities");

    if (document.getElementById("choices-realestate")) {
      const realestate = document.getElementById("choices-realestate");
      this.realestate = new Choices(realestate);
      if (this.realestate.getValue(true) != undefined) {
        this.currentRealEstate = this.getRealEstateList.filter(value => {
          return value.id == this.realestate.getValue(true)
        });
        await this.$store.dispatch("areas/getAreas", {
          filter: {
            ...(this.realestate.getValue(true) ? {realestate_id: String(this.realestate.getValue(true))} : {}),
          },
        });
        document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' + this.currentRealEstate[0]?.address;
      }
    }

    if (document.getElementById("choices-utility_services_id")) {
      var mutility = document.getElementById("choices-utility_services_id");
      new Choices(mutility);
    }
  },

  methods: {
    updateMeterValue(event, meter) {
      if (meter?.get_consumption_reg?.length > 0) {
        meter.get_consumption_reg[0].current_value = event.target.value;
      } else {
        meter.get_consumption_reg.push({current_value: event.target.value});
      }
      if (meter.get_prev_consumption_reg.length > 0) {
        console.log(event.target.value);
        if (parseFloat(meter.get_prev_consumption_reg[0].current_value) > parseFloat(event.target.value)) {
          document.getElementById("circle_" + meter.product_number).style.color = "#ffc000";
        } else if (parseFloat(meter.get_prev_consumption_reg[0].current_value) <= parseFloat(event.target.value)) {
          document.getElementById("circle_" + meter.product_number).style.color = "#6fac46";
        } else {
          document.getElementById("circle_" + meter.product_number).style.color = "red";
        }
      }
    },
    getMeterValue(meter) {
      if (meter.get_consumption_reg.length > 0) {
        return meter.get_consumption_reg[0].current_value;
      }
      return null;
    },
    getLastUpdateDate(meter) {
      if (Array.isArray(meter?.get_consumption_reg) && meter.get_consumption_reg.length > 0) {
        const firstEntry = meter.get_consumption_reg[0];
        return firstEntry?.r_month && firstEntry?.r_year
            ? `${firstEntry.r_month}/${firstEntry.r_year}`
            : 'N/A';
      }

      if (Array.isArray(meter?.get_prev_consumption_reg) && meter.get_prev_consumption_reg.length > 0) {
        const firstPrevEntry = meter.get_prev_consumption_reg[0];
        return firstPrevEntry?.r_month && firstPrevEntry?.r_year
            ? `${firstPrevEntry.r_month}/${firstPrevEntry.r_year}`
            : 'N/A';
      }

      return 'N/A';
    },
    getPrevMeterValue(meter) {
      if (meter && Array.isArray(meter.get_prev_consumption_reg) && meter?.get_prev_consumption_reg?.length > 0) {
        const firstEntry = meter.get_prev_consumption_reg[0];
        return firstEntry && typeof firstEntry.current_value !== 'undefined' ? firstEntry.current_value : null;
      }
      return null;
    },
    updateSubMeterValue(event, meter, submeter, index) {
      if (!meter || !Array.isArray(meter.get_sub_meters) || !meter.get_sub_meters[index]) {
        console.error("Invalid meter or submeter structure");
        return;
      }

      const subMeter = meter.get_sub_meters[index];

      if (!Array.isArray(subMeter.get_consumption_reg)) {
        subMeter.get_consumption_reg = [];
      }

      if (subMeter.get_consumption_reg.length > 0) {
        subMeter.get_consumption_reg[0].current_value = event.target.value;
      } else {
        subMeter.get_consumption_reg.push({current_value: event.target.value});
      }

      if (!Array.isArray(subMeter.get_prev_consumption_reg) || subMeter.get_prev_consumption_reg.length === 0) {
        console.warn("Previous consumption data is missing");
        return;
      }

      const prevValue = parseFloat(subMeter.get_prev_consumption_reg[0]?.current_value);
      const newValue = parseFloat(event.target.value);

      if (isNaN(prevValue) || isNaN(newValue)) {
        console.warn("Invalid meter values, skipping color update");
        return;
      }

      const circleElement = document.getElementById(`circle_${submeter?.product_number}_${index}`);
      if (!circleElement) {
        console.warn("Circle element not found");
        return;
      }

      if (prevValue > newValue) {
        circleElement.style.color = "#ffc000";
      } else if (prevValue <= newValue) {
        circleElement.style.color = "#6fac46";
      } else {
        circleElement.style.color = "red";
      }
    },
    getSubMeterValue(meter, index) {
      if (!meter || !Array.isArray(meter.get_sub_meters) || !meter.get_sub_meters[index]) {
        console.warn("Invalid meter or submeter structure");
        return null;
      }

      const subMeter = meter.get_sub_meters[index];

      if (Array.isArray(subMeter.get_consumption_reg) && subMeter.get_consumption_reg.length > 0) {
        return subMeter.get_consumption_reg[0]?.current_value ?? null;
      }

      return null;
    },

    getPrevSubMeterValue(meter, index) {
      if (!meter || !Array.isArray(meter.get_sub_meters) || !meter.get_sub_meters[index]) {
        console.warn("Invalid meter or submeter structure");
        return null;
      }

      const subMeter = meter.get_sub_meters[index];

      if (Array.isArray(subMeter.get_prev_consumption_reg) && subMeter.get_prev_consumption_reg.length > 0) {
        return subMeter.get_prev_consumption_reg[0]?.current_value ?? null;
      }

      return null;
    },
    lineStyle(item) {
      if (item == 1) {
        return "border-left: thick solid red;";
      } else if (item == 2) {
        return "border-left: thick solid #c5dfb3;";
      } else if (item == 3) {
        return "border-left: thick solid #9ac1e5;";
      } else if (item == 4) {
        return "border-left: thick solid #febf00;";
      }
      return "border-left: thick solid #afa99d;";
    },
    colorStyle(item) {
      if (item == 1) {
        return "width: 100%; background-color: red;";
      } else if (item == 2) {
        return "width: 100%; background-color: #c5dfb3;";
      } else if (item == 3) {
        return "width: 100%; background-color: #9ac1e5;";
      } else if (item == 4) {
        return "width: 100%; background-color: #febf00;";
      }
      return "width: 100%; background-color: #afa99d;";
    },
    placeholderStyle(item) {
      if (item == 1) {
        return "KWh";
      } else if (item == 2) {
        return "GJ";
      } else if (item == 3) {
        return "m3";
      } else if (item == 4) {
        return "m3";
      }
      return "";
    },
    circleColor(network) {
      if (network.get_meters.length > 0) {
        let meterCount = 0;
        let submetersCount = 0;
        let status = 0;

        meterCount = network.get_meters.length;
        for (let i = 0; i < network.get_meters.length; i++) {
          if (network.get_meters[i].get_consumption_reg[0] && network.get_meters[i].get_consumption_reg[0].current_value != '') {
            status += 1
          }
          if (network.get_meters[i].get_sub_meters.length > 0) {
            submetersCount += network.get_meters[i].get_sub_meters.length;
            for (let ii = 0; ii < network.get_meters[i].get_sub_meters.length; ii++) {
              if (network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0] && network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0].current_value != '') {
                status += 1
              }
            }
          }
        }
        const total = meterCount + submetersCount;


        if (status == total) {
          return "vertical-align: middle; color: green;";
        } else {
          return "vertical-align: middle; color: red;";
        }
      }
    },
    subCircleColor(meter) {
      if (
          !meter ||
          !Array.isArray(meter.get_consumption_reg) ||
          meter.get_consumption_reg.length === 0 ||
          !Array.isArray(meter.get_prev_consumption_reg) ||
          meter.get_prev_consumption_reg.length === 0
      ) {
        return "vertical-align: middle; color: red; float: right;";
      }

      const prevValue = parseFloat(meter.get_prev_consumption_reg[0]?.current_value);
      const currentValue = parseFloat(meter.get_consumption_reg[0]?.current_value);

      if (isNaN(prevValue) || isNaN(currentValue)) {
        return "vertical-align: middle; color: red; float: right;";
      }

      if (prevValue > currentValue || 2 * prevValue < currentValue) {
        return "vertical-align: middle; color: #ffc000; float: right;";
      }

      return "vertical-align: middle; color: #6fac46; float: right;";
    },
    subSubCircleColor(meter, submeter, index) {
      if (
          !meter || !Array.isArray(meter.get_sub_meters) || !meter.get_sub_meters[index] ||
          !Array.isArray(meter.get_sub_meters[index].get_prev_consumption_reg) ||
          meter.get_sub_meters[index].get_prev_consumption_reg.length === 0 ||
          !Array.isArray(meter.get_sub_meters[index].get_consumption_reg) ||
          meter.get_sub_meters[index].get_consumption_reg.length === 0
      ) {
        return "vertical-align: middle; color: red; float: right;";
      }

      const prevValue = parseFloat(meter.get_sub_meters[index].get_prev_consumption_reg[0]?.current_value);
      const currentValue = parseFloat(meter.get_sub_meters[index].get_consumption_reg[0]?.current_value);

      if (isNaN(prevValue) || isNaN(currentValue)) {
        return "vertical-align: middle; color: red; float: right;";
      }

      if (prevValue > currentValue || 2 * prevValue < currentValue) {
        return "vertical-align: middle; color: #ffc000; float: right;";
      }

      return "vertical-align: middle; color: #6fac46; float: right;";
    },

    getStatus(network) {
      if (!network || !Array.isArray(network.get_meters) || network.get_meters.length === 0) {
        return "0/0"; // Default if network or meters are missing
      }

      let meterCount = network.get_meters.length;
      let submetersCount = 0;
      let status = 0;

      for (let i = 0; i < meterCount; i++) {
        const meter = network.get_meters[i];
        if (Array.isArray(meter.get_consumption_reg) && meter.get_consumption_reg[0]?.current_value) {
          status += 1;
        }
        if (Array.isArray(meter.get_sub_meters)) {
          submetersCount += meter.get_sub_meters.length;
          for (let ii = 0; ii < meter.get_sub_meters.length; ii++) {
            const submeter = meter.get_sub_meters[ii];
            if (Array.isArray(submeter.get_consumption_reg) && submeter.get_consumption_reg[0]?.current_value) {
              status += 1;
            }
          }
        }
      }

      const total = meterCount + submetersCount;
      return `${status}/${total}`;
    },

    getResult(network, statusType) {
      if (!network || !Array.isArray(network.get_meters) || network.get_meters.length === 0) {
        return statusType === "status" ? "Várakozik" : "Feltöltés"; // Default if network is missing
      }

      let meterCount = network.get_meters.length;
      let submetersCount = 0;
      let status = 0;

      for (let i = 0; i < meterCount; i++) {
        const meter = network.get_meters[i];
        if (Array.isArray(meter.get_consumption_reg) && meter.get_consumption_reg[0]?.current_value) {
          status += 1;
        }
        if (Array.isArray(meter.get_sub_meters)) {
          submetersCount += meter.get_sub_meters.length;
          for (let ii = 0; ii < meter.get_sub_meters.length; ii++) {
            const submeter = meter.get_sub_meters[ii];
            if (Array.isArray(submeter.get_consumption_reg) && submeter.get_consumption_reg[0]?.current_value) {
              status += 1;
            }
          }
        }
      }

      const total = meterCount + submetersCount;

      if (statusType === "status") {
        return status === total ? "Feltöltve" : "Várakozik";
      } else {
        return status === total ? "Módosítás" : "Feltöltés";
      }
    },
    async startRegistration() {
      this.resetApiValidation();
      try {
        this.consumption.selectedDate = this.calendarDate;
        await this.$store.dispatch("consumption/getConsumption", this.consumption);
        showSwal.methods.showSwal({
          type: "success",
          message: "Számítás befejezve!",
          width: 500
        });
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });

      }
    },
    async setRealEstate(id) {
      this.currentRealEstate = this.getRealEstateList.filter(value => {
        return value.id == id
      });
      await this.$store.dispatch("areas/getAreas", {
        filter: {
          ...(id ? {realestate_id: id} : {}),
        },
      });
      document.getElementById('realestate_address').value = this.currentRealEstate[0] === undefined ? '' : this.currentRealEstate[0]?.zip + ' ' + this.currentRealEstate[0]?.city + ' ' + this.currentRealEstate[0]?.address;
    },
    async addValues(network) {
      network.registration_date = this.selectedDate;
      network.selectedDate = this.calendarDate;
      await this.$store.dispatch("consumption/addConsumption", network);
    },
    isDisable(network) {
      for (let i = 0; i < network.get_meters.length; i++) {
        if (network.get_meters[i].get_consumption_reg.length > 0) {
          if (!network.get_meters[i].get_consumption_reg[0].current_value) {
            return true;
          }
        } else {
          return true;
        }
        for (let ii = 0; ii < network.get_meters[i].get_sub_meters.length; ii++) {
          if (network.get_meters[i].get_sub_meters[ii].get_consumption_reg.length > 0) {
            if (!network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0].current_value) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
      return false;
    }
  }
}
</script>