/* eslint-disable no-unused-vars */
import consumptionService from "../services/consumption.service";

const initialState = {
  consumption: null,
  consumptionCalculation: null,
  status: null,
};

export const consumption = {
  namespaced: true,
  state: initialState,
  actions: {
    async getConsumption({ commit }, options) {
      const consumption = await consumptionService.getConsumption(options);
      commit("getConsumptionSuccess", consumption);
    },

    async addConsumption({ commit }, newConsumption) {
      await consumptionService.addConsumption(newConsumption);
    },

    async getStatus({ commit }, options) {
      const status = await consumptionService.getStatus(options);
      commit("getConsumptionStatusSuccess", status);
    },

    async getEvents({ commit }, options) {
      const events = await consumptionService.getEvents(options);
      commit("getConsumptionEventsSuccess", events);
    },

    async getConsumptionCalculation({ commit }, options) {
      const consumptionCalculation =
        await consumptionService.getConsumptionCalculation(options);
      commit("getConsumptionCalculationSuccess", consumptionCalculation);
    },
  },
  mutations: {
    getConsumptionSuccess(state, consumption) {
      state.consumption = consumption;
    },

    getConsumptionCalculationSuccess(state, consumptionCalculation) {
      state.consumptionCalculation = consumptionCalculation;
    },

    getConsumptionStatusSuccess(state, status) {
      state.status = status;
    },

    getConsumptionEventsSuccess(state, events) {
      state.events = events;
    },
  },
  getters: {
    getConsumptionData(state) {
      return state.consumption?.data;
    },

    getStatusData(state) {
      return state.status?.data;
    },

    getEventsData(state) {
      return state.events?.data;
    },

    getConsumptionCalculationData(state) {
      return state.consumptionCalculation?.data;
    },
  },
};
