<template>
  <Dialog :visible="isModalForMergeOpen" @update:visible="emit('close')" :modal="true" :style="{ width: '450px' }" header="Helyiségek összevonása">
    <div class="flex flex-col" style="display: flex;flex-direction: column; gap: 1rem;">
      <div>
        <label for="identifier" class="block font-bold mb-3" style="display: block; font-weight: 700;">Azonostó</label>
        <InputText 
          id="identifier" 
          v-model.trim="form.identifier" 
          required="true" 
          autofocus 
          fluid 
        />
        <small v-if="submitted && !form.identifier" class="text-red-500">Azonostó szükséges.</small>
      </div>
      <div>
        <label for="area_buildings_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Épületszám</label>
        <Select 
          id="area_buildings_id"
          v-model="form.area_buildings_id" 
          :options="buildingsList"
          optionLabel="name" 
          optionValue="id" 
          placeholder="Válasszon épületet!"
          fluid 
        />
      </div>
      <div>
        <label for="area_numbers_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helységszám</label>
        <Select 
          id="area_numbers_id" 
          v-model="form.area_numbers_id"
          :options="filteredAreaNumbersList"
          optionLabel="name" 
          optionValue="id" 
          placeholder="Válasszon helység számot!"
          fluid 
        />
      </div>
      <div>
        <label for="area_functions_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Funkció</label>
        <Select 
          id="area_functions_id" 
          v-model="form.area_functions_id"
          :options="areaFunctionsList"
          optionLabel="name" 
          optionValue="id" 
          placeholder="Válasszon funkciót!"
          fluid
        />
      </div>
      <div>
        <label for="area_positions_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Pozíció</label>
        <Select 
          id="area_positions_id"
          v-model="form.area_positions_id"
          :options="filteredAreaPositionsList"
          optionLabel="name" 
          optionValue="id" 
          placeholder="Válasszon pozíciót!"
          fluid
        />
      </div>
      <div>
        <label for="lot_number" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helyrajzi szám</label>
        <InputText 
          id="lot_number" 
          v-model.trim="form.lot_number"
          required="true"
          autofocus 
          fluid 
          disabled
        />
        <small v-if="submitted && !form.lot_number" class="text-red-500">Helyrajzi szám szükséges.</small>
      </div>
      <div>
        <label for="net_floor_space" class="block font-bold mb-3" style="display: block; font-weight: 700;">Nettó terület</label>
        <InputText 
          id="net_floor_space" 
          v-model.trim="form.net_floor_space"
          required="true"
          autofocus 
          fluid 
        />
        <small v-if="submitted && !form.net_floor_space" class="text-red-500">Nettó terület szükséges.</small>
      </div>
      <div>
        <label for="gross_floor_space" class="block font-bold mb-3" style="display: block; font-weight: 700;">Bruttó terület</label>
        <InputText 
          id="gross_floor_space" 
          v-model.trim="form.gross_floor_space"
          required="true" 
          autofocus 
          fluid 
        />
        <small v-if="submitted && !form.gross_floor_space" class="text-red-500">Bruttó terület szükséges.</small>
      </div>
      <div>
        <label for="height" class="block font-bold mb-3" style="display: block; font-weight: 700;">Magasság</label>
        <InputText 
          id="height" 
          v-model.trim="form.height"
          required="true" 
          autofocus 
          fluid 
        />
        <small v-if="submitted && !form.height" class="text-red-500">Magasság szükséges.</small>
      </div>
      <div>
        <label for="area_floor_coverings_id" class="block font-bold mb-3" style="display: block; font-weight: 700;">Burkolat</label>
        <Select 
          id="area_floor_coverings_id" 
          v-model="form.area_floor_coverings_id"
          :options="areaFloorCoveringsList"
          optionLabel="name" 
          optionValue="id" 
          placeholder="Válasszon burkolatot!"
          fluid
        />
      </div>
    </div>
    <template #footer>
      <Button label="Mégse" icon="pi pi-times" text @click="hideDialog" />
      <Button label="Mentés" icon="pi pi-check" :loading="loading" @click="saveArea" />
    </template>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, computed, watch } from "vue";
import { useStore } from "vuex";
import showSwal from "../../../../mixins/showSwal";

const props = defineProps({
  isModalForMergeOpen: Boolean,
  selectedareas: Object,
  realestateId: Object,
});

const emit = defineEmits(["close"]);
const hideDialog = () => {
  emit("close");
};

const store = useStore();
const buildingsList = computed(() => store.getters["buildings/getBuildingsData"] || []);
const areaNumbersList = computed(() => store.getters["areanumbers/getAreanumbersData"] || []);
const areaFunctionsList = computed(() => store.getters["afunctions/getFunctionsData"] || []);
const areaPositionsList = computed(() => store.getters["positions/getPositionsData"] || []);
const areaFloorCoveringsList = computed(() => store.getters["coverings/getCoveringsData"] || []);
const filteredAreaNumbersList = computed(() => {
  return areaNumbersList.value.filter(areaNumber =>
    props.selectedareas.some(area =>
      area.area_numbers.some(n => n.id == areaNumber.id)
    )
  );
});
const filteredAreaPositionsList = computed(() => {
  return areaPositionsList.value.filter(areaPosition =>
    props.selectedareas.some(area =>
      area.area_position.some(p => p.id == areaPosition.id)
    )
  );
});

const submitted = ref(false);
const loading = ref(false);
const mergeIds = ref(null);
const form = ref({
    id: null, 
    identifier: "",
    realestate_id: "",
    area_buildings_id: "", 
    area_numbers_id: "", 
    lot_number: "",
    area_positions_id: "",
    area_functions_id: "", 
    net_floor_space: 0,
    gross_floor_space: 0, 
    height: 0, 
    area_floor_coverings_id: "", 
});

watch(() => props.selectedareas, (newVal) => {
  if (newVal && newVal.length > 0) {
    const selectedArea = newVal[0];
    form.value.lot_number = selectedArea.lot_number || "";
    
    const totalNetFloorSpace = newVal.reduce((sum, area) => {
      return sum + parseFloat(area.net_floor_space || 0);
    }, 0);

    const totalGrossFloorSpace = newVal.reduce((sum, area) => {
      return sum + parseFloat(area.gross_floor_space || 0);
    }, 0);

    const totalHeight = newVal.reduce((sum, area) => {
      return sum + parseFloat(area.height || 0);
    }, 0);
    
    form.value.net_floor_space = totalNetFloorSpace;
    form.value.gross_floor_space = totalGrossFloorSpace;
    const averageHeight = totalHeight / newVal.length;
    form.value.height = averageHeight.toFixed(2);
    
    mergeIds.value = newVal.map((area) => area.id);
  }
}, 
{ immediate: true });

const deleteSelectedAreas = async () => {
  try {
    mergeIds.value.forEach(async (id) => {
      await store.dispatch("areas/deleteArea", id);
    });
    emit("removeAreas", mergeIds.value);
    mergeIds.value = null;

  } catch (error) {
    console.log('Error in deleting a merged areas:', error);
  }
};

const saveArea = async () => {
  submitted.value = true;
  loading.value = true;
  form.value.realestate_id = props.realestateId;
  try {
    const areaId = await store.dispatch("areas/addArea", form.value);
    form.value.id = areaId.toString();
    await deleteSelectedAreas();
    emit("updateAreas", form.value);
    loading.value = false;
    showSwal.methods.showSwal({
      type: "success",
      message: "Sikeresen összevonta a helyiségeket!",
      width: 500
    });
    emit("close");
    
  } catch (error) {
    console.log('Error in saving the merged area:', error);
    showSwal.methods.showSwal({
      type: "error",
      message: "Valami hiba történt!",
      width: 500
    });
  }
};
</script>