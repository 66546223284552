/* eslint-disable no-unused-vars */
import areanumbersService from '../services/areanumbers.service';

const initialState = { areanumbers: null };

export const areanumbers = {
    namespaced: true,
    state: initialState,
    actions: {
        async getAreanumbers({ commit }, options) {
            const areanumbers = await areanumbersService.getAreanumbers(options);
            commit('getAreanumbersSuccess', areanumbers);
        },
    },
    mutations: {
        getAreanumbersSuccess(state, areanumbers) {
            state.areanumbers = areanumbers;
        },
    },
    getters: {
        getAreanumbersData(state) {
            return state.areanumbers?.data;
        },
    }
}

