<script setup>
import FormError from '@/components/FormError.vue'

const props = defineProps({
    modelValue: [String, Number, Boolean],
    label: String,
    error: String,
})
</script>

<template>
    <div class="col-12">
        <div class="d-flex flex-row gap-2">
            <label
                v-if="props.label"
                class="form-label text-secondary mb-2"
                v-html="props.label"
            />
            <slot/>
        </div>

        <FormError
            v-if="props.error"
            :error="props.error"
        />
    </div>
</template>