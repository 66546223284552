<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Főmérő regisztráció</h5>
              </div>
              <div class="col-6 text-end">
                <Button type="button" class="mx-2" label="Mentés" icon="pi pi-save" :loading="loading"
                        @click.prevent="handleAdd"/>
                <Button
                    severity="warn"
                    type="button" label="Vissza" icon="pi pi-arrow-left"
                    @click="this.$router.push({ name: 'Meters List' })"/>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-5">
                  <label class="form-label mb-0 ms-0">POD</label>
                  <FormDropdown
                      v-model="meter.pod"
                      :possible-values="UtilitiesBaseList"
                      :searchable="true"
                      mode="single"
                  />
                  <validation-error :errors="apiValidationErrors.pod"></validation-error>
                </div>

                <div class="col-5">
                  <material-input id="realestate_name" v-model:value="meter.realestate_name" label="Hely neve"
                                  name="realestate_name" variant="static" disabled></material-input>
                </div>

                <div class="col-5">
                  <material-input id="realestate_address" v-model:value="meter.realestate_address" label="Hely címe"
                                  name="realestate_address" variant="static" disabled></material-input>
                </div>
              </div>

              <br/>

              <div class="row p-4 bg-light">
                <div class="col-5">
                  <material-input id="product_number" v-model:value="meter.product_number" label="Gyáriszám"
                                  name="product_number" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Mérési egység</label>
                  <FormDropdown
                      v-model="meter.measurement_units_id"
                      :possible-values="getMeasurementUnitsBaseList"
                      :searchable="true"
                      mode="single"
                  />
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Beüzemelés időpontja</label>
                  <VueDatePicker id="selected-date" v-model="currentDate" :teleport="true" :format-locale="hu"
                                 :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ"
                                 cancel-text="Bezár"/>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Pozíció</label>
                  <FormDropdown
                      v-model="meter.position"
                      :possible-values="getAreaPositionsList"
                      :searchable="true"
                      mode="single"
                  />
                </div>

                <div class="col-5">
                  <label class="form-label">Helységek hozzáadása</label>
                  <FormDropdown
                      v-model="meter.area_ids"
                      :possible-values="areas"
                      :close-on-select="false"
                      :searchable="true"
                      mode="tags"
                  />
                  <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import MultiSelect from "@/components/MultiSelect.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import {hu} from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';
import FormDropdown from "../../../components/FormDropdown.vue";

export default {
  name: 'AddMeter',
  components: {
    FormDropdown,
    MaterialButton,
    MaterialInput,
    VueDatePicker,
    MultiSelect,
    ValidationError,
  },
  mixins: [formMixin],
  setup() {
    const currentDate = ref(new Date());
    const currentSubDate = ref(new Date());
    const areas = ref([]);
    const visible = ref(false);
    const meter = ref({});
    const submeter = ref([]);
    const date = ref(null);

    const format = (date) => {
      const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
    };

    return {
      color: '#33e8f5',
      currentDate,
      currentSubDate,
      hu,
      format,
      areas,
      visible,
      meter,
      submeter,
      date,
    }
  },
  watch: {
    'meter.pod': {
      async handler(newValue) {
        if (!newValue) return;

        const meterPod = this.meter.pod ?? null;
        if (!meterPod) return;

        const selectedUtility = this.UtilitiesList.find(value => value.id === meterPod);
        if (!selectedUtility) return;

        const realestate = this.getRealEstateList.find(value => value.id === selectedUtility.realestate_id);
        if (realestate) {
          this.$nextTick(() => {
            const realestateName = document.getElementById('realestate_name');
            const realestateAddress = document.getElementById('realestate_address');

            if (realestateName) realestateName.value = realestate.name;
            if (realestateAddress) realestateAddress.value = `${realestate.zip} ${realestate.city} ${realestate.address}`;
          });

          this.meter.area_ids = [];
          this.meter.position = null;
          this.areas = [];
          await this.$store.dispatch("realestates/getRealEstateAreas", realestate.id);
          this.areas = this.$store.getters["realestates/getRealEstateAreas"];
        }
      },
      deep: true,
      immediate: false,
    }
  },
  computed: {
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getMeasurementUnitsList() {
      return this.$store.getters["munits/getMunitsData"];
    },
    getMeasurementUnitsBaseList() {
      const data = this.getMeasurementUnitsList ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
    UtilitiesList() {
      return this.$store.getters["unetworks/getUnetworksData"];
    },
    UtilitiesBaseList() {
      const data = this.UtilitiesList ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.pod,
        }
      })
    },
    getutilityList() {
      return this.$store.getters["utilities/getUtilitiesData"];
    },
    getAreaPositionsList() {
      const data = this.$store.getters["positions/getPositionsData"] ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("realestates/getRealEstates"),
        this.$store.dispatch("unetworks/getUnetworks"),
        this.$store.dispatch("areas/getAreas"),
        this.$store.dispatch("positions/getPositions"),
        this.$store.dispatch("munits/getMunits"),
        this.$store.dispatch("utilities/getUtilities"),
        this.$store.dispatch("stypes/getStypes"),
      ]);

      this.loading = false;
    } catch (error) {
      console.error("Error loading data:", error);
    }
  },

  methods: {
    async handleAdd() {
      this.resetApiValidation();
      try {
        this.meter.currentDate = this.currentDate;
        this.meter.submeter = this.submeter;
        await this.$store.dispatch("meters/addMeter", this.meter);
        showSwal.methods.showSwal({
          type: "success",
          message: "Főmérő sikeresen hozzáadva!",
          width: 500
        });
        this.$router.push({name: "Meters List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });
      }
    },
  }
}
</script>