import axios from 'axios';
import authHeader from './auth-header';
import Jsona from "jsona";

const API_URL = process.env.VUE_APP_API_BASE_URL;
const dataFormatter = new Jsona();

export default {
    async getContacts(params) {
        const response = await axios.get(API_URL + "/contacts", {headers: authHeader(), params: params});
        return {data: dataFormatter.deserialize(response.data), meta: response.data.meta?.page};
    },

    async getContactsBaseData(params) {
        const response = await axios.get(API_URL + "/data/contacts", {headers: authHeader(), params: params});
        return response.data;
    },

    async addContact(contact) {
        contact.type = "contacts"
        const newJson = dataFormatter.serialize({stuff: contact})
        const response = await axios.post(API_URL + "/contacts", newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async deleteContact(contactId) {
        await axios.delete(API_URL + "/contacts/" + contactId, {headers: authHeader()});
    },

    async getContact(contactId) {
        const response = await axios.get(API_URL + "/contacts/" + contactId, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },

    async editContact(contact) {
        contact.type = "contacts"
        const newJson = dataFormatter.serialize({stuff: contact})
        const response = await axios.patch(API_URL + "/contacts/" + contact.id, newJson, {headers: authHeader()});
        return dataFormatter.deserialize(response.data);
    },
}




