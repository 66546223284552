<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 tag">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="tag d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Számlázás</h5>
              </div>
            </div>
          </div>

          <!-- Card body -->
          <div class="m-3 row">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="col-2">
                <label class="form-label mb-1 ms-0">Év/Hónap</label>
                <VueDatePicker v-model="calendarDate" :teleport="true" locale="hu" month-picker/>
              </div>

              <div class="col-2">
                <label class="form-label mb-1 ms-0">Ingatlan</label>
                <select id="choices-realestate" name="choices-realestate" v-model="billing.realestate_id"
                        class="form-control">
                  <option value="">Válassz!</option>
                  <option v-for="realEstate in getRealEstateList" :key="realEstate.id" :value="realEstate.id">
                    {{ realEstate.name }}
                  </option>
                </select>
              </div>

              <div class="col-2">
                <label class="form-label mb-1 ms-0">Helyiség</label>
                <select id="choices-area" name="choices-area" v-model="billing.area_id" class="form-control">
                  <option value="">Válassz!</option>
                  <option v-for="area in getAreaList" :key="area.id" :value="area.id">{{ area.building_nr }}</option>
                </select>
              </div>

              <div class="col-2">
                <label class="form-label mb-1 ms-0">Közmű</label>
                <select id="choices-utility_services_id" name="choices-utility_services_id"
                        v-model="billing.utility_services_id" class="form-control">
                  <option value="">Válassz!</option>
                  <option v-for="utility in getUtilityList" :key="utility.id" :value="utility.id">{{ utility.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="px-0 pb-0 card-body">
            <div v-if="filteredBillingsData && filteredBillingsData.data" class="mt-2 px-4">
              <OutInvoiceList :filteredBillingsData="filteredBillingsData"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Choices from 'choices.js';
import {ref} from 'vue';
import formMixin from "@/mixins/formMixin.js";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import OutInvoiceList from './components/OutInvoiceList.vue';

export default {
  name: 'OutGoingInvoiceList',
  components: {
    VueDatePicker,
    OutInvoiceList,
  },
  mixins: [formMixin],
  setup() {
    const visible = ref(false);
    const calendarDate = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });
    return {
      color: '#33e8f5',
      calendarDate,
      visible,
    };
  },
  data() {
    return {
      billing: {
        realestate_id: null,
        area_id: null,
        utility_services_id: null,
      },
      date: null,
    }
  },
  computed: {
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getAreaList() {
      return this.$store.getters["areas/getAreasData"];
    },
    getUtilityList() {
      return this.$store.getters["utilities/getUtilitiesData"];
    },
    filteredBillingsData() {
      return this.$store.getters['billings/getFilteredBillingsData'];
    },
  },
  watch: {
    calendarDate: {
      handler() {
        if (
            this.billing.realestate_id !== null &&
            this.billing.area_id !== null &&
            this.billing.utility_services_id !== null
        ) {
          this.filterBillings();
        }
      },
      deep: true,
    },
    "billing.realestate_id": {
      handler() {
        if (this.billing.realestate_id !== null && this.billing.utility_services_id !== null) {
          this.filterBillings();
        }
      },
      deep: true,
    },
    "billing.area_id": {
      handler() {
        if (this.billing.realestate_id !== null && this.billing.utility_services_id !== null) {
          this.filterBillings();
        }
      },
      deep: true,
    },
    "billing.utility_services_id": {
      handler() {
        if (this.billing.realestate_id !== null && this.billing.utility_services_id !== null) {
          this.filterBillings();
        }
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("realestates/getRealEstates"),
        this.$store.dispatch("areas/getAreas"),
        this.$store.dispatch("utilities/getUtilities"),
      ]);
    } catch (error) {
      console.error("Error loading data:", error);
    }

    if (document.getElementById("choices-realestate")) {
      const realestate = document.getElementById("choices-realestate");
      this.realestate = new Choices(realestate);
    }
    if (document.getElementById("choices-area")) {
      const area = document.getElementById("choices-area");
      this.area = new Choices(area);
    }
    if (document.getElementById("choices-utility_services_id")) {
      const mutility = document.getElementById("choices-utility_services_id");
      new Choices(mutility);
    }
  },
  methods: {
    async filterBillings() {
      try {
        this.billing.r_month = this.calendarDate.month + 1;
        this.billing.r_year = this.calendarDate.year;
        console.log("billing:", this.billing);
        await this.$store.dispatch("billings/filterBillingData", this.billing);
      } catch (error) {
        console.error("Error in filterBillings:", error);
      }
    },
  },
};
</script>