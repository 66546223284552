<script setup>
import { computed, onMounted, ref } from 'vue'
import FormEntry from "@/components/FormEntry.vue";

const props = defineProps({
    modelValue: String | Number,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
    centerContent: Boolean,
    type: {
        type: String,
        required: false,
        default: 'text',
    },
    autocomplete: {
        type: String,
        required: false,
        default: '',
    },
    errors: Object | Array | String
})

defineEmits(['update:modelValue'])

const input = ref(null)

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus()
    }
})

defineExpose({ focus: () => input.value.focus() })

const error = ref('');
const hasError = computed(() => {
    if (Array.isArray(props.errors)) {
        return props.errors.length > 0;
    }
    if (typeof props.errors === 'object' && props.errors !== null) {
        return Object.keys(props.errors).length > 0;
    }
    return typeof props.errors === 'string' && props.errors.trim().length > 0;
});
</script>

<template>
    <FormEntry :model-value="modelValue" :error="errors">
        <input ref="input"
            :class="['form-control form-control-sm border shadow custom-padding custom-round', hasError ? 'border-danger' : '', disabled ? 'text-muted' : '', centerContent ? 'text-center' : '']"
            :value="modelValue" :placeholder="placeholder" :readonly="readonly" :disabled="disabled" :type="type"
            :autocomplete="autocomplete" @input="$emit('update:modelValue', $event.target.value)" />
    </FormEntry>
</template>

<style scoped>
.custom-padding {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-color: #D1D5DB;
    min-width: 150px;
}
.custom-round {
    border-radius: 0.375rem !important;
}
</style>
