<template>
  <div class="position-relative d-flex align-items-center">
    <div
        class="rounded-circle d-inline-block"
        :class="statusColor"
        style="width: 16px; height: 16px; cursor: pointer;"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
    ></div>
    <div
        v-if="showTooltip"
        class="position-absolute bg-dark text-white text-small px-2 rounded shadow-sm"
        style="left: 24px; top: 0; white-space: nowrap;"
    >
      {{ statusText }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showTooltip: false,
      statuses: {
        no_invoice: { color: 'bg-primary', text: 'A számla hiányzik!' },
        no_lease: { color: 'bg-secondary', text: 'A helyiséghez nem tartozik bérlő!' },
        missing_registration: { color: 'bg-warning', text: 'Hiányzó fogyasztás regisztráció!' },
        calculated: { color: 'bg-success', text: 'Sikeres számítás!' },
        error: { color: 'bg-danger', text: 'Hiba történt!' }
      }
    };
  },
  computed: {
    statusColor() {
      return this.statuses[this.status]?.color || 'bg-light';
    },
    statusText() {
      return this.statuses[this.status]?.text || 'Unknown status';
    }
  }
};
</script>

<style scoped>
.position-relative:hover .position-absolute {
  display: block;
}
</style>
