import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    async getMasterData(params) {
        const response = await axios.post(API_URL + "/masterdata/" + params, { headers: authHeader() });
        return { data: response.data, meta: response.data.meta?.page};
    },

    async createMasterData(params) {
        const response = await axios.post(API_URL + "/masterdata-create", { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page};
    },

    async updateMasterData(params) {
        const response = await axios.put(API_URL + "/masterdata-update/" + params.selection + "/" + params.slug, { headers: authHeader(), params: params });
        return { data: response.data, meta: response.data.meta?.page};
    },

    async deleteMasterData(params) {
        const response = await axios.delete(API_URL + "/masterdata-delete/" + params.selection + "/" + params.slug, { headers: authHeader() });
        return { data: response.data, meta: response.data.meta?.page};
    },
};