<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Közmű Hozzáadása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Utilities List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div> 
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Fogyasztási hely címe</label>
                                    <FormDropDown
                                        v-model.number="computedRealEstateId"
                                        :possible-values="getRealEstateList"
                                        placeholder="Válassz hely címet!"
                                        :searchable="true"
                                        mode="single"
                                    />
                                    <validation-error :errors="apiValidationErrors.realestate_id"></validation-error>
                                </div>

                                <div class="col-5 mt-2">
                                    <material-input id="realestate_address" label="Fogyasztási hely neve"
                                        name="realestate_address" variant="static" :value="realEstateAddress"></material-input>
                                    <validation-error :errors="apiValidationErrors.realestate_address"></validation-error>
                                </div>

                                <div class="col-5">
                                    <label class="form-label mb-0 ms-0">Közmű szolgáltatás</label>
                                    <FormDropDown
                                        v-model.number="utility.utility_services_id"
                                        :possible-values="getutilityList"
                                        placeholder="Válassz közmű szolgáltatást!"
                                        :searchable="true"
                                        mode="single"
                                    />
                                </div>

                                <div class="col-5 mt-2">
                                    <material-input id="network_provider_name" v-model:value="utility.network_provider_name"
                                        label="Hálózati szolgáltató" name="network_provider_name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.network_provider_name"></validation-error>
                                </div>

                                <div class="col-5">
                                    <material-input id="pod" v-model:value="utility.pod"
                                        label="POD" name="pod" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.pod"></validation-error>
                                </div>
                                
                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">

                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleAdd">Hozzáadás</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormDropDown from "@/components/FormDropdown.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
    name: 'AddUtility',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        FormDropDown,
    },
    mixins: [formMixin],
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    data() {
        return {
            utility: {},
            realEstateAddress: "",
        }
    },
    computed: {
        getutilityList() {
            const data = this.$store.getters["utilities/getUtilitiesData"] ?? [];
            return data.map(item => ({
                value: item.id,
                label: item.name
            }));
        },
        getRealEstateList() {
            const data = this.$store.getters["realestates/getRealEstatesData"] ?? [];
            return data.map(item => ({
                value: item.id,
                label: item.zip + ' ' + item.city + ', ' + item.address,
                name: item.name
            }));
        },
        computedRealEstateId: {
            get() {
                return this.utility.realestate_id;
            },
            set(val) {
                this.utility.realestate_id = val ? parseFloat(val) : null;
            }
        },
    },
    watch: {
        computedRealEstateId(newVal) {
            const selectedRealEstate = this.getRealEstateList.find(item => item.value === newVal);
            this.realEstateAddress = selectedRealEstate ? selectedRealEstate.name : "";
        }
    },
    async mounted() {
        await this.$store.dispatch("utilities/getUtilities");
        await this.$store.dispatch("realestates/getRealEstates");
    },
    methods: {
        async handleAdd() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("unetworks/addUnetwork", this.utility);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Főmérő sikeresen hozzáadva!",
                    width: 500
                });
                this.$router.push({ name: "Utilities List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        async setCity(event) {
            this.currentRealEstate = this.getRealEstateList.filter(value => {
                return value.id == event
            });
            document.getElementById('realestate_address').value = this.currentRealEstate[0].name;
        },
    },
}
</script>