<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header border-bottom">
                        <div class="row d-flex align-items-center">
                            <div class="col-6">
                                <h5 class="mb-0">Kapcsolat Módosítása</h5>
                            </div>
                            <div class="col-6 text-end">
                                <material-button class="float-right btn btm-sm"
                                    @click="this.$router.push({ name: 'Contacts List' })">
                                    Vissza
                                </material-button>
                            </div>
                        </div>
                    </div>
                    <!--Card body-->
                    <div class="card-body">
                        <form>
                            <div class="row">
                                <div class="col-4">
                                    <material-input id="name" v-model:value="contact.name" label="Cégnév/Név"
                                        name="name" variant="static"></material-input>
                                    <validation-error :errors="apiValidationErrors.name"></validation-error>
                                </div>

                                <div class="col-4">
                                <material-input id="vat_number" v-model:value="contact.vat_number" label="Adószám"
                                    name="vat_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.vat_number"></validation-error>
                                </div>

                                <div class="col-4">
                                    <FormDropDown
                                        v-model.number="contact.contact_types_id"
                                        :possible-values="contacttypesList"
                                        placeholder="Válassz típust!"
                                        :searchable="true"
                                        mode="single"
                                    />
                                </div>

                                <div class="col-6">
                                <material-input id="manager" v-model:value="contact.account_number" label="Ügyvezető"
                                    name="manager" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.manager"></validation-error>
                                </div>

                                <div class="col-3">
                                <material-input id="contact" v-model:value="contact.contact" label="Kapcsolattartó"
                                    name="contact" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.contact"></validation-error>
                                </div>

                                <div class="col-3">
                                <material-input id="phone_number" v-model:value="contact.phone_number" label="Telefonszám"
                                    name="phone_number" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.phone_number"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="address" v-model:value="contact.address" label="Levelezési cím"
                                    name="address" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.address"></validation-error>
                                </div>

                                <div class="col-5">
                                <material-input id="email" v-model:value="contact.email" label="Kapcsolattartási Email"
                                    name="email" variant="static"></material-input>
                                <validation-error :errors="apiValidationErrors.email"></validation-error>
                                </div>

                                <div class="col-10 border border-secondary rounded m-2 p-2">
                                    <div v-for="(data, index) in ownersdata" :key="index">
                                        <div class="row">
                                            <div class="col-5">
                                                <material-input :id="`owner${index + 1}`" v-model:value="contact['owner' + (index + 1)]" :label="`${index + 1}. Tulajdonos neve`"
                                                    :name="`owner${index + 1}`"  variant="static" @input="updateOwner($event.target.value, index)"></material-input>
                                                    <validation-error :errors="apiValidationErrors['owner' + (index + 1)]"></validation-error>
                                            </div>
                                            <div class="col-5">
                                                <material-input :id="`ownership${index + 1}`" v-model:value="contact['ownership' + (index + 1)]" label="Tulajdoni hányad"
                                                    :name="`ownership${index + 1}`" variant="static"  @input="updateOwnership($event.target.value, index)"></material-input>
                                                    <validation-error :errors="apiValidationErrors['ownership' + (index + 1)]"></validation-error>
                                            </div>
                                            <div class="col-2 d-table">
                                                <span aria-hidden="true" class="text-xs font-weight-bold d-table-cell align-middle">
                                                    <i v-show="index === 0 && ownersdata.length < 3" class="fas fa-plus-circle cursor-pointer" @click="addMore()"> MEZŐ HOZZÁADÁSA</i>
                                                    <i v-show="index != 0 && ownersdata.length === (index + 1)" class="fas fa-minus-circle cursor-pointer" @click="remove(index)"> ELTÁVOLÍTÁS</i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="row mt-5 mb-5 d-flex align-items-center">
                                <div class="col-2 text-end mt-5">
                                    <material-button class="float-right btn btm-sm" @click.prevent="handleEdit">Módosít</material-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormDropDown from "@/components/FormDropdown.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash"

export default {
    name: 'editContacts',
    components: {
        MaterialButton,
        MaterialInput,
        ValidationError,
        FormDropDown,
    },
    mixins: [formMixin],
    data() {
        return {
            contact: {},
            ownersdata: [
                {
                    ownerName: "",
                    ownership: "",
                },
            ],
        }
    },
    computed: {
        getcontacttypesList() {
            return this.$store.getters["contacttypes/getContactTypesData"];
        },
        contacttypesList() {
            const data = this.getcontacttypesList ?? [];
            return data.map(item => ({
                value: item.id,
                label: item.name
            }));
        },
    },
    setup() {
        return {
            color: '#33e8f5'
        }
    },
    async mounted() {
        await this.$store.dispatch("contacttypes/getContactTypes");

        try {
            await this.$store.dispatch("contacts/getContact", this.$route.params.id);
            this.contact = _.omit(this.$store.getters['contacts/getContact'], 'links');
        } catch (error) {
            showSwal.methods.showSwal({
                type: "error",
                message: `Valami hiba történt!`,
                width: 500
            });
        }

        if (this.contact.owner1 != null) {
            this.ownersdata.push({
                ownerName: this.contact.owner1,
                ownership: this.contact.ownership1,
            });
        }
        if (this.contact.owner2 != null) {
            this.ownersdata.push({
                ownerName: this.contact.owner2,
                ownership: this.contact.ownership2,
            });
        }
        if (this.contact.owner3 != null) {
            this.ownersdata.push({
                ownerName: this.contact.owner3,
                ownership: this.contact.ownership3,
            });
        }
    },
    methods: {
        async handleEdit() {
            this.resetApiValidation();
            try {
                await this.$store.dispatch("contacts/editContact", this.contact);
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Kapcsolat sikeresen módosítva!",
                    width: 500
                });
                this.$router.push({ name: "Contacts List" })
            } catch (error) {
                if (error.response.data.errors) {
                    this.setApiValidation(error.response.data.errors);
                }
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });

            }
        },
        addMore() {
            this.ownersdata.push({
                ownerName: "",
                ownership: "",
            });
        },
        remove(index) {
            this.ownersdata.splice(index, 1);
        },
        updateOwner: function(value, index) {
            this.contact['owner' + (index + 1)] = value;
            this.$emit('input', value);
        },
        updateOwnership: function(value, index) {
            this.contact['ownership' + (index + 1)] = value;
            this.$emit('input', value);
        }
    }
}
</script>