<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Szerződés Módosítása</h5>
              </div>
              <div class="col-6 text-end">
                <Button type="button" label="Mentés" icon="pi pi-save" @click.prevent="handleEdit"/>
                <Button
                    severity="warn"
                    type="button" label="Vissza" icon="pi pi-arrow-left"
                    @click="this.$router.push({ name: 'Scontracts List' })"/>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="row pb-2">
                <div class="p-4 pt-1">
                  <hr>
                  <h2 style="width: 100%;">1. SZERZŐDÉS TÁRGYA</h2>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">POD</label>
                  <FormDropDown
                    v-model.number="contract.pod"
                    :possible-values="getUtilitiesList"
                    placeholder="Válassz típust!"
                    :searchable="true"
                    mode="single"
                    :handleSingleChange="setRealEstate()"
                  />
                  <validation-error :errors="apiValidationErrors.pod"></validation-error>
                </div>

                <div class="col-3">
                  <material-input id="realestate_name" v-model:value="contract.realestate_name" label="Hely neve"
                                  name="realestate_name" variant="static" disabled></material-input>
                </div>

                <div class="col-3">
                  <material-input id="realestate_address" v-model:value="contract.realestate_address" label="Hely címe"
                                  name="realestate_address" variant="static" disabled></material-input>
                </div>

                <div class="col-3">
                  <material-input id="contract_number" v-model:value="contract.contract_number" label="Szerződés száma"
                                  name="contract_number" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.contract_number"></validation-error>
                </div>

                <div class="col-3">
                  <material-input id="utility_service" label="Szolgáltatás típusa"
                                  name="utility_service" variant="static" v-model:value="contract.utility_service" disabled></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="col-3">
                  <material-input id="meter_product_number" label="Főmérő gyári száma"
                                  name="meter_product_number" variant="static" v-model:value="contract.meter_product_number" disabled></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="col-3">
                  <material-input id="network_provider_name" label="Szolgáltató"
                                  name="network_provider_name" variant="static" v-model:value="contract.network_provider_name" disabled></material-input>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Felhasználó</label>
                  <FormDropDown
                    v-model.number="contract.owners_id"
                    :possible-values="getOwnersList"
                    placeholder="Válassz felhasználót!"
                    :searchable="true"
                    mode="single"
                  />
                </div>
              </div>

              <div class="row bg-light">
                <div class="p-4">
                  <hr>
                  <h2 style="width: 100%;">2. SZOLGÁLTATÁSI IDŐSZAK</h2>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Időtartama</label>
                  <FormDropDown
                    v-model.number="contract.lease_durations_id"
                    :possible-values="getLeaseDurationsList"
                    placeholder="Válassz időtartamot!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Kezdete</label>
                  <VueDatePicker id="selected-start-date" v-model="currentStartDate" :teleport="true"
                                 :format-locale="hu" :locale="hu" :format="format" :enable-time-picker="false"
                                 select-text="Választ" cancel-text="Bezár"/>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Vége</label>
                  <VueDatePicker id="selected-end-date" v-model="currentEndDate" :teleport="true" :format-locale="hu"
                                 :locale="hu" :format="format" :enable-time-picker="false" select-text="Választ"
                                 cancel-text="Bezár"/>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Auto. hosszabbítás</label>
                  <FormDropDown
                    v-model.number="contract.auto_renewal"
                    :possible-values="autoRenewal"
                    placeholder="Válassz!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Lemondási határidő</label>
                  <FormDropDown
                    v-model.number="contract.notice_periods_id"
                    :possible-values="getNoticePeriodsList"
                    placeholder="Válassz határidőt!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="p-4">
                  <hr>
                  <h2 style="width: 100%;">3. FELHASZNÁLÁSI- ÉS FIZETÉSI FELTÉTELEK</h2>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Ellátás típusa</label>
                  <FormDropDown
                    v-model.number="contract.supply_types_id"
                    :possible-values="getSupplyTypesList"
                    placeholder="Válassz ellátást!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Adatszolgáltatási mód</label>
                  <FormDropDown
                    v-model.number="contract.reporting_types_id"
                    :possible-values="getReportingTypesList"
                    placeholder="Válassz módot!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Elszámolási mód</label>
                  <FormDropDown
                    v-model.number="contract.account_types_id"
                    :possible-values="getAccountTypesList"
                    placeholder="Válassz módot!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Fizetési mód</label>
                  <FormDropDown
                    v-model.number="contract.payment_methods_id"
                    :possible-values="getPaymentMethodsList"
                    placeholder="Válassz módot!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <material-input id="system_usage_fee" v-model:value="contract.system_usage_fee"
                                  label="Rendszerhasználati díj"
                                  name="system_usage_fee" variant="static"></material-input>
                </div>

                <div class="col-3">
                  <material-input id="energy_fee" v-model:value="contract.energy_fee" label="Energiadíj"
                                  name="energy_fee" variant="static"></material-input>
                </div>

                <div class="col-3">
                  <material-input id="contracted_qty" v-model:value="contract.contracted_qty"
                                  label="Szerződött mennyiség"
                                  name="contracted_qty" variant="static"></material-input>
                </div>

                <div class="col-3">
                  <material-input id="ex_consumption" v-model:value="contract.ex_consumption"
                                  label="Alul- és felülfogyasztás"
                                  name="ex_consumption" variant="static"></material-input>
                </div>

                <div class="col-3">
                  <label class="form-label mb-0 ms-0">Háztartási kiserömű</label>
                  <FormDropDown
                    v-model.number="contract.hh_solar_system_types_id"
                    :possible-values="getHhSolarSystemTypesList"
                    placeholder="Válassz kiseröművet!"
                    :searchable="true"
                    mode="single"
                  />
                </div>

                <div class="col-3">
                  <material-input id="solar_sys_performance" v-model:value="contract.solar_sys_performance"
                                  label="Kiserőmű teljesítmény"
                                  name="solar_sys_performance" variant="static"></material-input>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropDown from "@/components/FormDropdown.vue";
import {ref} from 'vue';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import _ from "lodash";
import VueDatePicker from '@vuepic/vue-datepicker';
import {hu} from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'EditScontract',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
    VueDatePicker,
    FormDropDown,
  },
  mixins: [formMixin],
  setup() {
    const currentStartDate = ref(new Date());
    const currentEndDate = ref(new Date());
    const visible = ref(false);
    const format = (date) => {
      const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    };
    return {
      color: '#33e8f5',
      currentStartDate,
      currentEndDate,
      hu,
      format,
      visible,
    }
  },
  data() {
    return {
      contract: {},
      autoRenewal: [
      { value: 1, label: "Igen" },
      { value: 0, label: "Nem" }
    ]
    }
  },
  computed: {
    getOwnersList() {
      const data = this.$store.getters["contacts/getContactsBaseData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getLeaseDurationsList() {
      const data = this.$store.getters["leasedurations/getLeasedurationsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getNoticePeriodsList() {
      const data = this.$store.getters["noticeperiods/getNoticeperiodsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    UtilitiesList() {
      return this.$store.getters["unetworks/getUnetworksData"] ?? [];
    },
    getUtilitiesList() {
      const data = this.UtilitiesList ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.pod
            }));
    },
    getutilityList() {
      return this.$store.getters["utilities/getUtilitiesData"] ?? [];
    },
    getMetersList() {
      return this.$store.getters["meters/getMetersData"];
    },
    getSupplyTypesList() {
      const data = this.$store.getters["supplytypes/getSupplyTypesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getReportingTypesList() {
      const data = this.$store.getters["reportingtypes/getReportingTypesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getAccountTypesList() {
      const data = this.$store.getters["accounttypes/getAccountTypesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getPaymentMethodsList() {
      const data = this.$store.getters["paymentmethods/getPaymentMethodsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getHhSolarSystemTypesList() {
      const data = this.$store.getters["hhsolarsystemtypes/getHhSolarSystemTypesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("contacts/getContactsBaseData"),
        this.$store.dispatch("realestates/getRealEstates"),
        this.$store.dispatch("leasedurations/getLeasedurations"),
        this.$store.dispatch("noticeperiods/getNoticeperiods"),
        this.$store.dispatch("unetworks/getUnetworks"),
        this.$store.dispatch("utilities/getUtilities"),
        this.$store.dispatch("meters/getMeters"),
        this.$store.dispatch("supplytypes/getSupplyTypes"),
        this.$store.dispatch("reportingtypes/getReportingTypes"),
        this.$store.dispatch("accounttypes/getAccountTypes"),
        this.$store.dispatch("paymentmethods/getPaymentMethods"),
        this.$store.dispatch("hhsolarsystemtypes/getHhSolarSystemTypes"),
      ]);
    } catch (error) {
      console.error("Error loading data:", error);
    }

    try {
      let contract = {};
      await this.$store.dispatch("scontracts/getScontract", this.$route.params.id);
      contract = _.omit(this.$store.getters['scontracts/getScontract'], 'links');
      this.contract = contract[0];
    } catch (error) {
      showSwal.methods.showSwal({
        type: "error",
        message: `Valami hiba történt!`,
        width: 500
      });
    }

    this.currentStartDate = new Date(this.contract.start_date);
    this.currentEndDate = new Date(this.contract?.fixed_term_end_date);
    this.setRealEstate(this.contract.pod);
  },
  methods: {
    async setRealEstate() {
      const selectedUtility = this.UtilitiesList?.find(value => value.id == this.contract.pod) ?? {};

      const currentRealEstate = this.getRealEstateList?.find(value => value.id == selectedUtility.realestate_id) ?? {};
      const currentUtility = this.getutilityList?.find(value => value.id == selectedUtility.utility_services_id) ?? {};
      const currentMeter = this.getMetersList?.find(value => value.pod == this.contract.pod) ?? {};

      this.contract.realestate_name = currentRealEstate?.name || '';
      this.contract.realestate_address = `${currentRealEstate?.zip || ''} ${currentRealEstate?.city || ''} ${currentRealEstate?.address || ''}`.trim();
      this.contract.utility_service = currentUtility?.name || '';
      this.contract.meter_product_number = currentMeter?.product_number || '';
      this.contract.network_provider_name = selectedUtility?.network_provider_name || '';
    },
    async handleEdit() {
      this.resetApiValidation();
      try {
        this.contract.start_date = this.currentStartDate;
        this.contract.fixed_term_end_date = this.currentEndDate;
        await this.$store.dispatch("scontracts/editScontract", this.contract);
        showSwal.methods.showSwal({
          type: "success",
          message: "Szerződés sikeresen módosítva!",
          width: 500
        });
        this.$router.push({name: "Scontracts List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });
      }
    },
  }
}
</script>