<template>
  <div class="container my-4">
    <div class="bg-success text-white pt-4 pb-1 px-4 rounded">
      <!-- Step texts -->
      <div class="d-flex justify-content-between text-center">
        <div v-for="(step, index) in steps" :key="index" class="step-container">
          <div v-for="line in step.text" :key="line">{{ line }}</div>
        </div>
      </div>

      <!-- Dots and Dashed Line -->
      <div class="d-flex align-items-center position-relative mt-3">
        <div v-for="(step, index) in steps" :key="'dot-' + index" class="dot-container">
          <div class="dot"></div>
        </div>

        <!-- Dashed Line Positioned Exactly Between the Dots -->
        <div class="dashed-line"></div>
      </div>

      <!-- Additional Information Below Dots -->
        <div class="row d-flex justify-content-center mt-1">
          <div
            class="row"
          >
            <div
              class="col text-center"
            >
              100%
            </div>
            <div
              class="col text-center"
              :class="meterPodCounts.check === 1 ? ' ' : 'text-danger'"
            >
              {{ meterPodCounts.valid }} / {{ meterPodCounts.total }}
            </div>
            <div
              class="col text-center" 
              :class="meterPodCounts.check === 1 ? ' ' : 'text-danger'"
            >
              {{ productNumberCounts.valid }} / {{ productNumberCounts.total }}
            </div>
            <div 
              class="col text-center"
            >
              {{ invoicePercentage }}%
            </div>
            <div
              class="col text-center" 
              :class="{ 'text-danger': checkedStatusCount !== productNumberCounts.total }"
            >
              {{ checkedStatusCount }} / {{ productNumberCounts.total }}
            </div>
            <div 
              class="col text-center" 
              :class="{ 'text-danger': outGoingInvoiceCheckCount !== productNumberCounts.total }"
            >
            {{ outGoingInvoiceCheckCount }} / {{ productNumberCounts.total }}
            </div>
            <div 
              class="col text-center" 
              :class="{ 'text-danger': outGoingInvoiceCheckCount !== productNumberCounts.total }"
            >
              {{ outGoingInvoiceCheckCount }} / {{ productNumberCounts.total }}
            </div>
          </div>
      </div>
    </div>

    <!-- Billing Information Section -->
    <div class="row px-3 mt-1">
      <div
        v-for="billing in filteredBillingsData.data"
        :key="billing.id"
        class="row ms-0-5 mb-2"
      >
        <div 
          class="col text-center text-success"
        >
          {{ billing.area_name }} 
        </div>
        <div 
          class="col text-center"
          :class="[billing.meter_has_value ? 'text-success cursor-pointer hoverable' : 'text-danger']"
          @click="billing.meter_has_value ? setFiltersAndRedirect(billing) : null"
        >
          {{ billing.meter_pod }}
        </div>

        <div 
          class="col text-center"
          :class="[billing.submeter_has_value ? 'text-success' : 'text-danger']"
        >
          {{ billing.submeter_pod }}
        </div>
        <div 
          class="col text-center text-success text-uppercase"
          :class="billing.has_invoice ? 'text-success cursor-pointer hoverable' : 'text-danger'"
           @click="billing.has_invoice ? redirect(billing) : null"
        >
          {{ billing.has_invoice ? billing.invoice_number : 'Számlaszám' }}
        </div>
        <div 
          class="col text-center text-uppercase" 
          :class="getStatusClass(billing)"
        >
          {{ getStatusText(billing) }}
        </div>
        <div 
          class="col text-center text-uppercase" 
          :class="getSecondStatusClass(billing)"
        >
          {{ getSecondStatusText(billing) }}
        </div>
        <div class="col d-flex justify-content-center align-items-center">
          <svg v-if="billing.meter_check === 1 && billing.submeter_check === 1 && billing.has_invoice" @click="openModal(billing)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <InvoiceModal ref="invoiceForm" @approve-invoice="handleInvoiceApproval"/>
</template>

<script>
import { ref } from 'vue';
import InvoiceModal from './InvoiceModal.vue'

export default {
  name: "OutInvoiceList",
  props: {
    filteredBillingsData: {
      type: Object,
      required: true,
    },
  },
  components: {
    InvoiceModal
  },
  data() {
    return {
      steps: [
        { text: ["\u00A0", "INGATLANADATOK", "ELLENŐRZÉSE"] },
        { text: ["SZÁMLÁZÁSHOZ", "SZÜKSÉGES FŐMÉRŐ", "LEOLVASÁSA"] },
        { text: ["SZÁMLÁZÁSHOZ", "SZÜKSÉGES ALMÉRŐK", "LEOLVASÁSA"] },
        { text: ["BEJÖVŐ", "ENERGIASZÁMLÁK", "FELDOLGOZÁSA"] },
        { text: ["ENERGIAKÖLTSÉG", "MEGOSZTÁSÁNAK", "ELLENŐRZÉSE"] },
        { text: ["KÖZVETETT", "SZOLGÁLTATÁSOK", "TOVÁBBSZÁMLÁZÁSA"] },
        { text: ["\u00A0", "SZÁMLAKÉP", "ELLENŐRZÉSE"] },
      ],
      consumption: {
        selectedDate: "",
        realestate_id: "",
      },
    };
  },
  computed: {
    meterPodCounts() {
      const meterPods = {};

      this.filteredBillingsData.data.forEach((billing) => {
        if (!billing.meter_pod) return;

        if (!meterPods[billing.meter_pod]) {
          meterPods[billing.meter_pod] = { valid: false };
        }

        if (billing.meter_has_value) {
          meterPods[billing.meter_pod].valid = true;
        }
      });

      const total = Object.keys(meterPods).length;
      const valid = Object.values(meterPods).filter(pod => pod.valid).length;

      const check = total === valid ? 1 : 0;

      return { total, valid, check };
    },
    productNumberCounts() {
      let total = 0;
      let valid = 0;

      this.filteredBillingsData.data.forEach((billing) => {
        if (billing.submeter_pod) {
          total++;
          if (billing.submeter_has_value) {
            valid++;
          }
        }
      });

      const check = total === valid ? 1 : 0;

      return { total, valid, check };
    },
    outGoingInvoiceCheckCount() {
      let total = 0;
      
      this.filteredBillingsData.data.forEach((billing) => {
        if (billing.check === 1) {
          total++;
        }
      });

      return total;
    },
    hasInvoiceCount() {
      return this.filteredBillingsData.data.filter(billing => billing.has_invoice).length;
    },
    checkedStatusCount() {
      return this.filteredBillingsData.data.filter(
        billing => billing.meter_check === 1 && billing.submeter_check === 1
      ).length;
    },
    invoicedStatusCount() {
      return this.filteredBillingsData.data.filter(billing => billing.invoiced).length;
    },
    invoicePercentage() {
      if (this.productNumberCounts.total === 0) return 0;
      return ((this.hasInvoiceCount / this.productNumberCounts.total) * 100).toFixed(0);
    },
  },
  methods: {
    handleInvoiceApproval(updatedInvoice) {},
    getStatusText(billing) {
      if (billing.meter_check === 1 && billing.submeter_check === 1) {
        return "Ellenőrzött";
      } else if (billing.meter_has_value && billing.submeter_has_value) {
        return "Jóváhagyásra vár";
      } else if(!billing.meter_has_value && !billing.submeter_has_value && !billing.has_invoice) {
        return "Figyelmeztetés";
      } else {
        return "Folyamatban"; 
      }
    },
    getStatusClass(billing) {
      if (billing.meter_check === 1 && billing.submeter_check === 1) {
        return "text-success"; 
      } else if (billing.meter_has_value && billing.submeter_has_value) {
        return "text-warning"; 
      } else if(billing.meter_has_value && billing.submeter_has_value && billing.has_invoice) {
        return "text-danger";
      } else {
        return "text-danger"; 
      }
    },
    getSecondStatusText(billing) {
      if (billing.check === 1) {
        return "Számlázott"; 
      } else if (billing.meter_check === 1 && billing.submeter_check === 1 && billing.has_invoice) {
        return "Számlakép"; 
      } else if(!billing.meter_has_value && !billing.submeter_has_value && !billing.has_invoice) {
        return "Figyelmeztetés";
      } else {
        return "Folyamatban"; 
      }
    },
    getSecondStatusClass(billing) {
      if (billing.check === 1) {
        return "text-success";
      } else if (billing.meter_check === 1 && billing.submeter_check === 1 && billing.has_invoice) {
        return "text-warning";
      } else if(!billing.meter_has_value && !billing.submeter_has_value && !billing.has_invoice) {
        return "text-danger";
      } else {
        return "text-danger"; 
      }
    },
    approveInvoiceStatus(billing) {
      if (billing.meter_check === 1 && billing.submeter_check === 1) {
        billing.approved = true;
      }
    },
    async setFiltersAndRedirect(billing) {
      try {
        const calendarDate = ref({ month: billing.r_month - 1, year: billing.r_year });
        this.consumption.selectedDate = calendarDate.value;
        this.consumption.realestate_id = billing.realestate_id;
        await this.$store.dispatch("consumption/getConsumption", this.consumption);
        this.$router.push({ name: 'ConsumptionsRegistration' });
      } catch (error) {
        console.error("Error in setFiltersAndRedirect:", error);
      }
    },
    redirect(billing) {
      this.$router.push(`/pages/incoming-invoices/edit-invoice/${billing.incoming_invoice_id}`);
    },
    openModal(billing) {
      this.$refs.invoiceForm.openModal(billing);
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 100%;
}

.step-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dot-container {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
}

.dot {
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: green;
  position: relative;
  z-index: 2;
}

.dashed-line {
  position: absolute;
  top: 50%;
  left: calc(7px + 200px / 2);
  right: calc(7px + 200px / 2);
  height: 2px;
  background: repeating-linear-gradient(to right, white, white 5px, transparent 5px, transparent 10px);
  transform: translateY(-50%);
  z-index: 1;
}

.row {
  margin-top: 20px;
}

.col {
  flex: 1;
}

.row + .row {
  margin-top: 10px;
}

.ms-0-5 {
    margin-left: 1px;
}

.hoverable {
  transition: color 0.3s ease;
}

.hoverable:hover {
  color: #146614 !important;
}

.hoverable:active {
  color: #064d06 !important;
}

@media (max-width: 1692px) {
  .dashed-line {
    left: calc(7px + 160px / 2);
    right: calc(7px + 160px / 2);
  }
}

@media (max-width: 1435px) {
  .dashed-line {
    left: calc(7px + 140px / 2);
    right: calc(7px + 140px / 2);
  }
}

@media (max-width: 1280px) {
  .dashed-line {
    left: calc(7px + 120px / 2);
    right: calc(7px + 120px / 2);
  }
}
</style>
