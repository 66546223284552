<template>
  <div class="py-4 container-fluid min-vh-80">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-12">
                <h5 class="mb-0">Fogyasztáskalkuláció - {{ selectedDate }}</h5>
              </div>

              <div class="col-3 mb-4">
                <label class="form-label mb-0 ms-0">Év/Hónap</label>
                <VueDatePicker v-model="calendarDate" :teleport="true" locale="hu" month-picker/>
              </div>

              <div class="col-3">
                <label class="form-label mb-0 ms-0">Ingatlan megnevezése</label>
                <select id="choices-realestate" v-model.number="consumption.realestate_id"
                        name="choices-realestate" class="form-control"
                        @change="setRealEstate($event.target.value)">
                  <option value="">Válassz!</option>
                  <option v-for="realestate in getRealEstateList" :key="realestate.id"
                          :value="realestate.id">{{ realestate.name }}
                  </option>
                </select>
                <validation-error :errors="apiValidationErrors.pod"></validation-error>
              </div>

              <div class="col-3 mb-4">
                <label class="form-label mb-0 ms-0">Közmű</label>
                <select id="choices-utility_services_id" v-model.number="consumption.utility_services_id"
                        name="choices-utility_services_id" class="form-control"
                        @change="setUtility($event.target.value)">
                  <option value="">Válassz!</option>
                  <option v-for="utility in getutilityList" :key="utility.id"
                          :value="utility.id">{{ utility.name }}
                  </option>
                </select>
              </div>

              <div class="col-3 text-end">
                <Button type="button" label="Kiválasztás" icon="pi pi-check" :loading="loading"
                        @click.prevent="startRegistration"/>
              </div>
            </div>


          </div>
          <!--Card body-->
          <div class="card-body">
            <div class="w-100">
              <hr>
              <h2>{{ calendarDate.year + ' ' + (calendarDate.month + 1) }}</h2>
            </div>
            <!-- Data -->
            <div v-if="getConsumptionCalculationList?.calculations !== null"
                 class="table-responsive mt-2 px-4">
              <div class="row bg-light fw-bold border-bottom py-2">
                <div class="col text-center">Helyiség</div>
                <div class="col text-center">Mérőóra</div>
                <div class="col text-center">Számítás</div>
                <div class="col text-center">Státusz</div>
                <div class="col text-center">
                  {{ getConsumptionCalculationList?.start_date ?? "Kezdő dátum" }}
                </div>
                <div class="col text-center">
                  {{ getConsumptionCalculationList?.end_date ?? "Befejező dátum" }}
                </div>
                <div class="col text-center">Eltérés</div>
                <div class="col text-center">Korrekció</div>
                <div class="col text-center">Szorzó</div>
                <div class="col text-center">Fogyasztás</div>
              </div>
              <div v-for="calculation in getConsumptionCalculationList?.calculations ?? []"
                   :key="calculation.id" class="row align-items-center border-bottom py-1">
                <div class="col text-center">{{ calculation.area }}</div>
                <div class="col text-center">{{ calculation.name }}</div>
                <div class="col text-center">{{ calculation.type }}</div>
                <div class="col d-flex justify-content-center align-items-center">
                  <CalculationStatus :status="calculation.status"/>
                </div>
                <div class="col text-center">{{ calculation.previous_value }}</div>
                <div class="col text-center">{{ calculation.current_value }}</div>
                <div class="col text-center">{{ calculation.difference }}</div>
                <div class="col text-center">{{ calculation.correction }}</div>
                <div class="col text-center">{{ calculation.multiplier }}</div>
                <div class="col text-center">{{ calculation.consumption }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Choices from 'choices.js';
import {ref} from 'vue';
import {CButton, CCard, CCardBody, CCollapse} from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faArrowsTurnRight} from "@fortawesome/free-solid-svg-icons";
import CalculationStatus from "./components/CalculationStatus.vue";

export default {
  name: 'Consumption',
  components: {
    CalculationStatus,
    MaterialButton,
    MaterialInput,
    VueDatePicker,
    CButton,
    CCollapse,
    CCard,
    CCardBody,
    FontAwesomeIcon,
  },
  mixins: [formMixin],
  setup() {
    const visible = ref(false);
    const calendarDate = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear()
    });
    return {
      color: '#33e8f5',
      calendarDate,
      visible,
    }
  },
  data() {
    return {
      consumption: {},
      dataConsumption: [],
      date: null,
      dataTable: null,
      consumptionList: null,
      loading: true,
      faArrowsTurnRight,
    }
  },
  computed: {
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getutilityList() {
      return this.$store.getters["utilities/getUtilitiesData"];
    },
    getConsumptionCalculationList() {
      return this.$store.getters["consumption/getConsumptionCalculationData"];
    },
    selectedDate() {
      const date = new Date();
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
  },
  async mounted() {

    try {
      await Promise.all([
        this.$store.dispatch("realestates/getRealEstates"),
        this.$store.dispatch("utilities/getUtilities"),
      ]);

      this.loading = false;
    } catch (error) {
      console.error("Error loading data:", error);
    }

    if (document.getElementById("choices-realestate")) {
      const realestate = document.getElementById("choices-realestate");
      this.realestate = new Choices(realestate);
    }

    if (document.getElementById("choices-utility_services_id")) {
      var mutility = document.getElementById("choices-utility_services_id");
      new Choices(mutility);
    }
  },

  methods: {
    updateMeterValue(event, meter) {
      if (meter.get_consumption_reg.length > 0) {
        meter.get_consumption_reg[0].current_value = event.target.value;
      } else {
        meter.get_consumption_reg.push({current_value: event.target.value});
      }
    },
    getMeterValue(meter) {
      if (meter.get_consumption_reg.length > 0) {
        return meter.get_consumption_reg[0].current_value;
      }
      return null;
    },
    updateSubMeterValue(event, meter, index) {
      if (meter.get_sub_meters[index].get_consumption_reg.length > 0) {
        meter.get_sub_meters[index].get_consumption_reg[0].current_value = event.target.value;
      } else {
        meter.get_sub_meters[index].get_consumption_reg.push({current_value: event.target.value});
      }
    },
    getSubMeterValue(meter, index) {
      if (meter.get_sub_meters[index].get_consumption_reg.length > 0) {
        return meter.get_sub_meters[index].get_consumption_reg[0].current_value;
      }
      return null;
    },
    colorStyle(item) {
      if (item == 1) {
        return "max-width: 400px; background-color: red;";
      } else if (item == 2) {
        return "max-width: 400px; background-color: #c5dfb3;";
      } else if (item == 3) {
        return "max-width: 400px; background-color: #9ac1e5;";
      } else if (item == 4) {
        return "max-width: 400px; background-color: #febf00;";
      }
      return "max-width: 400px; background-color: #afa99d;";
    },
    getStatus(network) {
      if (network.get_meters.length > 0) {
        let meterCount = 0;
        let submetersCount = 0;
        let status = 0;

        meterCount = network.get_meters.length;
        for (let i = 0; i < network.get_meters.length; i++) {
          if (network.get_meters[i].get_consumption_reg[0] && network.get_meters[i].get_consumption_reg[0].current_value != '') {
            status += 1
          }
          if (network.get_meters[i].get_sub_meters.length > 0) {
            submetersCount += network.get_meters[i].get_sub_meters.length;
            for (let ii = 0; ii < network.get_meters[i].get_sub_meters.length; ii++) {
              if (network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0] && network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0].current_value != '') {
                status += 1
              }
            }
          }
        }
        const total = meterCount + submetersCount;

        return status + '/' + total;
      }
    },
    getResult(network) {
      if (network.get_meters.length > 0) {
        let meterCount = 0;
        let submetersCount = 0;
        let status = 0;

        meterCount = network.get_meters.length;
        for (let i = 0; i < network.get_meters.length; i++) {
          if (network.get_meters[i].get_consumption_reg[0] && network.get_meters[i].get_consumption_reg[0].current_value != '') {
            status += 1
          }
          if (network.get_meters[i].get_sub_meters.length > 0) {
            submetersCount += network.get_meters[i].get_sub_meters.length;
            for (let ii = 0; ii < network.get_meters[i].get_sub_meters.length; ii++) {
              if (network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0] && network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0].current_value != '') {
                status += 1
              }
            }
          }
        }
        const total = meterCount + submetersCount;

        if (status == total) {
          return "Feltöltve";
        } else {
          return "Várakozik";
        }
      }
    },
    async startRegistration() {
      this.resetApiValidation();
      try {
        this.loading = true;
        this.consumption.selectedDate = this.calendarDate;
        await this.$store.dispatch("consumption/getConsumptionCalculation", this.consumption);
        showSwal.methods.showSwal({
          type: "success",
          message: "Számítás befejezve!",
          width: 500
        });
        this.loading = false;
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });
        this.loading = false;
      }
    },
    async setRealEstate(id) {
      this.currentRealEstate = this.getRealEstateList.filter(value => {
        return value.id == id
      });
    },
    async setUtility(id) {
      this.utility = this.getutilityList.filter(value => {
        return value.id == id
      });
    },
    async addValues(network) {
      network.registration_date = this.selectedDate;
      network.selectedDate = this.calendarDate;
      await this.$store.dispatch("consumption/addConsumption", network);
    },
    isDisable(network) {
      for (let i = 0; i < network.get_meters.length; i++) {
        if (network.get_meters[i].get_consumption_reg.length > 0) {
          if (!network.get_meters[i].get_consumption_reg[0].current_value) {
            return true;
          }
        } else {
          return true;
        }
        for (let ii = 0; ii < network.get_meters[i].get_sub_meters.length; ii++) {
          if (network.get_meters[i].get_sub_meters[ii].get_consumption_reg.length > 0) {
            if (!network.get_meters[i].get_sub_meters[ii].get_consumption_reg[0].current_value) {
              return true;
            }
          } else {
            return true;
          }
        }
      }
      return false;
    }
  }
}
</script>