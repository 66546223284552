<template>
  <Toast/>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Főmérő Módosítása</h5>
              </div>
              <div class="col-6 text-end">
                <div class="d-flex justify-content-end gap-1">
                  <Button type="button" label="Mentés" icon="pi pi-save" :loading="loading" @click.prevent="handleEdit"/>
                  <Button
                      severity="warn"
                      type="button" label="Vissza" icon="pi pi-arrow-left"
                      @click="this.$router.push({ name: 'Meters List' })"
                    />
                </div>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="row">
                <div class="col-5">
                  <label class="form-label mb-0 ms-0">POD</label>
                  <FormDropdown
                      v-model="meter.pod"
                      :possible-values="UtilitiesBaseList"
                      :searchable="true"
                      mode="single"
                  />
                  <validation-error :errors="apiValidationErrors.pod"></validation-error>
                </div>

                <div class="col-5">
                  <material-input id="realestate_name" :value="meter.unetwork.get_real_estate?.name || ''"
                                  label="Hely neve"
                                  name="realestate_name" variant="static" disabled></material-input>
                </div>

                <div class="col-5">
                  <material-input id="realestate_address" :value="meter.unetwork.get_real_estate?.address || ''"
                                  label="Hely címe"
                                  name="realestate_address" variant="static" disabled></material-input>
                </div>
              </div>

              <br/>

              <div class="row p-4 bg-light">
                <div class="col-5">
                  <material-input id="product_number" v-model:value="meter.product_number" label="Gyáriszám"
                                  name="product_number" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.product_number"></validation-error>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Mérési egység</label>
                  <FormDropdown
                      v-model="meter.measurement_units_id"
                      :possible-values="getMeasurementUnitsBaseList"
                      :searchable="true"
                      mode="single"
                  />
                </div>

                <div class="col-5">
                  <material-input id="commissioning_date" v-model:value="meter.commissioning_date"
                                  label="Beüzemelés időpontja"
                                  name="commissioning_date" variant="static"></material-input>
                  <validation-error :errors="apiValidationErrors.commissioning_date"></validation-error>
                </div>

                <div class="col-5">
                  <label class="form-label mb-0 ms-0">Pozíció</label>
                  <FormDropdown
                      v-model="meter.position"
                      :possible-values="getAreaPositionsList"
                      :searchable="true"
                      mode="single"
                  />
                </div>

                <div class="col-5">
                  <label class="form-label">Helységek hozzáadása</label>
                  <FormDropdown
                      v-model="areadIds"
                      :possible-values="areas"
                      :close-on-select="false"
                      :searchable="true"
                      mode="tags"
                      @update:modelValue="handleSelectionChange"
                  />
                  <validation-error :errors="apiValidationErrors.area_ids"></validation-error>
                </div>
              </div>

              <div class="relative">
                <div class="w-100">
                  <hr>
                  <h2>Almérő hozzáadása</h2>
                </div>

                <!--<div class="button_plus" id="showButton" color="dark" variant="ghost" @click="visible = !visible" />-->
                <CCollapse :visible="visible" style="position: relative; top: 20px;">
                  <CCard class="mt-3">
                    <CCardBody>
                      <div>
                        <div class="table-responsive">
                          <Toolbar class="mb-6">
                            <template #start>
                              <div class="d-flex gap-2">
                                <Button label="Almérő Hozzáadás" icon="pi pi-plus" class="mr-2" @click="openNew"/>
                                <Button label="Törlés" icon="pi pi-trash" severity="danger" outlined @click=""
                                      :disabled="!selectedsubmeters || !selectedsubmeters.length"/>
                              </div>
                            </template>

                            <template #end>
                              <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import"
                                          customUpload chooseLabel="Import" class="mr-2" auto
                                          :chooseButtonProps="{ severity: 'secondary' }"/>
                              <Button label="Export" icon="pi pi-upload" severity="secondary"
                                      @click="exportCSV($event)"/>
                            </template>
                          </Toolbar>
                          <!-- Table-->
                          <DataTable
                              ref="dt"
                              v-model:expandedRows="selectedsubmeters"
                              :value="submetersdata"
                              dataKey="id"
                              :paginator="true"
                              :rows="10"
                              :filters="filters"
                              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                              :rowsPerPageOptions="[5, 10, 25, 50]"
                              currentPageReportTemplate="{first} - {last} a {totalRecords} helységből"
                              resizableColumns
                              columnResizeMode="fit"
                              @rowExpand="onRowExpand"
                              @rowCollapse="onRowCollapse"
                          >

                            <template #header>
                              <div class="flex flex-wrap gap-2 items-center justify-between">
                                <div class="row mt-1 mb-1 d-flex align-items-right">
                                  <IconField style="padding-left: 0px;">
                                    <InputIcon>
                                      <i class="pi pi-search"/>
                                    </InputIcon>
                                    <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                                  </IconField>
                                </div>
                                <div class="row mt-1 d-flex align-items-left">
                                  <MultiSelect :modelValue="selectedColumns" :options="columns" optionLabel="header"
                                               @update:modelValue="onToggle"
                                               display="chip" placeholder="Select Columns"/>
                                </div>
                              </div>
                            </template>

                            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                            <Column expander style="width: 5rem"/>
                            <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
                                    :key="col.field + '_' + index" sortable>
                              <template v-if="col.field == 'submeter_types_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.submeter_type[0].name }}
                                </div>
                              </template>
                              <template v-if="col.field == 'measurement_units_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.measurement_unit[0].name }}
                                </div>
                              </template>
                            </Column>
                            <Column :exportable="false" style="min-width: 12rem">
                              <template #body="slotProps">
                                <Button icon="pi pi-pencil" outlined rounded class="mr-2"
                                        @click="editarea(slotProps.data)"/>
                                <Button icon="pi pi-trash" outlined rounded severity="danger"
                                        @click="confirmDeletearea(slotProps.data)"/>
                              </template>
                            </Column>
                            <template #expansion="slotProps">
                              <div class="p-4">
                                <p>Hozzátartozó helyiségek</p>
                                <DataTable :value="slotProps.data.areas">
                                  <Column field="name" header="Name" sortable></Column>
                                </DataTable>
                              </div>
                            </template>
                          </DataTable>
                        </div>

                        <Dialog v-model:visible="areaDialog" :style="{ width: '450px' }" header="Almérő adatok"
                                :modal="true">
                          <div class="flex flex-col" style="display: flex;flex-direction: column; gap: 1rem;">
                            <div>
                              <label for="product_number" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Gyári szám</label>
                              <InputText id="product_number" v-model.trim="area.product_number" required="true"
                                         autofocus :invalid="submitted && !area.product_number" fluid/>
                              <small v-if="submitted && !area.product_number" class="text-red-500">Name is
                                required.</small>
                            </div>
                            <div>
                              <label for="submeter_types_id" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Típus</label>
                              <Select id="submeter_types_id" v-model="computedSubmeterTypesId"
                                      :options="getSubmeterTypesList" optionLabel="name" optionValue="id"
                                      placeholder="Select a Status" fluid></Select>
                            </div>
                            <div>
                              <label for="description" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Beüzemelés időpontja</label>
                              <InputText id="lot_number" v-model.trim="area.commissioning_date" required="true"
                                         autofocus :invalid="submitted && !area.commissioning_date" fluid/>
                              <small v-if="submitted && !area.commissioning_date" class="text-red-500">Name is
                                required.</small>
                            </div>
                            <div>
                              <label for="measurement_units_id" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Mérési egység</label>
                              <Select id="measurement_units_id" v-model="computedMeasurementUnitsId"
                                      :options="getMeasurementUnitsList" optionLabel="name" optionValue="id"
                                      placeholder="Select a Status" fluid></Select>
                            </div>
                            <div>
                              <label for="position" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Pozíció</label>
                              <Select id="position" v-model="computedPositionId" :options="getAreaPositionsListForSelector" optionLabel="name"
                                      optionValue="id" placeholder="Select a Status" fluid></Select>
                            </div>
                            <div>
                              <label for="areas" class="block font-bold mb-3" style="display: block; font-weight: 700;">Helyiségek</label>
                              <MultiSelect id="areas" v-model="computedSubmeterAreasId" display="chip" :options="areaList"
                                           optionLabel="identifier" filter placeholder="Válassz helyiséget!"
                                           :maxSelectedLabels="3" class="form-control md:w-80"/>
                              <small v-if="submitted && !area.position" class="text-red-500">Name is required.</small>
                            </div>
                          </div>

                          <template #footer>
                            <Button label="Mégse" icon="pi pi-times" text @click="hideDialog"/>
                            <Button label="Mentés" icon="pi pi-check" :loading="loading" @click="savearea"/>
                          </template>
                        </Dialog>

                        <Dialog v-model:visible="deleteareaDialog" :style="{ width: '450px' }" header="Confirm"
                                :modal="true">
                          <div class="flex items-center gap-4">
                            <i class="pi pi-exclamation-triangle !text-3xl"/>
                            <span v-if="area"
                            >Biztos vagy benne, hogy törlöd a <b>{{ area.product_number }}</b
                            > almérőt?</span
                            >
                          </div>
                          <template #footer>
                            <Button label="No" icon="pi pi-times" text @click="deleteareaDialog = false"/>
                            <Button label="Yes" icon="pi pi-check" @click="deletearea"/>
                          </template>
                        </Dialog>

                        <Dialog v-model:visible="deleteareasDialog" :style="{ width: '450px' }" header="Confirm"
                                :modal="true">
                          <div class="flex items-center gap-4">
                            <i class="pi pi-exclamation-triangle !text-3xl"/>
                            <span v-if="area">Biztos vagy benne, hogy törlöd a kijelölt almérőket?</span>
                          </div>
                          <template #footer>
                            <Button label="No" icon="pi pi-times" text @click="deleteareasDialog = false"/>
                            <Button label="Yes" icon="pi pi-check" text @click="deleteSelectedareas"/>
                          </template>
                        </Dialog>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCollapse>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {CButton, CCard, CCardBody, CCollapse} from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import VueDatePicker from '@vuepic/vue-datepicker';
import {FilterMatchMode} from '@primevue/core/api';
import {hu} from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css';
import _ from "lodash";
import FormDropdown from "../../../components/FormDropdown.vue";

export default {
  name: 'editMeter',
  components: {
    FormDropdown,
    MaterialButton,
    MaterialInput,
    VueDatePicker,
    ValidationError,
    CButton,
    CCollapse,
    CCard,
    CCardBody
  },
  mixins: [formMixin],
  setup() {
    const visible = ref(true);
    const areas = ref([]);
    const format = (date) => {
      const day = date.getDate() < 9 ? "0" + date.getDate() : date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    };
    return {
      areas,
      visible,
      format,
      hu,
      color: '#33e8f5'
    }
  },
  data() {
    return {
      meter: {
        unetwork: {
          get_real_estate: {
            name: '',
            address: '',
          },
        },
        areas: {},
      },
      areaIds: {},
      selectedAreas: {},
      submeter: [],
      date: null,
      newArea: {},
      selected: null,
      areaDialog: false,
      deleteareaDialog: false,
      deleteareasDialog: false,
      area: {},
      selectedsubmeters: null,
      filters: {},
      submitted: false,
      loading: false,
      selectedColumns: null,
      columns: null,
      submetersdata: [],
      areas2: [],
      areaList: [],
      selectedAreaIds: [],
    }
  },
  watch: {
    'meter.pod': {
      async handler(newValue) {
        if (!newValue) return;

        const meterPod = this.meter.pod ?? null;
        if (!meterPod) return;

        const selectedUtility = this.UtilitiesList.find(value => value.id === meterPod);
        if (!selectedUtility) return;

        const realestate = this.getRealEstateList.find(value => value.id === selectedUtility.realestate_id);
        if (realestate) {
          this.$nextTick(() => {
            const realestateName = document.getElementById('realestate_name');
            const realestateAddress = document.getElementById('realestate_address');

            if (realestateName) realestateName.value = realestate.name;
            if (realestateAddress) realestateAddress.value = `${realestate.zip} ${realestate.city} ${realestate.address}`;
          });

          await this.$store.dispatch("realestates/getRealEstateAreas", realestate.id);
          this.areas = this.$store.getters["realestates/getRealEstateAreas"];
        }
      },
      deep: true,
      immediate: false,
    }
  },
  created() {
    this.initFilters();
    this.columns = [
      {field: 'product_number', header: 'Gyári szám'},
      {field: 'submeter_types_id', header: 'Típus'},
      {field: 'commissioning_date', header: 'Beüzemelés időpontja'},
      {field: 'measurement_units_id', header: 'Mérési egység'},
      {field: 'position', header: 'Pozíció'},
    ];
    this.selectedColumns = this.columns;
  },
  computed: {
    getRealEstateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getMeasurementUnitsList() {
      return this.$store.getters["munits/getMunitsData"];
    },
    getMeasurementUnitsBaseList() {
      const data = this.getMeasurementUnitsList ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
    UtilitiesList() {
      return this.$store.getters["unetworks/getUnetworksData"];
    },
    UtilitiesBaseList() {
      const data = this.UtilitiesList ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.pod,
        }
      })
    },
    AreaList() {
      return this.$store.getters["areas/getAreasData"];
    },
    getAreaList() {
      const data = this.$store.getters["areas/getAreasData"] ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.identifier,
        }
      })
    },
    getutilityList() {
      return this.$store.getters["utilities/getUtilitiesData"];
    },
    getSubmeterTypesList() {
      return this.$store.getters["stypes/getStypesData"];
    },
    getAreaPositionsListForSelector() {
      return this.$store.getters["positions/getPositionsData"];
    },
    getAreaPositionsList() {
      const data = this.$store.getters["positions/getPositionsData"] ?? [];
      return data.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
    },
    computedSubmeterTypesId: {
      get() {
        if (this.area.submeter_types_id) {
          return this.area.submeter_types_id.toString()
        }
      },
      set(val) {
        this.area.submeter_types_id = Number(val)
      }
    },
    computedMeasurementUnitsId: {
      get() {
        if (this.area.measurement_units_id) {
          return this.area.measurement_units_id.toString()
        }
      },
      set(val) {
        this.area.measurement_units_id = Number(val)
      }
    },
    computedSubmeterAreasId: {
      get() {
        if (this.area.areas) {
          if (Array.isArray(this.area.areas)) {
            return this.area.areas.map(area => area.area_id || area.value);
          }
          return [this.area.areas];
        }
        return []; 
      },
      set(val) {
        if (!Array.isArray(val)) val = [val];
        this.area.areas = val.map(id => {
          const selectedArea = this.areaList.find(a => a.id == id || a.value == id);
          return {
            value: id,
            area_id: id,
            name: selectedArea ? selectedArea.identifier : ''
          };
        });
      }
    },
    computedPositionId: {
      get() {
        if (this.area.position) {
          return this.area.position.toString()
        }
      },
      set(val) {
        this.area.position = Number(val)
      }
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("meters/getMeter", this.$route.params.id),
        this.$store.dispatch("realestates/getRealEstates"),
        this.$store.dispatch("unetworks/getUnetworks"),
        this.$store.dispatch("areas/getAreas"),
        this.$store.dispatch("positions/getPositions"),
        this.$store.dispatch("munits/getMunits"),
        this.$store.dispatch("utilities/getUtilities"),
        this.$store.dispatch("stypes/getStypes"),
      ]);

      const meter = await this.$store.getters['meters/getMeter'];

      this.areadIds = meter[0].areas.map((item) => item.area_id);
      this.areaList = this.AreaList.filter(area => this.areadIds.includes(Number(area.id)));
      this.prepareAreaList();

      this.loading = false;
    } catch (error) {
      console.error("Error loading data:", error);
    }

    try {
      this.meter = _.omit(this.$store.getters['meters/getMeter'], 'links');
      this.meter = this.meter[0];
      this.submeter = this.meter.get_sub_meters;
      this.meter.id = this.$route.params.id;
      if (this.meter.get_sub_meters.length > 0) {
        this.meter.get_sub_meters.forEach((submeter) => {
          let list = [];
          if (submeter.areas && submeter.areas.length > 0) {
            submeter.areas.forEach((value) => {
              const foundArea = this.AreaList?.find(val => {
                return String(val.id) === String(value.area_id);
              });
              
              if (foundArea && foundArea.identifier) {
                list.push({
                  value: value.area_id,
                  name: foundArea.identifier.toString(),
                });
              } else {
                console.log('Nem található terület ezzel az ID-val:', value.area_id);
              }
            });
          }
    
          this.submetersdata.push({
            id: submeter.id,
            product_number: submeter.product_number,
            submeter_types_id: submeter.submeter_types_id,
            commissioning_date: submeter.commissioning_date,
            measurement_units_id: submeter.measurement_units_id,
            position: submeter.position,
            submeter_type: this.getSubmeterTypesList.filter(val => val.id == submeter.submeter_types_id),
            measurement_unit: this.getMeasurementUnitsList.filter(val => val.id == submeter.measurement_units_id),
            areas: [...list],
          });
        });
      }
    } catch (error) {
      showSwal.methods.showSwal({
        type: "error",
        message: error,
        width: 500
      });
    }

    this.AreaList.forEach(item => {
      let value = item.id;
      let label = item.identifier;
      this.meter.areas.forEach(elem => {
        if (elem.area_id == item.id && elem.submeter_id == null) {
          this.areas2.push({value: value, name: label})
        }
      });
      this.areas.push({value: value, name: label});
    });
  },
  methods: {
    prepareAreaList() {
      this.areaList = this.AreaList.filter(area => this.areadIds.includes(Number(area.id)))
        .map(area => ({
          id: area.id,
          value: area.id,
          identifier: area.identifier 
        }));
    },
    handleSelectionChange(newIds) {
      newIds = newIds.map(id => String(id));
      this.areaIds = newIds;
      this.meter.areas = newIds.map(id => {
        const area = this.areas.find(area => area.value === id);
        return {
          value: id, 
          label: area ? area.name : null 
        };
      });
    },
    async handleEdit() {
      this.resetApiValidation();
      try {
        //this.meter.areas = this.areas2;
        await this.$store.dispatch("meters/editMeter", this.meter);
        showSwal.methods.showSwal({
          type: "success",
          message: "Főmérő sikeresen módosítva!",
          width: 500
        });
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });

      }
    },
    openNew() {
      this.area = {};
      this.submitted = false;
      this.areaDialog = true;
    },
    hideDialog() {
      this.areaDialog = false;
      this.submitted = false;
    },
    async savearea() {
      this.loading = true;
      this.submitted = true;

      if (this.area?.product_number?.trim()) {
        if (!this.area.areas || !Array.isArray(this.area.areas)) {
          const selectedAreaIds = this.computedSubmeterAreasId;
          if (Array.isArray(selectedAreaIds) && selectedAreaIds.length > 0) {
            this.area.areas = selectedAreaIds.map(id => {
              const selectedArea = this.areaList.find(a => a.id == id || a.value == id);
              const areaId = id instanceof Object ? 
                (id.id || id.value || id) : 
                id;

              return { value: areaId, area_id: areaId, name: selectedArea ? selectedArea.identifier : '' };
            });
          } else {
            this.area.areas = [];
          }
        } else {
          this.area.areas = this.area.areas.map(area => {
            const areaId = area.area_id instanceof Object ? 
              (area.area_id.id || area.area_id.value || area.area_id) : 
              area.area_id;

            return { value: areaId, area_id: areaId, name: area.name || '' };
          });
        }
        
        if (this.area.id) {
          this.area.id = this.area.id.toString();
          this.area.meter_pod = this.meter.id;
          try {
            await this.$store.dispatch("submeters/editSubMeter", this.area);

            this.area.submeter_type = this.getSubmeterTypesList.filter(val => val.id == this.area.submeter_types_id);
            this.area.measurement_unit = this.getMeasurementUnitsList.filter(val => val.id == this.area.measurement_units_id);

            this.submetersdata[this.findIndexById(this.area.id)] = this.area;
            this.$toast.add({severity: 'success', summary: 'Sikeres', detail: 'Almérő módosítva!', life: 3000});
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'HIBA', detail: error, life: 10000});
            this.areaDialog = false;
            this.area = {};
            this.loading = false;
          }
        } else {
          this.area.meter_pod = this.meter.id;
          console.log('area:', this.area);
          try {
            this.area.id = await this.$store.dispatch("submeters/addSubMeter", this.area);

            this.area.submeter_type = this.getSubmeterTypesList.filter(val => val.id == this.area.submeter_types_id);
            this.area.measurement_unit = this.getMeasurementUnitsList.filter(val => val.id == this.area.measurement_units_id);

            this.submetersdata.push(this.area);
            this.$toast.add({severity: 'success', summary: 'Sikeres', detail: 'Almérő hozzáadva!', life: 3000});
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'HIBA', detail: error, life: 10000});
            this.areaDialog = false;
            this.area = {};
            this.loading = false;
          }
        }

        this.areaDialog = false;
        this.area = {};
        this.loading = false;
      }
    },
    editarea(area) {
      this.area = {...area};
      this.areaDialog = true;
    },
    confirmDeletearea(area) {
      this.area = area;
      this.deleteareaDialog = true;
    },
    async deletearea() {
      this.submetersdata = this.submetersdata.filter(val => val.id !== this.area.id);
      this.deleteareaDialog = false;
      await this.$store.dispatch("submeters/deleteSubMeter", this.area.id);
      this.area = {};
      this.$toast.add({severity: 'success', summary: 'Sikeres', detail: 'Almérő törölve!', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.submetersdata.length; i++) {
        if (this.submetersdata[i].id == id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteareasDialog = true;
    },
    deleteSelectedareas() {
      this.submetersdata = this.areas.filter(val => !this.selectedareas.includes(val));
      this.deleteareasDialog = false;
      this.selectedareas = null;
      this.$toast.add({severity: 'success', summary: 'Sikeres', detail: 'Almérők törölve!', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter(col => value.includes(col));
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>