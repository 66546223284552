<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Ingatlan Hozzáadása</h5>
              </div>
              <div class="col-6 text-end d-flex gap-4 justify-content-end">
                <material-button 
                  class="float-right btn btm-sm" 
                  @click.prevent="handleAdd"
                >
                  Mentés
                </material-button>
                <material-button 
                  class="float-right btn btm-sm"
                  color="secondary"
                  @click="this.$router.push({ name: 'RealEstates List' })"
                >
                  Vissza
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="container my-6">
                <div class="row justify-content-center">
                  <div class="col-12 d-flex flex-wrap justify-content-center align-items-center gap-4">
                    <div v-for="(image, index) in previewImages" :key="index" class="m-2 text-center" style="cursor: pointer;">
                      <div class="" @click="removeImage(index)">✖</div>
                      <img :src="image" alt="Előnézet" class="img-thumbnail" style="width: 150px; height: 150px; object-fit: cover;" />
                      <div class="bg-success text-white p-2 mt-1 rounded text-truncate" style="max-width: 150px; overflow: hidden; white-space: nowrap;">
                        {{ fileNames[index] }}
                      </div>
                    </div>
                    <div v-if="previewImages.length < 3" class="m-2 text-center d-flex flex-column align-items-center justify-content-center text-center" style="cursor: pointer; width: 150px; height: 120px;" @click="$refs.fileInput.click()">
                      <div class="d-flex align-items-center justify-content-center w-100 h-100">
                        <img src="@/assets/img/img-placeholder.webp" alt="Kép helyőrző" class="w-100 h-100" style="object-fit: cover;" />
                      </div>
                      <p class="text-white bg-success w-100 text-center fw-bold mb-0">Kép +</p>
                    </div>
                  </div>
                </div>
                <input type="file" ref="fileInput" multiple @change="handleFileUpload" accept="image/jpeg, image/png, image/webp" class="d-none" />
              </div>

              <div class="d-flex mt-6">
                <div class="container text-center">
                  <div class="row border">
                      <div class="col-md-6 p-3 border-end fw-bold">Megnevezés</div>
                      <div class="col-md-6 p-2">
                        <material-input placeholder="Név" id="name" v-model:value="realestate.name"
                                        name="name" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.name"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end fw-bold p-4">Ország</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.country_code"
                          :possible-values="getcountryList"
                          placeholder="Válassz országot!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Irányítószám</div>
                      <div class="col-md-6 p-2">
                        <material-input id="zip" v-model:value="realestate.zip" placeholder="Irányítószám"
                                        name="zip" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.zip"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Város</div>
                      <div class="col-md-6 p-2">
                        <material-input id="city" v-model:value="realestate.city" placeholder="Város"
                                        name="city" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.city"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Cím</div>
                      <div class="col-md-6 p-2">
                        <material-input id="address" v-model:value="realestate.address" placeholder="Cím"
                                        name="address" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.address"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Helyrajzi Szám</div>
                      <div class="col-md-6 p-2">
                        <material-input placeholder="Helyrajzi Szám" id="lot_number"
                                        v-model:value="realestate.lot_number"
                                        name="lot_number" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                      </div>
                  </div>
                </div>

                <div class="container text-center">
                  <div class="row border">
                      <div class="col-md-6 border-end p-4 fw-bold">Tulajdonos</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.owner_id"
                          :possible-values="getownerList"
                          placeholder="Válassz tulajdonost!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Tulajdoni Hányad</div>
                      <div class="col-md-6 p-2">
                        <material-input id="ownership" v-model:value="realestate.ownership"
                                        placeholder="Tulajdoni hányad"
                                        name="ownership" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.ownership"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Alapterület [Nettó]</div>
                      <div class="col-md-6 p-2">
                        <material-input id="deed_size" v-model:value="computedDeedSize"
                                        placeholder="Tulajdoni lap szerinti alapterület (m2)"
                                        name="deed_size" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.deed_size"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Alapterület [Bruttó]</div>
                      <div class="col-md-6 p-2">
                        <material-input id="gross_size" v-model:value="computedGrossSize"
                                        placeholder="Ingatlan bruttó alapterülete (m2)"
                                        name="gross_size" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.gross_size"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Épületek Száma</div>
                      <div class="col-md-6 p-2">
                        <material-input id="building_nr" v-model:value="realestate.building_nr"
                                        placeholder="Épületek száma (db)"
                                        name="building_nr" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-4 fw-bold">Funckciónalitás</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.realestate_functions_id"
                          :possible-values="getfunctionList"
                          placeholder="Válassz funkciót!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-4 fw-bold">Ingatlankezelési Forma</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.realestate_handlings_id"
                          :possible-values="gethandlingList"
                          placeholder="Válassz kezelési formát!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Ingatlankezelő</div>
                      <div class="col-md-6 p-2">
                        <material-input id="property_manager_name" v-model:value="realestate.property_manager_name"
                                        placeholder="Ingatlankezelő neve"
                                        name="property_manager_name" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.property_manager_name"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Ingatlankezelő E-mail Címe</div>
                      <div class="col-md-6 p-2">
                        <material-input id="property_manager_email" v-model:value="realestate.property_manager_email"
                                        placeholder="Ingatlankezelő e-mail címe"
                                        name="property_manager_email" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.property_manager_email"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-2 fw-bold">Ingatlankezelő Telefonszáma</div>
                      <div class="col-md-6 p-2">
                        <material-input id="property_manager_phone" v-model:value="realestate.property_manager_phone"
                                        placeholder="Ingatlankezelő telefonszáma"
                                        name="property_manager_phone" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.property_manager_phone"></validation-error>
                      </div>
                  </div>
                </div>
              </div>
              <div class="container my-6">
                <div class="row justify-content-center">
                  <div class="col-12 d-flex flex-wrap justify-content-center align-items-center gap-4">
                    <div v-for="(pdf, index) in pdfFiles" :key="index" class="m-2 text-center" style="cursor: pointer;">
                      <div class="" @click="removePdf(index)">✖</div>
                      <div class="rounded text-truncate border" style="max-width: 150px; overflow: hidden; white-space: nowrap;">
                        <img src="@/assets/img/pdf-placeholder.webp" alt="Kép helyőrző" class="w-100 h-100" style="object-fit: cover;" />
                        <div class="border-t text-center bg-success text-white">
                          {{ pdf.name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="pdfFiles.length < 3" class="text-center d-flex flex-column align-items-center justify-content-center mt-4" style="cursor: pointer; width: 150px; height: 150px;" @click="$refs.pdfInput.click()">
                      <div class="d-flex align-items-center justify-content-center w-100 h-100">
                        <img src="@/assets/img/pdf-placeholder.webp" alt="Kép helyőrző" class="w-100 h-100" style="object-fit: cover;" />
                      </div>
                      <p class="text-white bg-success w-100 text-center fw-bold mb-0">PDF +</p>
                    </div>
                  </div>
                </div>
                <input type="file" ref="pdfInput" multiple @change="handlePdfUpload" accept="application/pdf" class="d-none" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropDown from "@/components/FormDropdown.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";

export default {
  name: 'AddRealEstate',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
    FormDropDown,
  },
  mixins: [formMixin],
  setup() {
    return {
      color: '#33e8f5'
    }
  },
  data() {
    return {
      realestate: {},
      countries: {},
      owners: {},
      sfunctions: {},
      handlings: {},
      previewImages: [],
      pdfFiles: [],
      fileNames: [],
    }
  },
  computed: {
    getcountryList() {
      const data = this.$store.getters["countries/getCountriesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getownerList() {
      const data = this.$store.getters["contacts/getContactsBaseData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getfunctionList() {
      const data = this.$store.getters["sfunctions/getFunctionsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    gethandlingList() {
      const data = this.$store.getters["handlings/getHandlingsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getAreaFloorCoveringsList() {
      return this.$store.getters["coverings/getCoveringsData"];
    },
    getImage() {
      if (!this.profile_image) return require("@/assets/img/haz_rajz.jpg");
      else {
        return this.profile_image;
      }
    },
    computedDeedSize: {
      set(val) {
        this.realestate.deed_size = parseFloat(val);
      }
    },
    computedGrossSize: {
      set(val) {
        this.realestate.gross_size = parseFloat(val);
      }
    },
    computedNetFloorSpace: {
      set(val) {
        this.area.net_floor_space = parseFloat(val);
      }
    },
    computedGrossFloorSpace: {
      set(val) {
        this.area.gross_floor_space = parseFloat(val);
      }
    },
    computedHeight: {
      set(val) {
        this.area.height = parseFloat(val);
      }
    },
    totalImgCount() {
      return this.previewImages.length;
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("countries/getCountries"),
        this.$store.dispatch("contacts/getContactsBaseData"),
        this.$store.dispatch("sfunctions/getFunctions"),
        this.$store.dispatch("handlings/getHandlings"),
        this.$store.dispatch("positions/getPositions"),
        this.$store.dispatch("afunctions/getFunctions"),
        this.$store.dispatch("coverings/getCoverings"),
      ]);

      this.loading = false;
    } catch (error) {
      console.error("Error loading data:", error);
    }
  },
  methods: {
    handleFileUpload(event) {
      if (!this.imageFiles) {
        this.imageFiles = [];
      }
      if (!this.realestate.files) {
        this.realestate.files = [];
      }

      const newFiles = Array.from(event.target.files);

      newFiles.forEach((file) => {
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImages.push(e.target.result);
            this.imageFiles.push(file); 
            this.realestate.files.push(file); 
            this.fileNames.push(file.name);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    handlePdfUpload(event) {
      if (!this.realestate.files) this.realestate.files = [];
      const newPdfs = Array.from(event.target.files);

      newPdfs.forEach((file) => {
        if (file.type === "application/pdf") {
          this.pdfFiles.push(file);
          this.realestate.files.push(file);
        }
      });
    },
    removeImage(index) {
      this.previewImages.splice(index, 1);
      this.realestate.files.splice(index, 1);
    },
    removePdf(index) {
      const pdfName = this.pdfFiles[index].name;
      this.realestate.files = this.realestate.files.filter(file => file.name !== pdfName);
      this.pdfFiles.splice(index, 1);
    },
    async handleAdd() {
      this.resetApiValidation();
      try {
        this.realestate.areas = this.areas;
        await this.$store.dispatch("realestates/addRealEstate", this.realestate);
        showSwal.methods.showSwal({
          type: "success",
          message: "Ingatlan sikeresen hozzáadva!",
          width: 500
        });
        this.$router.push({name: "RealEstates List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });

      }
    },
  }
}
</script>
<style scoped>
.uploaded-image img {
  width: 100%; 
  max-width: 150px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 5px;
}

.text-center {
  text-align: center;
}

.bg-success {
  background-color: #28a745;
  color: white;
  padding: 5px;
}
</style>