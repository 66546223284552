<template>
    <div v-if="isModalVisible" class="modal" @click.self="closeModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="mt-4 row bottom-border">
                        <div  
                            class="col-12"
                        >
                            <div class="row ">
                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Mérési pont azonosító</p>
                                </div>
                                <div class="col-5">
                                    {{ invoice.submeter_pod }}
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Szolgáltatás típusa</p>
                                </div>
                                <div class="col-3">
                                    {{ invoice.utility_services_name }}
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Elszámolási időszak</p>
                                </div>
                                <div class="col-5">
                                    {{ invoice.accounting_period_start }} - {{ invoice.accounting_period_end }}
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Számla sorszáma</p>
                                </div>
                                <div class="col-3">

                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Kezdő és záró mérőállás</p>
                                </div>
                                <div class="col-5">
                                    <div class="flex justify-center items-center">
                                        {{ invoice.previous_value }} - {{ invoice.current_value }}
                                    </div>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Számla kelte</p>
                                </div>
                                <div class="col-3">
                                    {{ invoice.invoice_date }}
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Hónap</p>
                                </div>
                                <div class="col-3">
                                    {{ invoice.r_year }}/{{ invoice.r_month }}
                                </div>
                            </div>
                            <div class="row border border-secondary rounded my-5">
                                <div class="col-2" :style="{
                                    backgroundImage:
                                        'url(' + require('@/assets/img/providers/e-on.png') + ')',
                                    backgroundSize: 'contain',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                }">
                                </div>
                                <div class="col-7">
                                    <div class="col-12">
                                        <p class="form-label text-black pt-2 text-bold"></p>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <p class="form-label text-black pt-2 text-bold">Szerződés száma</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="form-label text-black pt-2"></p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <p class="form-label text-black pt-2 text-bold">Bankszámlaszám</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="form-label text-black pt-2"></p>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-6">
                                            <p class="form-label text-black pt-2 text-bold">Kapcsolattartó</p>
                                        </div>
                                        <div class="col-6">
                                            <p class="form-label text-black pt-2"></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-3" style="background-color: #fbe3d5;">
                                    <div class="row text-center">
                                        <div class="col-12">
                                            <p class="form-label text-black pt-2 text-bold">Számla összeg</p>
                                        </div>
                                        <div class="col-12">
                                            <h3 class="text-black pt-2 text-bold"></h3>
                                        </div>
                                        <div class="col-12">
                                            <p class="form-label text-black pt-2 text-bold">HUF</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Főmérő gyári száma</p>
                                </div>
                                <div class="col-5">
                                    <p class="form-label text-black pt-2">{{ invoice.meter_pod }}</p>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Fogyasztó neve</p>
                                </div>
                                <div class="col-3">
                                    <p class="form-label text-black pt-2"></p>
                                </div>


                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Aktuális fogyasztás </p>
                                </div>
                                <div class="col-5">
                                    <p class="form-label text-black pt-2"> kWh</p>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Partnerszám</p>
                                </div>
                                <div class="col-3">
                                    <p class="form-label text-black pt-2">1000000352</p>
                                </div>


                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Aktuális visszatáplálás</p>
                                </div>
                                <div class="col-5">

                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Fogyasztási hely neve</p>
                                </div>
                                <div class="col-3">
                                    <p class="form-label text-black pt-2"></p>
                                </div>


                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Szerződés lejárati idő </p>
                                </div>
                                <div class="col-5">
                                    <p class="form-label text-black pt-2"></p>
                                </div>

                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Fogyasztási hely címe</p>
                                </div>
                                <div class="col-3">
                                    <p class="form-label text-black pt-2"></p>
                                </div>
                            </div>

                            <div class="row mt-5">
                                <div class="col-5">
                                    <p class="form-label text-black pt-2 text-bold">Tétel megnevezése</p>
                                </div>
                                <div class="col-1">
                                    <p class="form-label text-black pt-2 text-bold">ÁFA%</p>
                                </div>
                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Nettó érték (HUF)</p>
                                </div>
                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Áfa összege(HUF)</p>
                                </div>
                                <div class="col-2">
                                    <p class="form-label text-black pt-2 text-bold">Bruttó érték (HUF)</p>
                                </div>

                                <hr>

                                <div class="col-5">
                                    <p class="form-label text-black pt-2 ">Energiadíj összesen</p>
                                </div>
                                <div class="col-1">
                                    <p class="form-label text-black pt-2 ">27</p>
                                </div>
                                <div class="col-2">
                                    <p class="form-label text-black pt-2 " id="energy_price_Net_out"></p>
                                </div>
                                <div class="col-2">
                                    <p class="form-label text-black pt-2" id="energy_price_AFA_out"></p>
                                </div>
                                <div class="col-2">
                                    < </div>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">Jövedéki adó összesen</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 ">27</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="excise_tax_Net_out"></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2" id="excise_tax_AFA_out"></p>
                                        </div>
                                        <div class="col-2">

                                        </div>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">Pénzeszközök összesen</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 ">AHK</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="cash_equivalents_out">
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2">- </p>
                                        </div>
                                        <div class="col-2">

                                        </div>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">KÁT és Prémium pénzeszközök
                                                összesen</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 ">AHK</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="kat_and_premium_equivalents"></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2">- </p>
                                        </div>
                                        <div class="col-2">

                                        </div>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">Rendszerhasználati díjak
                                                összesen </p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 ">27</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="grid_access_fees_Net_out"></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2" id="grid_access_fees_AFA_out">
                                            </p>
                                        </div>
                                        <div class="col-2">

                                        </div>

                                        <hr>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">Összesítés (ÁFA szerint)</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 ">27</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="sum_with_afa_Net_out">
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2" id="sum_with_afa_AFA_out"></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="sum_with_afa"></p>
                                        </div>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">Összesítés (ÁFA szerint)</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 ">AHK</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="sum_without_afa"></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2">-</p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="sum_without_afa2"></p>
                                        </div>

                                        <hr>

                                        <div class="col-5">
                                            <p class="form-label text-black pt-2 ">Számla összesen</p>
                                        </div>
                                        <div class="col-1">
                                            <p class="form-label text-black pt-2 "></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2 " id="gross_amount_Net_out">
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2" id="gross_amount_AFA_out"></p>
                                        </div>
                                        <div class="col-2">
                                            <p class="form-label text-black pt-2" id="gross_amount"></p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4 mb-4 container">
                    <div class="row justify-content-between">
                        <div class="col-2">
                            <Button type="button" label="Letöltés" icon="pi pi-download" />
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <div class="me-2">
                                <Button type="button" label="NAV Küldés" icon="pi pi-arrow-right" />
                            </div>
                            <div>
                                <Button 
                                    type="button"
                                    label="Elfogadom"
                                    icon="pi pi-check"
                                    v-if="invoice.check === 0" 
                                    @click="updateInvoiceStatus(invoice.id, true)"
                                />
                                <Button 
                                    type="button"
                                    label="Elutasítás"
                                    icon="pi pi-times"
                                    severity="danger"
                                    v-else 
                                    @click="updateInvoiceStatus(invoice.id, false)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
import { CButton } from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import formMixin from "@/mixins/formMixin.js";
import { ref } from 'vue';
import showSwal from "../../../../mixins/showSwal";

export default {
    name: "InvoiceModal",
    components: {
        MaterialButton,
        CButton,
    },
    mixins: [formMixin],
    setup() {
        const visible = ref(false);
        return {
            color: '#33e8f',
            visible,
        }
    },
    data() {
        return {
            isModalVisible: false,
            invoice: null,
        };
    },
    methods: {
        openModal(billing) {
            this.invoice = billing;
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        updateInvoiceStatus(id, newStatus) {
            this.invoice.check = newStatus ? 1 : 0;
            this.$emit('approve-invoice', this.invoice);
            this.updateStatusOnServer(id, newStatus);
        },
        async updateStatusOnServer(id, newStatus) {
            try {
                await this.$store.dispatch("billings/outInvoicesStore", {id: id, status: newStatus});
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Számlakép státusza sikeresen frissítve!",
                    width: 500
                });
            } catch (error) {
                console.error("Error in outgoing invoices store:", error);
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Valami hiba történt!",
                    width: 500
                });
            }
        },
    }
};
</script>

<style scoped>
.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 1200px;
    max-height: 90vh;
    overflow-y: auto;
    margin-top: 5vh;
}

.modal-body {
    padding: 10px;
}
</style>