<template>
  <Toast/>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header border-bottom">
            <div class="row d-flex align-items-center">
              <div class="col-6">
                <h5 class="mb-0">Ingatlan Módosítása</h5>
              </div>
              <div class="col-6 text-end d-flex justify-content-end gap-4">
                <material-button 
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'RealEstates List' })"
                  @click.prevent="handleEdit"
                  :loading="loading"
                >
                  Mentés
                </material-button>
                <material-button 
                  class="float-right btn btm-sm"
                  @click="this.$router.push({ name: 'RealEstates List' })"
                  color="secondary"
                >
                  Vissza
                </material-button>
              </div>
            </div>
          </div>
          <!--Card body-->
          <div class="card-body">
            <form>
              <div class="container my-6">
                <div class="row justify-content-center align-items-center">
                  <div class="col-12 d-flex flex-wrap justify-content-center align-items-center gap-6">
                    <div v-if="images.length > 0" class="d-flex flex-wrap justify-content-center gap-6">
                      <div v-for="(image, index) in images" :key="image.id" class="text-center">
                        <div @click="deleteMedia(image.id)" class="cursor-pointer" style="color: red;">✖</div>
                        <img :src="image.original_url" :alt="'Image ' + index" class="img-thumbnail cursor-pointer" style="width: 150px; height: 150px; object-fit: cover;" @click="openModal(image)"/>
                        <div class="bg-success text-white p-2 mt-1 rounded text-truncate" style="max-width: 150px; max-height: 150px; overflow: hidden; white-space: nowrap;">
                          {{ image.file_name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="previewImages.length > 0" v-for="(image, index) in previewImages" :key="index" class="m-2 text-center" style="cursor: pointer;">
                      <div @click="removeImage(index)">✖</div>
                      <img :src="image" alt="Kép előnézete" class="img-thumbnail cursor-pointer" style="width: 150px; height: 150px; object-fit: cover;" @click="openModal(image)"/>
                      <div class="bg-success text-white p-2 mt-1 rounded text-truncate" style="max-width: 150px; overflow: hidden; white-space: nowrap;">
                        {{ fileNames[index] }}
                      </div>
                    </div>
                    <div v-if="totalImagesCount < 3" class="m-2 text-center d-flex flex-column align-items-center justify-content-center text-center" style="cursor: pointer; width: 150px; height: 120px;" @click="$refs.fileInput.click()">
                      <div class="d-flex align-items-center justify-content-center w-100 h-100">
                        <img src="@/assets/img/img-placeholder.webp" alt="Kép helyőrző" class="w-100 h-100" style="object-fit: cover;" />
                      </div>
                      <p class="text-white bg-success w-100 text-center fw-bold mb-0">Kép +</p>
                    </div>
                  </div>
                  <input type="file" ref="fileInput" multiple @change="handleFileUpload" accept="image/jpeg, image/png, image/webp" class="d-none" />
                </div>
              </div>
              <div v-if="modalVisible" class="modal fade show d-block" tabindex="-1" role="dialog" style="background: rgba(0,0,0,0.6); display: flex; align-items: center; justify-content: center; min-height: 100vh;">
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">{{ selectedImage?.file_name }}</h5>
                        <button type="button" class="btn-close cursor-pointer" @click="modalVisible = false"></button>
                      </div>
                      <div class="modal-body text-center">
                        <img :src="selectedImage?.original_url" class="img-fluid" alt="Kép nagyban">
                      </div>
                      <div class="modal-footer">
                        <material-button type="button" color="secondary" @click="modalVisible = false">Bezárás</material-button >
                        <material-button class="btn btn-success" @click.prevent="downloadMedia(selectedImage?.id)">Letöltés</material-button >
                      </div>
                    </div>
                  </div>
              </div>
              <div class="d-flex mt-6">
                <div class="container text-center">
                  <div class="row border">
                      <div class="col-md-6 p-3 border-end fw-bold">Megnevezés</div>
                      <div class="col-md-6 p-2">
                        <material-input placeholder="Név" id="name" v-model:value="realestate.name"
                                        name="name" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.name"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end fw-bold p-4">Ország</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.country_code"
                          :possible-values="getcountryList"
                          placeholder="Válassz országot!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Irányítószám</div>
                      <div class="col-md-6 p-2">
                        <material-input id="zip" v-model:value="realestate.zip" placeholder="Irányítószám"
                                        name="zip" variant="static" disabled></material-input>
                        <validation-error :errors="apiValidationErrors.zip"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Város</div>
                      <div class="col-md-6 p-2">
                        <material-input id="city" v-model:value="realestate.city" placeholder="Város"
                                        name="city" variant="static" disabled></material-input>
                        <validation-error :errors="apiValidationErrors.city"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Cím</div>
                      <div class="col-md-6 p-2">
                        <material-input id="address" v-model:value="realestate.address" placeholder="Cím"
                                        name="address" variant="static" disabled></material-input>
                        <validation-error :errors="apiValidationErrors.address"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Helyrajzi Szám</div>
                      <div class="col-md-6 p-2">
                        <material-input placeholder="Helyrajzi Szám" id="lot_number"
                                        v-model:value="realestate.lot_number"
                                        name="lot_number" variant="static" disabled></material-input>
                        <validation-error :errors="apiValidationErrors.lot_number"></validation-error>
                      </div>
                  </div>
                </div>

                <div class="container text-center">
                  <div class="row border">
                      <div class="col-md-6 border-end p-4 fw-bold">Tulajdonos</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.owner_id"
                          :possible-values="getownerList"
                          placeholder="Válassz tulajdonost!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Tulajdoni Hányad</div>
                      <div class="col-md-6 p-2">
                        <material-input id="ownership" v-model:value="realestate.ownership"
                                        placeholder="Tulajdoni hányad"
                                        name="ownership" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.ownership"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Alapterület [Nettó]</div>
                      <div class="col-md-6 p-2">
                        <material-input id="deed_size" v-model:value="realestate.deed_size"
                                        placeholder="Tulajdoni lap szerinti alapterület (m2)"
                                        name="deed_size" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.deed_size"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Alapterület [Bruttó]</div>
                      <div class="col-md-6 p-2">
                        <material-input id="gross_size" v-model:value="realestate.gross_size"
                                        placeholder="Ingatlan bruttó alapterülete (m2)"
                                        name="gross_size" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.gross_size"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Épületek Száma</div>
                      <div class="col-md-6 p-2">
                        <material-input id="building_nr" v-model:value="realestate.building_nr"
                                        placeholder="Épületek száma (db)"
                                        name="building_nr" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.building_nr"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-4 fw-bold">Funckciónalitás</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.realestate_functions_id"
                          :possible-values="getfunctionList"
                          placeholder="Válassz funkciót!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-4 fw-bold">Ingatlankezelési Forma</div>
                      <div class="col-md-6 pt-3">
                        <FormDropDown
                          v-model.number="realestate.realestate_handlings_id"
                          :possible-values="gethandlingList"
                          placeholder="Válassz kezelési formát!"
                          :searchable="true"
                          mode="single"
                        />
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Ingatlankezelő</div>
                      <div class="col-md-6 p-2">
                        <material-input id="property_manager_name" v-model:value="realestate.property_manager_name"
                                        placeholder="Ingatlankezelő neve"
                                        name="property_manager_name" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.property_manager_name"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Ingatlankezelő E-mail Címe</div>
                      <div class="col-md-6 p-2">
                        <material-input id="property_manager_email" v-model:value="realestate.property_manager_email"
                                        placeholder="Ingatlankezelő e-mail címe"
                                        name="property_manager_email" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.property_manager_email"></validation-error>
                      </div>
                  </div>
                  <div class="row border">
                      <div class="col-md-6 border-end p-3 fw-bold">Ingatlankezelő Telefonszáma</div>
                      <div class="col-md-6 p-2">
                        <material-input id="property_manager_phone" v-model:value="realestate.property_manager_phone"
                                        placeholder="Ingatlankezelő telefonszáma"
                                        name="property_manager_phone" variant="static"></material-input>
                        <validation-error :errors="apiValidationErrors.property_manager_phone"></validation-error>
                      </div>
                  </div>
                </div>
              </div>
              <div class="container my-6">
                <div class="row justify-content-center align-items-center">
                  <div class="col-12 d-flex flex-wrap justify-content-center align-items-center gap-6 mt-4">
                    <div v-for="pdf in documents" :key="pdf.id" class="m-2 text-center" style="cursor: pointer;">
                      <div @click="deleteMedia(pdf.id)" class="cursor-pointer" style="color: red;">✖</div>
                      <div @click="showPdf(pdf)" class="rounded text-truncate border" style="max-width: 150px; overflow: hidden; white-space: nowrap;">
                        <img src="@/assets/img/pdf-placeholder.webp" alt="PDF" class="w-100 h-100" style="object-fit: cover;" />
                        <div class="border-t text-center bg-success text-white">
                          {{ pdf.file_name }}
                        </div>
                      </div>
                    </div>
                    <div v-for="(pdf, index) in pdfFiles" :key="index" class="m-2 text-center" style="cursor: pointer;">
                      <div class="" @click="removePdf(index)">✖</div>
                      <div class="rounded text-truncate border" style="max-width: 150px; overflow: hidden; white-space: nowrap;">
                        <img src="@/assets/img/pdf-placeholder.webp" alt="Kép helyőrző" class="w-100 h-100" style="object-fit: cover;" />
                        <div class="border-t text-center bg-success text-white">
                          {{ pdf.name }}
                        </div>
                      </div>
                    </div>
                    <div v-if="totalPdfCount < 3" class="mx-2 mt-4 text-center" style="cursor: pointer;" @click="openFilePicker">
                      <div class="rounded text-truncate border" style="max-width: 150px; overflow: hidden; white-space: nowrap;">
                        <img src="@/assets/img/pdf-placeholder.webp" alt="Kép helyőrző" class="w-100 h-100" style="object-fit: cover;" />
                        <div class="border-t text-center bg-success text-white">
                          PDF +
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="file" ref="pdfInput" multiple @change="handlePdfUpload" accept="application/pdf" class="d-none" />
                </div>
              </div>
              
              <div class="relative">
                <MergeRealEstatesAreasModal 
                  :isModalForMergeOpen="isModalForMergeOpen" 
                  @close="closeModal" 
                  :selectedareas="selectedareas" 
                  :realestateId="realestateId"
                  @updateAreas="addNewArea"
                  @removeAreas="deleteOldAreas"
                />

                <SeparateRealEstatesAreasModal
                  :isModalForSeparateOpen="isModalForSeparateOpen" 
                  @close="closeModal" 
                  :selectedareas="selectedareas" 
                  :realestateId="realestateId"
                  @editAreas="addNewAreas"
                  @editNetFloorSpace="editAreaNetGrossFloorSpace"
                  @removeAreas="deleteOldAreas"
                />

                <!--<div class="button_plus" id="showButton" color="dark" variant="ghost" @click="visible = !visible" />-->
                <CCollapse :visible="visible" style="position: relative; top: 20px;">
                  <CCard class="mt-3">
                    <CCardBody>
                      <div>
                        <div class="table-responsive">
                          <!-- Table-->
                          <DataTable
                              ref="dt"
                              v-model:selection="selectedareas"
                              :value="areas"
                              dataKey="id"
                              :paginator="true"
                              :rows="10"
                              :filters="filters"
                              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                              :rowsPerPageOptions="[5, 10, 25, 50]"
                              currentPageReportTemplate="{first} - {last} a {totalRecords} helységből"
                              resizableColumns
                              columnResizeMode="fit"

                          >
                            <template #header>
                              <Toolbar class="mb-2">
                                <template #center>
                                  <div class="my-4">
                                    <div class="d-flex gap-4 justify-content-between">
                                      <div class="flex flex-wrap gap-2">
                                        <div class="row mt-1 mb-1 d-flex align-items-right">
                                          <IconField style="padding-left: 0px;">
                                            <InputIcon>
                                              <i class="pi pi-search"/>
                                            </InputIcon>
                                            <InputText v-model="filters['global'].value" placeholder="Keresés..."/>
                                          </IconField>
                                        </div>
                                      </div>
                                      <Button 
                                        label="Helyiség Hozzáadása"
                                        icon="pi pi-plus" 
                                        class="mr-2"
                                        @click="openNew"
                                      />
                                      <Button 
                                        label="Átalakítás" 
                                        icon="pi pi-building" 
                                        severity="danger" 
                                        outlined 
                                        @click="openModal(selectedareas)"
                                        :disabled="!selectedareas || !selectedareas.length || realestate.get_areas_sum_gross_floor_space !== realestate.gross_size"
                                      />
                                      <FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import"
                                              customUpload chooseLabel="Import" class="mr-2" auto
                                              :chooseButtonProps="{ severity: 'secondary' }"/>
                                      <Button label="Export" icon="pi pi-upload" severity="secondary"
                                              @click="exportCSV($event)"/>
                                    </div>
                                    <div class="d-flex justify-content-between py-4 gap-12">
                                      <div class="container text-center px-0">
                                        <div class="row border">
                                          <div class="col-md-6 border-end p-2 fw-bold">ÉPÜLETEK</div>
                                            <div class="col-md-6 pt-2">
                                              {{ realestate.building_nr }}
                                            </div>
                                        </div>
                                        <div class="row border">
                                            <div class="col-md-6 border-end p-2 fw-bold">RENDELTETÉSI EGYSÉGEK</div>
                                            <div class="col-md-6 pt-2">
                                              {{ realestate.get_areas_count }}
                                            </div>
                                        </div>
                                      </div>
                                      <div class="container text-center px-0">
                                        <div class="row border">
                                          <div class="col-md-6 border-end p-2 fw-bold">INGATLAN FELDOLGOZOTTSÁG</div>
                                            <div class="col-md-6 pt-2">
                                              {{ realestate.get_areas_sum_gross_floor_space || 0 }} / {{ realestate.gross_size || 0 }}
                                            </div>
                                        </div>
                                        <div class="row border">
                                            <div class="col-md-6 border-end p-2 fw-bold">HASZONTERÜLET/EGYÉB</div>
                                            <div class="col-md-6 pt-2">
                                              {{ countCommonArea }} / {{ countOperations }}
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row mt-1 d-flex align-items-left">
                                          <MultiSelect 
                                            :modelValue="selectedColumns" 
                                            :options="columns" 
                                            optionLabel="header"         
                                            @update:modelValue="onToggle"
                                            display="chip" placeholder="Select Columns"
                                          />
                                    </div>
                                  </div>
                                </template>
                              </Toolbar>
                            </template>

                            <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>
                            <Column v-for="(col, index) of selectedColumns" :field="col.field" :header="col.header"
                                    :key="col.field + '_' + index" sortable>
                              <template v-if="col.field == 'area_buildings_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.area_buildings[0].name }}
                                </div>
                              </template>
                              <template v-if="col.field == 'area_numbers_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.area_numbers[0].name }}
                                </div>
                              </template>
                              <template v-if="col.field == 'area_functions_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.area_function[0].name }}
                                </div>
                              </template>
                              <template v-if="col.field == 'area_positions_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.area_position[0].name }}
                                </div>
                              </template>
                              <template v-if="col.field == 'area_floor_coverings_id'" #body="slotProps">
                                <div>
                                  {{ slotProps.data.area_floor_covering[0].name }}
                                </div>
                              </template>
                            </Column>
                            <Column :exportable="false" style="min-width: 12rem">
                              <template #body="slotProps">
                                <Button icon="pi pi-pencil" outlined rounded class="mr-2"
                                        @click="editarea(slotProps.data)"/>
                                <Button icon="pi pi-trash" outlined rounded severity="danger"
                                        @click="confirmDeletearea(slotProps.data)"/>
                              </template>
                            </Column>
                          </DataTable>
                        </div>

                        <Dialog v-model:visible="areaDialog" :style="{ width: '450px' }" header="Helység adatok"
                                :modal="true">
                          <div class="flex flex-col" style="display: flex;flex-direction: column; gap: 1rem;">
                            <div>
                              <label for="identifier" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Azonostó</label>
                              <InputText id="identifier" v-model.trim="area.identifier" required="true" autofocus
                                         :invalid="submitted && !area.identifier" fluid/>
                              <small v-if="submitted && !area.identifier" class="text-red-500">Azonostó szükséges.</small>
                            </div>
                            <div class="d-flex gap-1">
                              <div>
                                <label for="area_numbers_id" class="block font-bold mb-2"
                                     style="display: block; font-weight: 300;">Helység száma</label>
                                <Select id="area_numbers_id" v-model="computedAreaNumbersId"
                                      :options="getAreaNumbersList" optionLabel="name" optionValue="id"
                                      placeholder="Válasszon számot" fluid></Select>
                              </div>
                              <div>
                                <label for="area_functions_id" class="block font-bold mb-2"
                                     style="display: block; font-weight: 300;">Helység funkció</label>
                                <Select id="area_functions_id" v-model="computedFunctionId"
                                      :options="getAreaFunctionsList" optionLabel="name" optionValue="id"
                                      placeholder="Válasszon funkciót" fluid></Select>
                              </div>
                            </div>
                            <div>
                              <label for="description" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Nettó alapterület [m2]</label>
                              <InputText id="net_floor_space" v-model.trim="area.net_floor_space" required="true"
                                         autofocus :invalid="submitted && !area.net_floor_space" fluid/>
                              <small v-if="submitted && !area.net_floor_space" class="text-red-500">Nettó terület szükséges..</small>
                            </div>
                            <div>
                              <label for="area_buildings_id" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Épület szám</label>
                              <Select id="area_buildings_id" v-model="computedBuildingId"
                                      :options="getBuildingsList" optionLabel="name" optionValue="id"
                                      placeholder="Válasszon épületet!" fluid></Select>
                            </div>
                            <div>
                              <label for="area_positions_id" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Pozíció</label>
                              <Select id="area_positions_id" v-model="computedPositionId"
                                      :options="getAreaPositionsList" optionLabel="name" optionValue="id"
                                      placeholder="Válasszon pozíciót!" fluid></Select>
                            </div>
                            <div>
                              <label for="description" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Helyrajzi szám</label>
                              <InputText id="lot_number" v-model.trim="area.lot_number" required="true" autofocus
                                         :invalid="submitted && !area.lot_number" fluid/>
                              <small v-if="submitted && !area.lot_number" class="text-red-500">Helyrajzi szám szükséges.</small>
                            </div>
                            <div>
                              <label for="description" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Bruttó alapterület [m2]</label>
                              <InputText id="gross_floor_space" v-model.trim="area.gross_floor_space" required="true"
                                         autofocus :invalid="submitted && !area.gross_floor_space" fluid/>
                              <small v-if="submitted && !area.gross_floor_space" class="text-red-500">Bruttó alapterület szükséges.</small>
                            </div>
                            <div>
                              <label for="description" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Belmagasság [m]</label>
                              <InputText id="height" v-model.trim="area.height" required="true" autofocus
                                         :invalid="submitted && !area.height" fluid/>
                              <small v-if="submitted && !area.height" class="text-red-500">Belmagasság szükséges.</small>
                            </div>
                            <div>
                              <label for="area_floor_coverings_id" class="block font-bold mb-3"
                                     style="display: block; font-weight: 700;">Burkolat</label>
                              <Select id="area_floor_coverings_id" v-model="computedFloorCoveringsId"
                                      :options="getAreaFloorCoveringsList" optionLabel="name" optionValue="id"
                                      placeholder="Válasszon burkolatot!" fluid></Select>
                            </div>
                          </div>

                          <template #footer>
                            <Button label="Mégse" icon="pi pi-times" text @click="hideDialog"/>
                            <Button label="Mentés" icon="pi pi-check" :loading="loading" @click="savearea"/>
                          </template>
                        </Dialog>

                        <Dialog v-model:visible="deleteareaDialog" :style="{ width: '450px' }" header="Megerősítés"
                                :modal="true">
                          <div class="flex items-center gap-4">
                            <i class="pi pi-exclamation-triangle !text-3xl"/>
                            <span v-if="area"
                            >Biztos vagy benne, hogy törlöd a <b>{{ area.area_nr }}</b
                            > helységet?</span
                            >
                          </div>
                          <template #footer>
                            <Button label="No" icon="pi pi-times" text @click="deleteareaDialog = false">Nem</Button>
                            <Button label="Yes" icon="pi pi-check" @click="deletearea">Igen</Button>
                          </template>
                        </Dialog>

                        <Dialog v-model:visible="deleteareasDialog" :style="{ width: '450px' }" header="Megerősítés"
                                :modal="true">
                          <div class="flex items-center gap-4">
                            <i class="pi pi-exclamation-triangle !text-3xl"/>
                            <span v-if="area">Biztos vagy benne, hogy törlöd a kijelölt helységeket?</span>
                          </div>
                          <template #footer>
                            <Button label="No" icon="pi pi-times" text @click="deleteareasDialog = false">Nem</Button>
                            <Button label="Yes" icon="pi pi-check" text @click="deleteSelectedareas">Igen</Button>
                          </template>
                        </Dialog>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCollapse>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropDown from "@/components/FormDropdown.vue";
import {ref, onMounted } from 'vue';
import {CButton, CCard, CCardBody, CCollapse} from '@coreui/vue-pro';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import ValidationError from "@/components/ValidationError.vue";
import Modal from "@/components/Modal";
import formMixin from "@/mixins/formMixin.js";
import showSwal from "../../../mixins/showSwal";
import {FilterMatchMode} from '@primevue/core/api';
import { useRoute } from 'vue-router';
import MergeRealEstatesAreasModal from "./components/MergeRealEstatesAreasModal.vue";
import SeparateRealEstatesAreasModal from "./components/SeparateRealEstatesAreasModal.vue";

export default {
  name: 'EditRealEstate',
  components: {
    MaterialButton,
    MaterialInput,
    ValidationError,
    CButton,
    CCollapse,
    CCard,
    CCardBody,
    Modal,
    FormDropDown,
    MergeRealEstatesAreasModal,
    SeparateRealEstatesAreasModal,
  },
  mixins: [formMixin],
  setup() {
    const visible = ref(true);
    const isModalForMergeOpen = ref(false);
    const isModalForSeparateOpen = ref(false);
    const selectedareas = ref(null);
    const realestateId = ref(null);
    const route = useRoute();

    onMounted(() => {
      realestateId.value = route.params.id;
    });

    const openModal = (selectedareas) => {
      if(selectedareas.length > 1) {
        isModalForMergeOpen.value = true;
      } else {
        isModalForSeparateOpen.value = true;
      }
    };

    const closeModal = () => {
        isModalForMergeOpen.value = false;
        isModalForSeparateOpen.value = false;
    };

    return {
      visible,
      color: '#33e8f5',
      isModalForMergeOpen,
      isModalForSeparateOpen,
      selectedareas,
      openModal,
      closeModal,
      realestateId,
    }
  },
  data() {
    return {
      data: [],
      realestate: {},
      areas: [],
      countries: {},
      owners: {},
      sfunctions: {},
      handlings: {},
      newArea: {},
      selected: null,
      areaDialog: false,
      deleteareaDialog: false,
      deleteareasDialog: false,
      area: {},
      selectedareas: null,
      filters: {},
      submitted: false,
      loading: false,
      selectedColumns: null,
      columns: null,
      previewImages: [],
      realEstateImages: [],
      pdfFiles: [],
      fileNames: [],
      images: [],
      documents: [],
      deleteMediaFiles: {},
      modalVisible: false,
      selectedImage: null,
    }
  },
  created() {
    this.initFilters();
    this.columns = [
      {field: 'identifier', header: 'Azonosító'},
      {field: 'area_buildings_id', header: 'Épület'},
      {field: 'area_numbers_id', header: 'Helység'},
      {field: 'area_functions_id', header: 'Funkció'},
      {field: 'area_positions_id', header: 'Pozíció'},
      {field: 'lot_number', header: 'Helyrajzi szám'},
      {field: 'net_floor_space', header: 'Nettó terület'},
      {field: 'gross_floor_space', header: 'Bruttó terület'},
      {field: 'height', header: 'Magasság'},
      {field: 'area_floor_coverings_id', header: 'Burkolat'},
    ];
    this.selectedColumns = this.columns;
  },
  computed: {
    getcountryList() {
      const data = this.$store.getters["countries/getCountriesData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getownerList() {
      const data = this.$store.getters["contacts/getContactsBaseData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getfunctionList() {
      const data = this.$store.getters["sfunctions/getFunctionsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    gethandlingList() {
      const data = this.$store.getters["handlings/getHandlingsData"] ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getAreaPositionsList() {
      return this.$store.getters["positions/getPositionsData"];
    },
    getAreaNumbersList() {
      return this.$store.getters["areanumbers/getAreanumbersData"];
    },
    getBuildingsList() {
      return this.$store.getters["buildings/getBuildingsData"];
    },
    areaPositionsList() {
      const data = this.getAreaPositionsList ?? [];
      return data.map(item => ({
                value: item.id,
                label: item.name
            }));
    },
    getAreaFunctionsList() {
      return this.$store.getters["afunctions/getFunctionsData"];
    },
    getAreaFloorCoveringsList() {
      return this.$store.getters["coverings/getCoveringsData"];
    },
    getRealestateList() {
      return this.$store.getters["realestates/getRealEstatesData"];
    },
    getImage() {
      if (!this.profile_image)
        return require("@/assets/img/haz_rajz.jpg");
      else {
        return this.profile_image;
      }
    },
    computedBuildingId: {
      get() {
        if (this.area.area_buildings_id) {
          return this.area.area_buildings_id.toString()
        }
      },
      set(val) {
        this.area.area_buildings_id = Number(val)
      }
    },
    computedAreaNumbersId: {
      get() {
        if (this.area.area_numbers_id) {
          return this.area.area_numbers_id.toString()
        }
      },
      set(val) {
        this.area.area_numbers_id = Number(val)
      }
    },
    computedFunctionId: {
      get() {
        if (this.area.area_functions_id) {
          return this.area.area_functions_id.toString()
        }
      },
      set(val) {
        this.area.area_functions_id = Number(val)
      }
    },
    computedPositionId: {
      get() {
        if (this.area.area_positions_id) {
          return this.area.area_positions_id.toString()
        }
      },
      set(val) {
        this.area.area_positions_id = Number(val)
      }
    },
    computedFloorCoveringsId: {
      get() {
        if (this.area.area_floor_coverings_id) {
          return this.area.area_floor_coverings_id.toString()
        }
      },
      set(val) {
        this.area.area_floor_coverings_id = Number(val)
      }
    },
    totalPdfCount() {
      return this.pdfFiles.length + this.documents.length;
    },
    totalImagesCount() {
      return this.previewImages.length + this.images.length;
    },
    countCommonArea() {
      return this.areas.filter(area => area.identifier.includes("Közös terület")).length;
    },
    countOperations() {
      return this.areas.filter(area => area.identifier.includes("Üzemeltetés")).length;
    },
  },
  async mounted() {
    try {
      await Promise.all([
        this.$store.dispatch("realestates/getRealEstate", this.$route.params.id),
        this.$store.dispatch("buildings/getBuildings"),
        this.$store.dispatch("areanumbers/getAreanumbers"),
        this.$store.dispatch("countries/getCountries"),
        this.$store.dispatch("contacts/getContactsBaseData"),
        this.$store.dispatch("sfunctions/getFunctions"),
        this.$store.dispatch("handlings/getHandlings"),
        this.$store.dispatch("positions/getPositions"),
        this.$store.dispatch("afunctions/getFunctions"),
        this.$store.dispatch("coverings/getCoverings"),
      ]);

      this.loading = false;
    } catch (error) {
      console.error("Error loading data:", error);
    }

    try {
      this.realestate = this.$store.getters['realestates/getRealEstate'];
      this.realestate = this.realestate[0];
      this.realestate.id = this.$route.params.id;
      this.categorizeMedia();
      if (this.realestate.areas.length > 0) {
        this.realestate.areas.forEach((owner, index) => {
          this.areas.push({
            id: owner.id,
            identifier:  owner.identifier,
            area_buildings_id: owner.area_buildings_id,
            area_buildings: this.getBuildingsList.filter(val => val.id == owner.area_buildings_id),
            area_numbers_id: owner.area_numbers_id,
            area_numbers: this.getAreaNumbersList.filter(val => val.id == owner.area_numbers_id),
            lot_number: owner.lot_number,
            area_positions_id: owner.area_positions_id,
            area_position: this.getAreaPositionsList.filter(val => val.id == owner.area_positions_id),
            area_functions_id: owner.area_functions_id,
            area_function: this.getAreaFunctionsList.filter(val => val.id == owner.area_functions_id),
            net_floor_space: owner.net_floor_space,
            gross_floor_space: owner.gross_floor_space,
            height: owner.height,
            area_floor_coverings_id: owner.area_floor_coverings_id,
            area_floor_covering: this.getAreaFloorCoveringsList.filter(val => val.id == owner.area_floor_coverings_id),
          });
        });
      }
    } catch (error) {
      showSwal.methods.showSwal({
        type: "error",
        message: error,
        width: 500
      });
    }
  },
  methods: {
    showPdf(pdf) {
      const pdfUrl = pdf.original_url;
      window.open(pdfUrl, '_blank');
    },
    categorizeMedia() {
      this.images = [];
      this.documents = [];

      this.realestate.media.forEach(file => {
        if (file.collection_name === "images") {
          if (this.images.length < 3) {
            this.images.push(file);
          }
        } else if (file.collection_name === "documents") {
          if (this.documents.length < 3) {
            this.documents.push(file);
          }
        }
      });
    },
    openFilePicker() {
      if (this.totalPdfCount < 3) {
        this.$refs.pdfInput.click();
      }
    },
    handleFileUpload(event) {
      if (!this.imageFiles) {
        this.imageFiles = [];
      }
      if (!this.realestate.files) {
        this.realestate.files = [];
      }

      const newFiles = Array.from(event.target.files);

      newFiles.forEach((file) => {
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.previewImages.push(e.target.result); 
            this.imageFiles.push(file); 
            this.realestate.files.push(file); 
            this.fileNames.push(file.name);
          };
          reader.readAsDataURL(file);
        }
      });
    },
    removeImage(index) {
      this.previewImages.splice(index, 1);
      this.realestate.files.splice(index, 1);
      this.fileNames.splice(index, 1);
    },
    handlePdfUpload(event) {
      if (!this.realestate.files) this.realestate.files = [];
      const newPdfs = Array.from(event.target.files);

      newPdfs.forEach((file) => {
        if (file.type === "application/pdf") {
          this.pdfFiles.push(file);
          this.realestate.files.push(file);
        }
      });
    },
    removePdf(index) {
      const pdfName = this.pdfFiles[index].name;
      this.realestate.files = this.realestate.files.filter(file => file.name !== pdfName);
      this.pdfFiles.splice(index, 1);
    },
    openModal(image) {
      this.selectedImage = image;
      this.modalVisible = true;
    },
    async downloadMedia(mediaId) {
      await this.$store.dispatch("realestates/downloadMedia", mediaId);
    },  
    async refreshRealEstateData() {
      try {
        await this.$store.dispatch("realestates/getRealEstate", this.realestate.id);
        this.realestate = this.$store.getters['realestates/getRealEstate'][0];
      } catch (error) {
        console.log("Error in refreshing the realestate data:", error);
      }
    },
    addNewArea(newArea) {
      this.area.id = newArea.id;
      this.area.identifier = newArea.identifier;
      this.area.lot_number = newArea.lot_number;
      this.area.net_floor_space = newArea.net_floor_space;
      this.area.gross_floor_space = newArea.gross_floor_space;
      this.area.height = newArea.height;
      this.area.realestate_id = newArea.realestate_id;
      this.area.area_buildings = this.getBuildingsList.filter(val => val.id == newArea.area_buildings_id);
      this.area.area_numbers = this.getAreaNumbersList.filter(val => val.id == newArea.area_numbers_id);
      this.area.area_position = this.getAreaPositionsList.filter(val => val.id == newArea.area_positions_id);
      this.area.area_function = this.getAreaFunctionsList.filter(val => val.id == newArea.area_functions_id);
      this.area.area_floor_covering = this.getAreaFloorCoveringsList.filter(val => val.id == newArea.area_floor_coverings_id);
      this.areas.push(this.area);
      this.refreshRealEstateData();
    },
    addNewAreas(savedAreas) {
      savedAreas.forEach(newArea => {
        let area = {};
        area.id = newArea.id;
        area.identifier = newArea.identifier;
        area.lot_number = newArea.lot_number;
        area.net_floor_space = newArea.net_floor_space;
        area.gross_floor_space = newArea.gross_floor_space;
        area.height = newArea.height;
        area.realestate_id = newArea.realestate_id;
        area.area_buildings = this.getBuildingsList.filter(val => val.id == newArea.area_buildings_id);
        area.area_numbers = this.getAreaNumbersList.filter(val => val.id == newArea.area_numbers_id);
        area.area_position = this.getAreaPositionsList.filter(val => val.id == newArea.area_positions_id);
        area.area_function = this.getAreaFunctionsList.filter(val => val.id == newArea.area_functions_id);
        area.area_floor_covering = this.getAreaFloorCoveringsList.filter(val => val.id == newArea.area_floor_coverings_id);
        this.areas.push(area);
    });
      this.refreshRealEstateData();
    },
    editAreaNetGrossFloorSpace(editedArea) {
      const areaToEdit = this.areas.find(area => area.id === editedArea.id);
      if (areaToEdit) {
        areaToEdit.net_floor_space = editedArea.net_floor_space;
        areaToEdit.gross_floor_space = editedArea.gross_floor_space;
      }
    },
    deleteOldAreas(areaIds) {
      this.areas = this.areas.filter(area => !areaIds.includes(area.id));
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
      return;
    },
    openNew() {
      this.area = {};
      this.submitted = false;
      this.areaDialog = true;
    },
    hideDialog() {
      this.areaDialog = false;
      this.submitted = false;
    },
    async savearea() {
      this.loading = true
      this.submitted = true;
        if (this.area.id) {
          this.area.id = this.area.id.toString();
          this.area.realestate_id = this.realestate.id;
          try {
            await this.$store.dispatch("areas/editArea", this.area);

            this.area.area_buildings = this.getBuildingsList.filter(val => val.id == this.area.area_buildings_id);
            this.area.area_numbers = this.getAreaNumbersList.filter(val => val.id == this.area.area_numbers_id);
            this.area.area_position = this.getAreaPositionsList.filter(val => val.id == this.area.area_positions_id);
            this.area.area_function = this.getAreaFunctionsList.filter(val => val.id == this.area.area_functions_id);
            this.area.area_floor_covering = this.getAreaFloorCoveringsList.filter(val => val.id == this.area.area_floor_coverings_id);

            this.areas[this.findIndexById(this.area.id)] = this.area;
            this.$toast.add({severity: 'success', summary: 'Sikeres', detail: 'Helység módosítva!', life: 3000});
            this.refreshRealEstateData();
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'HIBA', detail: error, life: 10000});
            this.areaDialog = false;
            this.area = {};
            this.loading = false
          }
        } else {
          this.area.realestate_id = this.realestate.id;
          try {
            const areaID = await this.$store.dispatch("areas/addArea", this.area);
            this.area.id = areaID.toString();

            this.area.area_buildings = this.getBuildingsList.filter(val => val.id == this.area.area_buildings_id);
            this.area.area_numbers = this.getAreaNumbersList.filter(val => val.id == this.area.area_numbers_id);
            this.area.area_position = this.getAreaPositionsList.filter(val => val.id == this.area.area_positions_id);
            this.area.area_function = this.getAreaFunctionsList.filter(val => val.id == this.area.area_functions_id);
            this.area.area_floor_covering = this.getAreaFloorCoveringsList.filter(val => val.id == this.area.area_floor_coverings_id);

            this.areas.push(this.area);
            this.$toast.add({severity: 'success', summary: 'Sikeres', detail: 'Helység hozzáadva!', life: 3000});
            this.refreshRealEstateData();
          } catch (error) {
            this.$toast.add({severity: 'error', summary: 'HIBA', detail: error, life: 10000});
            this.areaDialog = false;
            this.area = {};
            this.loading = false
          }
        }

        this.areaDialog = false;
        this.area = {};
      this.loading = false;
    },
    editarea(area) {
      this.area = {...area};
      this.areaDialog = true;
    },
    confirmDeletearea(area) {
      this.area = area;
      this.deleteareaDialog = true;
    },
    async deletearea() {
      this.areas = this.areas.filter(val => val.id !== this.area.id);
      this.deleteareaDialog = false;
      await this.$store.dispatch("areas/deleteArea", this.area.id);
      this.area = {};
      this.refreshRealEstateData();
      this.$toast.add({severity: 'success', summary: 'Successful', detail: 'area Deleted', life: 3000});
    },
    async deleteMedia(id) {
      try {
        this.deleteMediaFiles.realestate_id = this.realestate.id;
        this.deleteMediaFiles.media_id = id;
        await this.$store.dispatch('realestates/deleteMedia', this.deleteMediaFiles);
        this.images = this.images.filter(image => image.id !== id);
        this.documents = this.documents.filter(document => document.id !== id);
        showSwal.methods.showSwal({
          type: "success",
          message: "Sikeresen törölte a média tartalmat.",
          width: 500
        });

      } catch (error) {
        console.log("Hiba történt a média törlésekor:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Hiba történt a média törlésekor.",
          width: 500
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.areas.length; i++) {
        if (this.areas[i].id == id) {
          index = i;
          break;
        }
      }

      return index;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteareasDialog = true;
    },
    deleteSelectedareas() {
      this.areas = this.areas.filter(val => !this.selectedareas.includes(val));
      this.deleteareasDialog = false;
      this.selectedareas = null;
      this.$toast.add({severity: 'success', summary: 'Successful', detail: 'areas Deleted', life: 3000});
    },
    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },
    async handleEdit() {
      this.loading = true
      this.resetApiValidation();
      try {
        this.realestate.deed_size = parseFloat(this.realestate.deed_size);
        this.realestate.gross_size = parseFloat(this.realestate.gross_size);
        this.realestate.areas = this.areas;
        await this.$store.dispatch("realestates/editRealEstate", this.realestate);
        showSwal.methods.showSwal({
          type: "success",
          message: "Ingatlan sikeresen módosítva!",
          width: 500
        });
        this.$router.push({name: "RealEstates List"})
      } catch (error) {
        if (error.response.data.errors) {
          this.setApiValidation(error.response.data.errors);
        }
        showSwal.methods.showSwal({
          type: "error",
          message: "Valami hiba történt!",
          width: 500
        });

      }
      this.loading = false
    },
    addArea(type, value) {
      if (type == 'commissioning_date') {
        this.newArea[type] = this.format(value);
      } else if (type == 'area_buildings_id' || type == 'area_numbers_id' || type == 'area_functions_id' || type == 'area_positions_id' || type == 'area_floor_coverings_id') {
        this.newArea[type] = Number(value);
      } else if (type == 'net_floor_space' || type == 'gross_floor_space' || type == 'height') {
        this.newArea[type] = parseFloat(value);
      } else {
        this.newArea[type] = value;
      }
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter(col => value.includes(col));
    }
  }
}
</script>